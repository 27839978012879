import { t } from "@lingui/macro"
import { Stack, Typography, useTheme } from "@mui/material"
import { observer } from "mobx-react"

import CircularProgress from "@mui/material/CircularProgress"

import { HighlightedQuery } from "src/components/HighlightedQuery"
import { Icon } from "src/components/icons"
import { Cross16 } from "src/components/icons/Cross16"
import { User16 } from "src/components/icons/User16"
import { Tooltip } from "src/components/Tooltips/Tooltip"
import { formatInteger } from "src/lib/number"
import { getSegmentTypeIcon } from "src/lib/segment-type"
import { SegmentPickerStore } from "src/components/SegmentPicker/store"
import { truncatePath } from "src/modals/segment-picker/utils"
import { useStore } from "src/store/lib/useStore"
import {
    DeselectButton,
    ListItemInnerSelect,
    ListItemTextSelect,
} from "src/components/SegmentPicker/components/SegmentPickerSelectListItem/styled"
import { ISegmentPickerSelectListItemProps } from "src/components/SegmentPicker/components/SegmentPickerSelectListItem/types"

export const ListItem = observer(
    ({ segment }: ISegmentPickerSelectListItemProps) => {
        const store = useStore(SegmentPickerStore)
        const theme = useTheme()

        const { icon, color } = getSegmentTypeIcon(segment.type)

        const truncatedSegmentPath = truncatePath(segment.path)

        return (
            <ListItemInnerSelect
                sx={
                    store.publishGroupVisible
                        ? segment.tenantCount > 0
                            ? {}
                            : { opacity: 0.5 }
                        : {}
                }
                secondaryAction={
                    <>
                        <Stack direction="row" spacing={1} alignItems="center">
                            <Stack
                                direction="row"
                                spacing={0.5}
                                alignItems="center"
                            >
                                <Icon
                                    icon={<User16 />}
                                    color={theme.palette.grey[900]}
                                />
                                <Typography
                                    fontSize="12px"
                                    color={theme.palette.grey[700]}
                                >
                                    {store.publishGroupLoading ? (
                                        <CircularProgress size={12} />
                                    ) : (
                                        formatInteger(segment.tenantCount)
                                    )}
                                </Typography>
                            </Stack>
                            <DeselectButton
                                title={t({
                                    id: `segment-picker.selected.deselect-segment`,
                                    values: {
                                        name: segment.name,
                                    },
                                })}
                                onClick={() => store.deselectSegment(segment)}
                            >
                                <Cross16 />
                            </DeselectButton>
                        </Stack>
                    </>
                }
            >
                <Icon icon={icon} size={16} color={color} />
                <ListItemTextSelect
                    primary={
                        <Typography
                            fontSize="14px"
                            fontWeight={500}
                            color={theme.palette.grey[900]}
                            lineHeight={1.4}
                            overflow="hidden"
                            textOverflow="ellipsis"
                            whiteSpace="nowrap"
                            display="inherit"
                            title={segment.name}
                        >
                            <HighlightedQuery
                                text={segment.name}
                                query={store.selectedSegments.meta.search}
                            />
                        </Typography>
                    }
                    secondary={
                        <Tooltip
                            title={
                                truncatedSegmentPath !== segment.path
                                    ? segment.path
                                    : ""
                            }
                            arrow
                        >
                            <Typography
                                component="span"
                                fontSize="12px"
                                lineHeight={1.4}
                                color={theme.palette.grey[700]}
                                overflow="hidden"
                                textOverflow="ellipsis"
                                whiteSpace="nowrap"
                                display="inherit"
                            >
                                <HighlightedQuery
                                    text={truncatedSegmentPath}
                                    query={store.selectedSegments.meta.search}
                                />
                            </Typography>
                        </Tooltip>
                    }
                />
            </ListItemInnerSelect>
        )
    },
)
