/* eslint-disable import/no-relative-parent-imports */
import { t } from "@lingui/macro"
import { Stack } from "@mui/material"

import { useState } from "react"

import { TextFields } from "./TextFields"

import { DropDown } from "./DropDown"

import { IProps } from "./types"

import { FormFieldSet } from "src/components/FormFieldSet"
import { IFieldsArrayProps } from "src/views/integration-connector/type"

import { getRemoveRequiredFields } from "src/modals/integration-connector-detail/helper"

const getErrorMessage = (
    data: IFieldsArrayProps[],
    index: number,
    value: string,
) => {
    if (data[index].validator !== undefined) {
        if (value.match(new RegExp(data[index].validator ?? "")) !== null) {
            return ""
        } else {
            return t`errors.required`
        }
    } else {
        // if there is no validator, force field to be filled in (maintain current behaviour)
        if (value.trim() === "") {
            return t`errors.required`
        } else {
            return ""
        }
    }
}

export const DynamicSection = (props: IProps) => {
    const [value, setValue] = useState("")

    const removeRequired = getRemoveRequiredFields(props.connectorType)

    const handleFormChange = (
        index: number,
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        let data = [...props.fields]
        data[index].name =
            event.target.value.trim() !== "" ? event.target.value : ""
        data[index].errorMessage = !removeRequired.includes(
            data[index].value as string,
        )
            ? getErrorMessage(data, index, event.target.value.trim())
            : ""
        props.setInputFields(data)
        props.setDynamicFieldsHasChange(true)
    }
    const onSelectValueChange = (index: number, value: string) => {
        setValue(value)
        let data = [...props.fields]
        data[index].name = value
        data[index].errorMessage = value.trim() !== "" ? "" : t`errors.required`
        props.setInputFields(data)
        props.setDynamicFieldsHasChange(true)
    }
    return (
        <FormFieldSet header="">
            <Stack spacing={2}>
                {props.fields.map((input, index) => {
                    return (
                        <div key={index}>
                            {input.inputType === "textField" && (
                                <TextFields
                                    index={index}
                                    item={input}
                                    handleFormChange={handleFormChange}
                                    disabled={props.disabled}
                                />
                            )}

                            {input.inputType === "dropdown" && (
                                <DropDown
                                    index={index}
                                    value={input.name ?? value}
                                    item={input}
                                    onSelectValueChange={onSelectValueChange}
                                    disabled={props.disabled}
                                />
                            )}
                        </div>
                    )
                })}
            </Stack>
        </FormFieldSet>
    )
}
