/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { avy_api_pkg_segment_Dimension } from "../models/avy_api_pkg_segment_Dimension"
import type { avy_api_pkg_segment_ExchangeEntityIDRequest } from "../models/avy_api_pkg_segment_ExchangeEntityIDRequest"
import type { avy_api_pkg_segment_ExchangeEntityIDResponse } from "../models/avy_api_pkg_segment_ExchangeEntityIDResponse"
import type { avy_api_pkg_segment_SegmentForSearch } from "../models/avy_api_pkg_segment_SegmentForSearch"
import type { avy_api_pkg_segment_SegmentPreview } from "../models/avy_api_pkg_segment_SegmentPreview"
import type { internal_api_http_admin_segment_Segment } from "../models/internal_api_http_admin_segment_Segment"
import type { internal_api_http_admin_segment_SegmentCreateUpdate } from "../models/internal_api_http_admin_segment_SegmentCreateUpdate"
import type { segment_JSONLogic } from "../models/segment_JSONLogic"
import type { segment_SegmentTreeNode } from "../models/segment_SegmentTreeNode"
import type { segment_SelectedSegmentsRequest } from "../models/segment_SelectedSegmentsRequest"

import type { CancelablePromise } from "../core/CancelablePromise"
import { OpenAPI } from "../core/OpenAPI"
import { request as __request } from "../core/request"

export class SegmentsAdminService {
    /**
     *
     * Dimensions
     * Get available dimensions
     * @returns avy_api_pkg_segment_Dimension OK
     * @throws ApiError
     */
    public static getV1AdminDimension(): CancelablePromise<
        Array<avy_api_pkg_segment_Dimension>
    > {
        return __request(OpenAPI, {
            method: "GET",
            url: "/v1/admin/dimension",
        })
    }

    /**
     * Get segments
     * Returns all segments.
     * @returns segment_SegmentTreeNode OK
     * @throws ApiError
     */
    public static getV1AdminSegment({
        accessGroupId,
    }: {
        /** Access group ID (optional) **/
        accessGroupId?: string
    }): CancelablePromise<Array<segment_SegmentTreeNode>> {
        return __request(OpenAPI, {
            method: "GET",
            url: "/v1/admin/segment",
            query: {
                access_group_id: accessGroupId,
            },
        })
    }

    /**
     * Create segment
     * Creates a segment.
     * @returns internal_api_http_admin_segment_Segment OK
     * @throws ApiError
     */
    public static postV1AdminSegment({
        request,
    }: {
        /** Segment data. **/
        request: internal_api_http_admin_segment_SegmentCreateUpdate
    }): CancelablePromise<internal_api_http_admin_segment_Segment> {
        return __request(OpenAPI, {
            method: "POST",
            url: "/v1/admin/segment",
            body: request,
        })
    }

    /**
     * Get Segment IDs from entity IDs
     * Exchanges entity IDs for SegmentIDs.
     * @returns avy_api_pkg_segment_ExchangeEntityIDResponse OK
     * @throws ApiError
     */
    public static segmentExchangeEntityId({
        request,
    }: {
        /** Segment ID exchange data. **/
        request: Array<avy_api_pkg_segment_ExchangeEntityIDRequest>
    }): CancelablePromise<Array<avy_api_pkg_segment_ExchangeEntityIDResponse>> {
        return __request(OpenAPI, {
            method: "POST",
            url: "/v1/admin/segment/exchange-entity-id",
            body: request,
        })
    }

    /**
     * Preview a smart segment
     * Validates smart segment definition and retuns the number of resolved tenants.
     * @returns avy_api_pkg_segment_SegmentPreview OK
     * @throws ApiError
     */
    public static postV1AdminSegmentPreview({
        request,
    }: {
        /** JSONLogic definition of the smart segment. **/
        request: segment_JSONLogic
    }): CancelablePromise<avy_api_pkg_segment_SegmentPreview> {
        return __request(OpenAPI, {
            method: "POST",
            url: "/v1/admin/segment/preview",
            body: request,
        })
    }

    /**
     * Segment search
     * Segment search
     * @returns avy_api_pkg_segment_SegmentForSearch OK
     * @throws ApiError
     */
    public static getV1AdminSegmentSearch({
        accessGroupId,
    }: {
        /** Access group ID (optional) **/
        accessGroupId?: string
    }): CancelablePromise<Array<avy_api_pkg_segment_SegmentForSearch>> {
        return __request(OpenAPI, {
            method: "GET",
            url: "/v1/admin/segment/search",
            query: {
                access_group_id: accessGroupId,
            },
        })
    }

    /**
     * Selected segments
     * Get segments by segment IDs and publish group IDs
     * @returns avy_api_pkg_segment_SegmentForSearch OK
     * @throws ApiError
     */
    public static postV1AdminSegmentSelectedSegments({
        request,
    }: {
        /** Selected segments request **/
        request: segment_SelectedSegmentsRequest
    }): CancelablePromise<Array<avy_api_pkg_segment_SegmentForSearch>> {
        return __request(OpenAPI, {
            method: "POST",
            url: "/v1/admin/segment/selected-segments",
            body: request,
        })
    }

    /**
     * Get segment
     * Returns a segment by ID.
     * @returns internal_api_http_admin_segment_Segment OK
     * @throws ApiError
     */
    public static getV1AdminSegment1({
        segmentId,
    }: {
        /** Segment ID **/
        segmentId: number
    }): CancelablePromise<internal_api_http_admin_segment_Segment> {
        return __request(OpenAPI, {
            method: "GET",
            url: "/v1/admin/segment/{segment_id}",
            path: {
                segment_id: segmentId,
            },
        })
    }

    /**
     * Delete segment
     * Deletes a segment.
     * @returns any OK
     * @throws ApiError
     */
    public static deleteV1AdminSegment({
        segmentId,
        parentSegmentId,
    }: {
        /** Segment ID **/
        segmentId: number
        /** New Parent SegmentID **/
        parentSegmentId?: number
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: "DELETE",
            url: "/v1/admin/segment/{segment_id}",
            path: {
                segment_id: segmentId,
            },
            query: {
                parent_segment_id: parentSegmentId,
            },
        })
    }

    /**
     * Update segment
     * Updates a segment.
     * @returns any OK
     * @throws ApiError
     */
    public static patchV1AdminSegment({
        request,
        segmentId,
    }: {
        /** Segment data. **/
        request: internal_api_http_admin_segment_SegmentCreateUpdate
        /** Segment ID **/
        segmentId: number
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: "PATCH",
            url: "/v1/admin/segment/{segment_id}",
            path: {
                segment_id: segmentId,
            },
            body: request,
        })
    }
}
