import { t } from "@lingui/macro"
import { observer } from "mobx-react"
import { useCallback, useEffect, useMemo, useRef } from "react"
import { useNavigate } from "react-router"
import { Box, ButtonGroup, Grid, Stack, useTheme } from "@mui/material"

import { ListPage } from "src/components/ListPage"
import { DataGridProTable } from "src/components/Table/DataGridPro"
import { GlobalStore } from "src/store"
import { StoreProvider } from "src/store/lib/StoreProvider"
import { useStore } from "src/store/lib/useStore"
import { FilterButton } from "src/views/community-overview/components/FilterButton"
import { CommunityOverviewHeader } from "src/views/community-overview/components/Header"
import { StatisticsHeader } from "src/views/community-overview/components/StatisticsHeader"
import { StatisticsRow } from "src/views/community-overview/components/StatisticsRow"
import { useGetCommunityOverviewColumns } from "src/views/community-overview/hooks/useGetCommunityOverviewColumns"
import { CommunitiesOverviewViewStore } from "src/views/community-overview/store"
import { IButtonData } from "src/views/community-overview/types"
import { trackModuleEvent } from "src/analytics/helpers/mixpanel_tracking"
import { MixpanelProperties } from "src/analytics/constants/properties"

const View = observer(() => {
    const store = useStore(CommunitiesOverviewViewStore)
    const gstore = useStore(GlobalStore)
    const { palette } = useTheme()
    const navigate = useNavigate()

    const prevSelectedCommunities = useRef(store.selectedCommunities)
    const prevStartDate = useRef(store.startDate)
    const columns = useGetCommunityOverviewColumns()

    const buttons = useMemo<IButtonData[]>(
        () => [
            { label: t`community-view.any`, value: null },
            { label: t`community-view.handled`, value: true },
            { label: t`community-view.unhandled`, value: false },
        ],
        [],
    )

    useEffect(() => {
        ;(async () => {
            await store.init(gstore.session.accessGroupId)
            await store.loadCommunityFlags()
        })()
    }, [store, gstore.session.accessGroupId])

    useEffect(() => {
        if (
            prevSelectedCommunities.current !== store.selectedCommunities ||
            prevStartDate.current !== store.startDate
        ) {
            ;(async () => await store.reload())()

            prevSelectedCommunities.current = store.selectedCommunities
            prevStartDate.current = store.startDate
        }
    }, [store, store.selectedCommunities, store.startDate])

    const handleRowClick = useCallback(
        (item) => {
            navigate(
                `/community/posts-and-comments?${store.generatePostCommentUrl(
                    undefined,
                    item.id.toString(),
                )}`,
            )
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [navigate],
    )

    const handleHandledFilterChange = useCallback((value: boolean | null) => {
        trackModuleEvent("Handled filter - community overview", {
            [MixpanelProperties.HandledFilter]:
                value === null
                    ? "All"
                    : value === true
                    ? "Handled"
                    : "Unhandled",
            [MixpanelProperties.EventDefinition]:
                "The handled/unhandled/all filter",
        })

        void store.setShowHandled(value)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const renderFilterButtons = useMemo(
        () => (
            <ButtonGroup variant="outlined">
                {buttons.map((button) => (
                    <FilterButton
                        key={button.label}
                        label={button.label}
                        value={button.value}
                        isActive={store.showHandled === button.value}
                        onClick={() =>
                            handleHandledFilterChange(
                                button.value as boolean | null,
                            )
                        }
                    />
                ))}
            </ButtonGroup>
        ),
        [buttons, handleHandledFilterChange, store.showHandled],
    )

    return (
        <ListPage
            header={{
                header: t`community-view.header`,
                breadcrumbs: [
                    t`community-view.community-breadcrumb`,
                    t`community-overview.community-breadcrumb`,
                ],
                tailingComponent: <CommunityOverviewHeader />,
            }}
            loading={gstore.loading.is(
                CommunitiesOverviewViewStore.LoadingKeys.init,
            )}
        >
            <Stack bgcolor={palette.background.default} padding={3}>
                <Grid
                    borderRadius={1}
                    bgcolor={palette.grey[50]}
                    marginBottom={2}
                >
                    <StatisticsHeader />
                    <StatisticsRow />
                </Grid>
            </Stack>
            <Box mt={24} display="flex" justifyContent="flex-end">
                {renderFilterButtons}
            </Box>
            <Stack bgcolor={palette.background.default} padding={3}>
                <Grid
                    borderRadius={1}
                    bgcolor={palette.grey[50]}
                    marginBottom={2}
                >
                    <DataGridProTable
                        paginator={store.communitiesPaginator}
                        hideToolbar={true}
                        data={store.communitiesPaginator.items}
                        columns={columns}
                        onRowClickEvent={handleRowClick}
                        repository="community"
                        loading={gstore.loading.is(
                            CommunitiesOverviewViewStore.LoadingKeys.loading,
                        )}
                    />
                </Grid>
            </Stack>
        </ListPage>
    )
})

export const CommunityOverviewView = observer(() => (
    <StoreProvider Store={CommunitiesOverviewViewStore}>
        <View />
    </StoreProvider>
))
