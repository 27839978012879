import { t } from "@lingui/macro"
import { Stack } from "@mui/material"
import { observer } from "mobx-react"
import { useMemo } from "react"

import {
    Author,
    HeadUnderline,
    InternalObjectIDContainer,
    TimeSince,
    Wrap,
} from "src/modals/community-posts-and-comments/styled"

import { Avatar } from "src/components/Avatar"
import { timeSinceDate } from "src/lib/date"
import { CommunityPostsAndCommentsStore } from "src/modals/community-posts-and-comments/store"
import { FlagRenderer } from "src/shared/community-posts-and-comments/components/FlagRenderer"
import { useStore } from "src/store/lib/useStore"
import { PostsAndCommentsActionMenu } from "src/modals/community-posts-and-comments/components/ActionMenu"

export const PostHeaderSection = observer(() => {
    const store = useStore(CommunityPostsAndCommentsStore)

    const timeSinceCreated = useMemo(
        () =>
            store.post.createdDate != null
                ? timeSinceDate(store.post.createdDate)
                : null,
        [store.post.createdDate],
    )

    return (
        <Wrap
            direction="row"
            spacing={2}
            justifyContent="space-between"
            alignItems="flex-start"
        >
            <Stack direction="row" spacing={2} alignItems="center">
                <Avatar size={56} name={store.post.authorName} />
                <Stack direction="column">
                    <Author>{store.post.authorName}</Author>
                    <HeadUnderline>
                        <TimeSince
                            dateTime={store.post.createdDate?.toLocaleString()}
                        >
                            {timeSinceCreated}
                        </TimeSince>
                        <InternalObjectIDContainer>
                            {t({
                                id: "view-community-post-modal.internal-object-id",
                                values: {
                                    internalObjectId:
                                        store.post.internalObjectId,
                                },
                            })}
                        </InternalObjectIDContainer>
                    </HeadUnderline>
                </Stack>
            </Stack>
            <Stack direction="row" spacing={1} alignItems="center">
                {FlagRenderer(store.post.flags)}
                {store.isWriteAccess() && <PostsAndCommentsActionMenu />}
            </Stack>
        </Wrap>
    )
})
