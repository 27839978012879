import {
    avy_api_pkg_embed_SalesData,
    avy_api_pkg_embed_Segment,
    avy_api_pkg_admin_domain_ResourceAccessType,
} from "src/api"

export interface ISegment {
    id: number
    name: string
    type: ISegmentForSearch["type"]
}

export interface IEmbedIntegrationFormItemProps {
    id: number
    name: string
    partner_name: string
    sales_data?: avy_api_pkg_embed_SalesData | undefined
    type: string
    categories: string[]
    segments: avy_api_pkg_embed_Segment[] | undefined
    access_type?: avy_api_pkg_admin_domain_ResourceAccessType
}

export enum EmbedIntegrationItemTypes {
    Simple = "simple",
    Subscription = "subscription",
}
