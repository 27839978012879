import { t } from "@lingui/macro"
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank"
import CheckBoxIcon from "@mui/icons-material/CheckBox"
import { useCallback, useEffect, useMemo, useState } from "react"
import {
    Stack,
    useTheme,
    Box,
    Chip,
    Autocomplete,
    TextField,
    Checkbox,
} from "@mui/material"

import { useNavigate } from "react-router"

import { useStore } from "src/store/lib/useStore"
import { CommunityPostsAndCommentsStore } from "src/views/community-posts-and-comments/store"
import {
    flagToIconMap,
    flagToColorMap,
    getFlagOptionsForDropdown,
    getTranslatedFlagOptions,
} from "src/shared/community-posts-and-comments/constants/flag-constants"
import useRouteParams from "src/views/community-posts-and-comments/hooks/useRouteParams"
import { IPostAndCommentsFilter } from "src/views/community-posts-and-comments/types"
import { trackModuleEvent } from "src/analytics/helpers/mixpanel_tracking"
import { MixpanelProperties } from "src/analytics/constants/properties"

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
const checkedIcon = <CheckBoxIcon fontSize="small" />

export default function MultiselectFlagsFilter() {
    const { palette } = useTheme()
    const store = useStore(CommunityPostsAndCommentsStore)
    const colorMap = flagToColorMap(palette)
    const allParams = useRouteParams()
    const navigate = useNavigate()

    const flagOptions = useMemo(() => getFlagOptionsForDropdown(), [])

    const [selectedOptions, setSelectedOptions] = useState<
        IPostAndCommentsFilter[]
    >([])

    // Update selected options based on URL params
    const handleFlagFromParams = useCallback(() => {
        const flagInParams = allParams.find((param) => param.param === "flag")
        if (flagInParams != null) {
            // Split the comma-separated flags
            const flagIds = flagInParams.value.split(",")

            // Find all matching flag options
            const selectedFlags = flagOptions.filter((flagOption) =>
                flagIds.includes(flagOption.id),
            )

            setSelectedOptions(selectedFlags)
            store.setFlagFilter(selectedFlags)
        }
    }, [allParams, flagOptions, store])

    useEffect(() => {
        handleFlagFromParams()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleSelectOnChange = useCallback(
        (_event, newValue) => {
            setSelectedOptions(newValue)
            store.setFlagFilter(newValue)

            // Track the flag filter event when user selects flags
            if (newValue.length > 0) {
                // Get translated values for all selected flags
                const translatedFlags = getTranslatedFlagOptions()
                const selectedFlagValues = newValue.map(
                    (flag: { id: string }) =>
                        translatedFlags[
                            flag.id as keyof typeof translatedFlags
                        ] ?? flag.id,
                )

                trackModuleEvent("Flag filter - posts and comments", {
                    [MixpanelProperties.SelectedFlag]:
                        selectedFlagValues.join(", "),
                    [MixpanelProperties.EventDefinition]:
                        "When selecting a flag in the filter",
                })
            }

            // Get all current params except flag
            const otherParams = allParams
                .filter((param) => param.param !== "flag")
                .map((param) => `${param.param}=${param.value}`)

            // Add new flag param with all selected values joined by commas
            const flagParam =
                newValue.length > 0
                    ? `flag=${newValue
                          .map((flag: { id: string }) => flag.id)
                          .join(",")}`
                    : null

            // Combine params and navigate
            const combinedParams = [flagParam, ...otherParams]
                .filter(Boolean)
                .join("&")

            const baseUrl = "/community/posts-and-comments"
            const url =
                combinedParams.length > 0
                    ? `${baseUrl}?${combinedParams}`
                    : baseUrl

            navigate(url)
        },
        [allParams, navigate, store],
    )

    const renderFlagIcon = useCallback(
        (key: string) => {
            const IconComponent =
                flagToIconMap[key as keyof typeof flagToIconMap]
            if (IconComponent === undefined) return null
            const color = colorMap[key as keyof typeof colorMap]
            return <IconComponent key={key} htmlColor={color} />
        },
        [colorMap],
    )

    const renderFlagOption = useCallback(
        (props, option) => {
            const isSelected = selectedOptions.some(
                (selectedOption) => selectedOption.id === option.id,
            )
            return (
                <li key={option.id} {...props}>
                    <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 4 }}
                        checked={isSelected}
                    />
                    {renderFlagIcon(option.id)}
                    <Stack width={8} />
                    {option.title}
                </li>
            )
        },
        [selectedOptions, renderFlagIcon],
    )

    const renderInput = useCallback(
        (params) => (
            <TextField
                {...params}
                sx={{
                    maxWidth: 480,
                    backgroundColor: palette.common.white,
                }}
                label={t`community-posts-view.filter-on-flags`}
                placeholder={t`community-posts-view.filter-on-flags`}
            />
        ),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    )

    return (
        <Stack maxHeight={56} width={480} zIndex={2}>
            <Autocomplete
                multiple
                limitTags={2}
                id="flags-filter"
                options={flagOptions}
                value={selectedOptions}
                onChange={handleSelectOnChange}
                disableCloseOnSelect
                getOptionLabel={(option) => option.title}
                renderOption={renderFlagOption}
                renderInput={renderInput}
                renderTags={(value, getTagProps) =>
                    value?.map((option, index) => (
                        <Chip
                            avatar={<Box>{renderFlagIcon(option.id)}</Box>}
                            label={option.title}
                            {...getTagProps({ index })}
                        />
                    ))
                }
            />
        </Stack>
    )
}
