/**
 * Helper functions for community-related operations
 */

import { GridRenderCellParams } from "@mui/x-data-grid-pro"

import { TMultipleSelectCheckmarksOption } from "src/components/MultipleSelectCheckmarks/types"
import { IPostAndCommentsItem } from "src/types/community-posts-and-comments/communityPostsAndComments"

/**
 * Interface for objects that have an itemType property
 */
interface IItemTypeContainer {
    itemType?: string
}

/**
 * Interface for objects that have a row property with an itemType
 */
interface IRowItemTypeContainer {
    row?: IItemTypeContainer
}

/**
 * Union type for all possible item types that can be checked for post/comment
 */
type ItemCheckable =
    | IItemTypeContainer
    | IRowItemTypeContainer
    | GridRenderCellParams
    | IPostAndCommentsItem

/**
 * Format a list of community names into a comma-separated string
 *
 * @param communities - Array of community objects with name property
 * @returns A comma-separated string of community names
 */
export const formatCommunityNames = (
    communities: TMultipleSelectCheckmarksOption[],
): string => {
    return communities.map((community) => community.name).join(", ")
}

/**
 * Check if an item is a post based on its itemType property
 *
 * @param item - The item to check
 * @returns Boolean indicating if the item is a post
 */
export const isPostItem = (item: ItemCheckable): boolean => {
    return (
        (item as IItemTypeContainer)?.itemType === "post" ||
        (item as IRowItemTypeContainer)?.row?.itemType === "post"
    )
}

/**
 * Check if an item is a comment based on its itemType property
 *
 * @param item - The item to check
 * @returns Boolean indicating if the item is a comment
 */
export const isCommentItem = (item: ItemCheckable): boolean => {
    return (
        (item as IItemTypeContainer)?.itemType === "comment" ||
        (item as IRowItemTypeContainer)?.row?.itemType === "comment"
    )
}

/**
 * Get a descriptive type string for an item
 *
 * @param item - The item to get the type for
 * @returns A string representing the item type
 */
export const getItemTypeLabel = (item: ItemCheckable): string => {
    if (isPostItem(item)) {
        return "post"
    }
    if (isCommentItem(item)) {
        return "comment"
    }
    return "unknown"
}
