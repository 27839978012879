import { t } from "@lingui/macro"
import { MoreHoriz } from "@mui/icons-material"
import { observer } from "mobx-react"
import { useCallback, useMemo } from "react"

import { ActionDropdown } from "src/components/ActionDropdown"
import { CommunityPostsAndCommentsEditModal } from "src/modals/community-posts-and-comments-edit"
import { CommunityPostsAndCommentsStore } from "src/modals/community-posts-and-comments/store"
import { ConfirmModal } from "src/modals/confirm"
import { useHandleAndReportActions } from "src/shared/community-posts-and-comments/hooks/useHandleAndReportActions"
import { GlobalStore } from "src/store"
import { useStore } from "src/store/lib/useStore"
import { trackModuleEvent } from "src/analytics/helpers/mixpanel_tracking"
import { MixpanelProperties } from "src/analytics/constants/properties"
import { TrackingSource, TrackingAction } from "src/analytics/constants/sources"

export const PostsAndCommentsActionMenu = observer(() => {
    const gstore = useStore(GlobalStore)
    const store = useStore(CommunityPostsAndCommentsStore)
    const { actionsItems } = useHandleAndReportActions()

    const isAuthorBlocked = useMemo(
        () => store.post.authorIsBlocked === true,
        [store.post.authorIsBlocked],
    )

    const handleDeletePostConfirm = useCallback(
        async (confirmed: boolean) => {
            if (confirmed && (await store.deletePost())) {
                trackModuleEvent("Delete post", {
                    [MixpanelProperties.DeletedFrom]: TrackingSource.Post,
                    [MixpanelProperties.EventDefinition]:
                        'When user confirms the delete with "ok"',
                    [MixpanelProperties.PostID]: store.post.id,
                    [MixpanelProperties.PostText]: store.post.text,
                    [MixpanelProperties.DeletedBy]: gstore.session.user?.name,
                })

                gstore.modals.pop()
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [store.post.id, store.post.text, gstore.session.user?.name],
    )

    const handleDeletePostConfirmModal = useCallback(() => {
        gstore.modals.open(
            () => (
                <ConfirmModal
                    onConfirm={handleDeletePostConfirm}
                    title={t`view-community-post-modal.delete-post-confirm-modal.title`}
                    content={t`view-community-post-modal.delete-post-confirm-modal.content`}
                />
            ),
            { variant: "slide-up-w600" },
        )
    }, [gstore.modals, handleDeletePostConfirm])

    const handleBlockUnblockTenantConfirm = useCallback(
        async (confirmed: boolean) => {
            if (!confirmed) return

            // Only track blocking, not unblocking
            if (confirmed && !isAuthorBlocked) {
                // Track blocking a user
                trackModuleEvent("Block user", {
                    [MixpanelProperties.EventDefinition]:
                        'When user confirms the block with "ok"',
                    [MixpanelProperties.BlockedBy]: gstore.session.user?.name,
                    [MixpanelProperties.BlockedUser]: store.post.authorName,
                    [MixpanelProperties.BlockedFrom]: TrackingSource.Post,
                    [MixpanelProperties.PostID]: store.post.id,
                    [MixpanelProperties.PostText]: store.post.text,
                })
            }

            const action = await store.blockUnblockTenant(
                isAuthorBlocked
                    ? TrackingAction.Unblocked
                    : TrackingAction.Blocked,
            )

            if (action === true) {
                gstore.modals.pop()
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [isAuthorBlocked, gstore.session.user?.name, gstore.modals],
    )

    const handleBlockUnblockTenantConfirmModal = useCallback(() => {
        gstore.modals.open(
            () => (
                <ConfirmModal
                    onConfirm={handleBlockUnblockTenantConfirm}
                    title={
                        isAuthorBlocked
                            ? t`view-community-post-modal.unblock-tenant-confirm-modal.title`
                            : t`view-community-post-modal.block-tenant-confirm-modal.title`
                    }
                    content={
                        isAuthorBlocked
                            ? t`view-community-post-modal.unblock-tenant-confirm-modal.content`
                            : t`view-community-post-modal.block-tenant-confirm-modal.content`
                    }
                />
            ),
            { variant: "slide-up-w600" },
        )
    }, [gstore.modals, handleBlockUnblockTenantConfirm, isAuthorBlocked])

    const handleOpenPostDetailModal = useCallback(() => {
        gstore.modals.open(
            () => <CommunityPostsAndCommentsEditModal id={store.post.id} />,
            {
                hasPreview: true,
            },
        )
    }, [gstore.modals, store.post])

    const renderActionOptions = useMemo(
        () => (
            <ActionDropdown
                icon={<MoreHoriz fontSize="large" />}
                items={[
                    ...actionsItems(store.post),
                    {
                        text: t`view-community-post-modal.edit-post-button`,
                        onClick: handleOpenPostDetailModal,
                        hidden: !store.canEditPost(
                            gstore.session.user?.adminId,
                        ),
                    },
                    {
                        text: isAuthorBlocked
                            ? t`view-community-post-modal.unblock-tenant-button`
                            : t`view-community-post-modal.block-tenant-button`,
                        onClick: handleBlockUnblockTenantConfirmModal,
                        loading: gstore.loading.is(
                            CommunityPostsAndCommentsStore.LoadingKeys[
                                "block-unblock"
                            ],
                        ),
                        hidden: !store.canBlockUnblockTenant(),
                    },
                    {
                        text: t`view-community-post-modal.delete-post-button`,
                        destructive: true,
                        loading: gstore.loading.is(
                            CommunityPostsAndCommentsStore.LoadingKeys[
                                "delete-post"
                            ],
                        ),
                        onClick: handleDeletePostConfirmModal,
                    },
                ]}
            />
        ),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [
            actionsItems,
            gstore.loading,
            gstore.session.user?.adminId,
            handleBlockUnblockTenantConfirmModal,
            handleDeletePostConfirmModal,
            handleOpenPostDetailModal,
            isAuthorBlocked,
        ],
    )

    return renderActionOptions
})
