/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable no-sequences */
import { observer } from "mobx-react"
import { t } from "@lingui/macro"
import { useCallback, useEffect, useState } from "react"

import { IntegrationConnectorItemStore } from "."

import { BoxContainer, Header, DynamicBoxContainer } from "./styled"

import { DynamicViews } from "./components/DynamicView"

import { AccessGroupDropdownAndConnectorView } from "./components/AccessGroupDropdownAndConnectorView"

import { WindowModalBottomView } from "./components/WindowModalBottomView"

import { SideModalView } from "./components/SideModalView"

import { PublishingSettingsSection } from "./components/PublishingSettingsSection"

import { useStore } from "src/store/lib/useStore"
import { GlobalStore } from "src/store"
import {
    IIntegrationTypeConnectorProps,
    IFieldsArrayProps,
} from "src/views/integration-connector/type"
import { FormPanel } from "src/components/FormPanel"
import { ResourceType } from "src/types/resource"

import { getRemoveRequiredFields } from "src/modals/integration-connector-detail/helper"
import { useInitializer } from "src/lib/initializer"
import { CenteredSpinner } from "src/components/CenteredSpinner"

export type IIntegrationConnectorItemProps = {
    id?: number
    showPopUpView?: boolean
    resourceType?: ResourceType
}

export const IntegrationConnectorItem = observer(
    (props: IIntegrationConnectorItemProps) => {
        const store = useStore(IntegrationConnectorItemStore)
        const globalStore = useStore(GlobalStore)
        const [thisModal] = useState(
            globalStore.modals.active[globalStore.modals.active.length - 1],
        )
        thisModal.confirmOnOverlayClick = () => store.formFields.getIsDirty()

        const [inputFields, setInputFields] = useState<IFieldsArrayProps[]>([])
        const [oldFields, setOldFields] = useState<IFieldsArrayProps[]>([])
        const [dynamicFieldsHasChange, setDynamicFieldsHasChange] =
            useState(false)

        const ConnectorSelected = store.formFields.get("connectorTypeId")
        const ConnectorType = store.formFields.get("type")

        const generateHashMap = useCallback(() => {
            const metadata = oldFields.reduce(
                (
                    acc: { [k: string]: string },
                    i: IFieldsArrayProps,
                    index: number,
                ) => {
                    return (acc = {
                        ...acc,
                        [i.value as string]: inputFields[index].name as string,
                    })
                },
                {},
            )
            store.formFields.set("metaData", metadata)
        }, [oldFields, inputFields, store.formFields])

        const handleSubmit = useCallback(
            async (
                event:
                    | React.FormEvent<HTMLFormElement>
                    | React.MouseEvent<Element, MouseEvent>,
            ) => {
                event.preventDefault()
                store.setIsLoading(true)
                let noEmptyFieldArray = []
                let dynamicFieldsValidationCheckArray = inputFields.map(
                    (item) => {
                        return { ...item }
                    },
                )

                const removeRequired = getRemoveRequiredFields(ConnectorType)

                dynamicFieldsValidationCheckArray.map((item) => {
                    if (item.validator !== undefined) {
                        if (
                            (item.name ?? "").match(
                                new RegExp(item.validator ?? ""),
                            ) !== null
                        ) {
                            noEmptyFieldArray.push(item)
                            item.errorMessage = ""
                            return setInputFields(
                                dynamicFieldsValidationCheckArray,
                            )
                        } else {
                            item.errorMessage = t`errors.required`
                            return setInputFields(
                                dynamicFieldsValidationCheckArray,
                            )
                        }
                    } else {
                        // if there is no validator, force field to be filled in (maintain current behaviour)
                        if (
                            (item.name === undefined || item.name === "") &&
                            !removeRequired.includes(item.value as string)
                        ) {
                            item.errorMessage = t`errors.required`
                            return setInputFields(
                                dynamicFieldsValidationCheckArray,
                            )
                        } else {
                            noEmptyFieldArray.push(item)
                            item.errorMessage = ""
                            return setInputFields(
                                dynamicFieldsValidationCheckArray,
                            )
                        }
                    }
                })

                if (
                    noEmptyFieldArray.length ===
                    dynamicFieldsValidationCheckArray.length
                ) {
                    store.formFields.set("isDynamicFieldEmpty", "no")
                } else {
                    store.formFields.set("isDynamicFieldEmpty", "")
                }
                generateHashMap()

                store.validateRequiredFields([
                    "name",
                    "connectorTypeId",
                    "accessGroupId",
                    "isDynamicFieldEmpty",
                ])

                if (store.formFields.hasErrors() === true) {
                    store.setIsLoading(false)
                    return
                }
                if (!props.id) {
                    await store.createItem()
                } else {
                    await store.updateItem()
                }
                if (!Boolean(store.formFields.error("generic"))) {
                    globalStore.modals.pop()
                }
            },
            [globalStore.modals, store, props.id, generateHashMap],
        )

        const getFieldsArray = useCallback(() => {
            let connectorObject: IIntegrationTypeConnectorProps = {}
            let newFieldsArray: IFieldsArrayProps[] | undefined = []
            if (store.connectors?.length > 0) {
                //Creating brand new copy of array object
                connectorObject = {
                    ...store.connectors.find(
                        (c) => c.integrationTypeId === ConnectorSelected,
                    ),
                }
                //Creating brand new copy of fields array object
                newFieldsArray = connectorObject?.fields?.map((field) => {
                    return { ...field }
                })
                // Inserting inputType into each object for dynamic fields to display
                newFieldsArray !== undefined &&
                    newFieldsArray.map((object) => {
                        return Object.assign(object, {
                            inputType: object.allowed_values
                                ? "dropdown"
                                : "textField",
                            value: object.name,
                            name: "",
                            errorMessage: "",
                        })
                    })
                // Move Dropdown Dynamic Element To The Top Of Array Index
                newFieldsArray?.map((item, i) => {
                    if (item.inputType === "dropdown") {
                        newFieldsArray?.splice(i, 1)
                        newFieldsArray?.unshift(item)
                    }
                    return newFieldsArray
                })
            }
            if (props.id) {
                let metaData = store.formFields.get("metaData")
                newFieldsArray?.map(
                    (i: IFieldsArrayProps) =>
                        (i.name =
                            metaData && (metaData[`${i.value}`] as string)),
                )
            }
            return newFieldsArray ?? []
        }, [store, ConnectorSelected, props.id])

        const initialized = useInitializer(async () => {
            await store.init(props.id)
        }, [props.id])

        useEffect(() => {
            ConnectorSelected !== undefined &&
                ConnectorSelected > 0 &&
                store.connectors.length > 0 &&
                setInputFields(getFieldsArray())
            setOldFields(getFieldsArray())
        }, [getFieldsArray, store.connectors, ConnectorSelected])

        useEffect(() => {
            !props.id &&
                store.formFields.set(
                    "accessGroupId",
                    globalStore.session.accessGroup.id as number,
                )
        }, [globalStore.session.accessGroup.id])

        const renderDynamicViews = () => (
            <DynamicViews
                inputFields={inputFields}
                setInputFields={setInputFields}
                connectorType={ConnectorType}
                disabled={store.formFields.get("accessType") === "READ"}
                setDynamicFieldsHasChange={setDynamicFieldsHasChange}
            />
        )

        const renderPublishSetting = () => {
            if (store.selectedConnectorConfig?.is_publishable !== true) {
                return null
            }
            return (
                <FormPanel
                    sections={[
                        {
                            header: t`resource-detail-modal.publishing-settings-section.section-header`,
                            content: <PublishingSettingsSection />,
                        },
                    ]}
                />
            )
        }

        const renderAccessGroupDropdownAndConnectorView = () => (
            <AccessGroupDropdownAndConnectorView
                disabled={Boolean(store.isEditMode)}
                accessGroupId={store.formFields.get("accessGroupId") as number}
                errorMessage={store.formFields.error("accessGroupId") ?? ""}
                isPopUpView={props.showPopUpView}
                resourceType={props.resourceType}
            />
        )
        const renderWindowModalBottomView = () => (
            <WindowModalBottomView onClick={(e) => handleSubmit(e)} />
        )
        const renderWindowModalView = () => {
            return (
                <>
                    <Header>{t`resource-detail-modal.heading`}</Header>
                    <BoxContainer>
                        {renderAccessGroupDropdownAndConnectorView()}
                    </BoxContainer>
                    <DynamicBoxContainer>
                        {inputFields.length > 0 && renderDynamicViews()}
                        {renderPublishSetting()}
                    </DynamicBoxContainer>
                    {renderWindowModalBottomView()}
                </>
            )
        }

        const renderSideModalView = () => {
            return (
                <SideModalView
                    handleSubmit={(e) => handleSubmit(e)}
                    inputFields={inputFields}
                    dynamicFieldsHasChange={dynamicFieldsHasChange}
                    renderDynamicViews={() => renderDynamicViews()}
                    renderPublishSettingView={() => renderPublishSetting()}
                />
            )
        }

        if (!initialized) {
            return <CenteredSpinner height="100vh" />
        }
        return props.showPopUpView === false
            ? renderSideModalView()
            : renderWindowModalView()
    },
)
