import { Stack } from "@mui/material"
import { observer } from "mobx-react"

import {
    PostText,
    ImageGrid,
    Link,
    Image,
    LinkTypography,
} from "src/modals/community-posts-and-comments/styled"

import { YouTubeEmbed } from "src/components/YouTubeEmbed"

import { CommunityPostsAndCommentsStore } from "src/modals/community-posts-and-comments/store"
import { useStore } from "src/store/lib/useStore"
import { UrlLinksHandler } from "src/lib/urlLinksHandler"

export const PostBody = observer(() => {
    const store = useStore(CommunityPostsAndCommentsStore)

    const urlLinks = new UrlLinksHandler(store.post.text)
    const urlListObject = urlLinks.createUrlListObject()

    return (
        <>
            <PostText>{store.post.text}</PostText>
            <Stack width="100%" marginBottom={2}>
                {urlListObject !== undefined &&
                    urlListObject.map((url, index) => (
                        <Stack width="100%" key={index}>
                            <LinkTypography href={url.link} target="blank">
                                {url.link}
                            </LinkTypography>
                            {url.hasVideo && (
                                <YouTubeEmbed videoUrl={url.link} />
                            )}
                        </Stack>
                    ))}
            </Stack>

            {store.post.images.length > 0 && (
                <ImageGrid images={store.post.images.length}>
                    {store.post.images.map((url, i) => (
                        <Link
                            key={url}
                            href={url}
                            target="_blank"
                            rel="noopener"
                            style={{
                                gridArea: `img-${i}`,
                            }}
                        >
                            <Image src={url} />
                        </Link>
                    ))}
                </ImageGrid>
            )}
        </>
    )
})
