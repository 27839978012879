import {
    community_CommentOnPost,
    community_PostWithAdminDetailsV2,
} from "src/api"
import {
    GridRowsPropExtended,
    IUnifiedItemParams,
} from "src/views/community-posts-and-comments/types"
import { parseDate } from "src/lib/date"
import { IPostAndCommentsItemType } from "src/types/community-posts-and-comments/communityPostsAndComments"
import { isPostItem } from "src/views/community-overview/helpers/communityHelpers"

const getStringOrDefault = (value?: string, defaultValue = "-") =>
    value != null && value.length > 0 ? value : defaultValue

const parseItemDates = (
    item: community_PostWithAdminDetailsV2 | community_CommentOnPost,
    isPost: boolean,
) => ({
    createdAt:
        parseDate(
            isPost
                ? (item as community_CommentOnPost).created_date
                : (item as community_PostWithAdminDetailsV2).created_at,
        ) ?? new Date(),
    updatedAt: parseDate(item.updated_at) ?? new Date(),
})

const areAllFlaggedCommentsHandled = (
    comments: community_CommentOnPost[],
): boolean => {
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (!comments || comments.length === 0) return false

    const flaggedComments = comments.filter((comment) =>
        Object.values(comment?.flags ?? {}).some(
            (flagValue) => flagValue === true,
        ),
    )

    return flaggedComments.length === 0
        ? comments.every((comment) => comment.is_handled === null)
        : flaggedComments.every((comment) => comment.is_handled === true)
}

const createUnifiedItem = ({
    item,
    id,
    parentHierarchy,
    itemType,
    accessType,
    communityName,
    parentId,
    communityId,
}: IUnifiedItemParams): GridRowsPropExtended => {
    const isPost = isPostItem({ itemType })
    const postItem = item as community_PostWithAdminDetailsV2
    const commentItem = item as community_CommentOnPost

    const { createdAt, updatedAt } = parseItemDates(item, isPost)

    return {
        id,
        original_id: isPost
            ? postItem.post_id ?? -1
            : commentItem.comment_id ?? -1,
        community_id: communityId ?? -1,
        parent_id: parentId,
        hierarchy: [...parentHierarchy, id],
        text: item.text ?? "-",
        author_name: getStringOrDefault(
            isPost
                ? postItem.author?.author_name
                : commentItem.author?.author_name,
            "-",
        ),
        authorObjectType: isPost ? postItem.author?.object_type ?? "-" : "-",
        authorObjectId: isPost
            ? postItem.author?.object_id ?? undefined
            : undefined,
        likes: isPost
            ? postItem.likes?.length ?? 0
            : commentItem.likes?.length ?? 0,
        comments_count: isPost ? postItem.comments_count ?? 0 : 0,
        access_type: accessType ?? (isPost ? postItem.access_type ?? "" : ""),
        created_date: createdAt,
        updated_at: updatedAt,
        community_name: getStringOrDefault(
            communityName ?? (isPost ? postItem.community_name : undefined),
        ),
        internal_apartment_id: item.internal_apartment_id ?? "-",
        apartment_no: item.apartment_no ?? "-",
        flags: item.flags,
        flagsInComments: isPost ? postItem.flags_on_comments : undefined,
        is_handled: isPost
            ? postItem.is_handled === null
                ? null
                : Boolean(postItem.is_handled)
            : commentItem.is_handled === null
            ? null
            : Boolean(commentItem.is_handled),
        itemType,
        post_id: isPost ? postItem.post_id : parentId,
        object_id: isPost
            ? postItem.author?.object_id
            : commentItem.author?.object_id,
        hasComments: isPost
            ? postItem.comments != null && postItem.comments.length > 0
                ? true
                : false
            : false,
        allCommentsIsHandled: isPost
            ? areAllFlaggedCommentsHandled(postItem?.comments ?? [])
            : undefined,
    }
}

export const createItemsFromPost = (post: community_PostWithAdminDetailsV2) => {
    const postId = `p-${post.post_id}`
    const postRow = createUnifiedItem({
        item: post,
        id: postId,
        parentHierarchy: [],
        itemType: IPostAndCommentsItemType.POST,
        communityId: post.community_id,
    })

    const commentRows = (post.comments ?? []).map((comment) =>
        createUnifiedItem({
            item: comment,
            id: `c-${comment.comment_id}`,
            parentHierarchy: [postId],
            itemType: IPostAndCommentsItemType.COMMENT,
            accessType: post.access_type,
            communityName: post.community_name,
            parentId: post.post_id ?? -1,
            communityId: post.community_id,
        }),
    )

    return [postRow, ...commentRows]
}
