/**
 * Type for values that can be in a nested object
 */
export type NestedValue =
    | string
    | number
    | boolean
    | null
    | undefined
    | NestedObject
    | NestedValue[]

/**
 * Type for a nested object with arbitrary properties
 */
export type NestedObject = {
    [key: string]: NestedValue
}

/**
 * Removes empty string or undefined values from an object
 * @param obj The object to clean
 * @param keysToCheck Optional array of property paths to check (e.g. ['prompts.context_aware_tonality'])
 *                   If not provided, all properties will be checked recursively
 * @returns A new object with empty properties removed
 */
export const removeEmptyProperties = <T extends NestedObject>(
    obj: T,
    keysToCheck?: string[],
): T => {
    if (obj === null || typeof obj !== "object" || Array.isArray(obj)) {
        return obj
    }

    // Create a copy that we can safely mutate
    const result = { ...obj } as NestedObject

    if (keysToCheck != null && keysToCheck.length > 0) {
        // Check only specific paths
        keysToCheck.forEach((keyPath) => {
            const parts = keyPath.split(".")
            let current: NestedObject = result
            let parent: NestedObject | null = null
            let lastKey = ""

            for (let i = 0; i < parts.length - 1; i++) {
                if (
                    current[parts[i]] === undefined ||
                    current[parts[i]] === null
                ) {
                    return
                }
                parent = current
                current = current[parts[i]] as NestedObject
                lastKey = parts[i]
            }

            const finalKey = parts[parts.length - 1]

            if (
                current[finalKey] === undefined ||
                (typeof current[finalKey] === "string" &&
                    current[finalKey] === "")
            ) {
                // Delete the empty property
                delete current[finalKey]

                // If this was the last property in a nested object, check if parent should be removed too
                if (
                    parent != null &&
                    lastKey.length > 0 &&
                    Object.keys(current).length === 0
                ) {
                    delete parent[lastKey]
                }
            }
        })
    } else {
        // No specific keys provided, check all properties recursively
        Object.keys(result).forEach((key) => {
            const value = result[key]

            if (
                value !== null &&
                typeof value === "object" &&
                !Array.isArray(value)
            ) {
                const cleanedValue = removeEmptyProperties(
                    value as NestedObject,
                )

                if (Object.keys(cleanedValue).length === 0) {
                    delete result[key]
                } else {
                    result[key] = cleanedValue
                }
            } else if (value === "" || value === undefined) {
                delete result[key]
            }
        })
    }

    return result as T
}

// Export the same function with a different name for backward compatibility
export const removeEmptyAllProperties = removeEmptyProperties
