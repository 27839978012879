import { observer } from "mobx-react"
import { useCallback, useMemo } from "react"
import { t } from "@lingui/macro"
import { Favorite, FavoriteBorder, MoreHoriz } from "@mui/icons-material"
import { Stack } from "@mui/material"

import {
    Body,
    Author,
    TimeSince,
    Image,
    LikeButton,
    LikeFooter,
    OpenLikesModalButton,
    ActionDropdownPosition,
    InternalObjectIDContainer,
} from "./styled"

import { trackModuleEvent } from "src/analytics/helpers/mixpanel_tracking"
import { MixpanelProperties } from "src/analytics/constants/properties"
import { TrackingSource } from "src/analytics/constants/sources"

import {
    LinkTypography,
    CommentText,
} from "src/modals/community-posts-and-comments/styled"

import { formatDateTime } from "src/lib/date"
import { ConfirmModal } from "src/modals/confirm"
import { ActionDropdown } from "src/components/ActionDropdown"
import { useStore } from "src/store/lib/useStore"
import { GlobalStore } from "src/store"

import { CommunityPostsAndCommentsStore } from "src/modals/community-posts-and-comments/store"
import { CommunityLikesModal } from "src/modals/community-posts-and-comments-likes"
import { UrlLinksHandler } from "src/lib/urlLinksHandler"
import { FlagRenderer } from "src/shared/community-posts-and-comments/components/FlagRenderer"
import { IComment } from "src/types/community-posts-and-comments/communityPostsAndComments"
import { useHandleAndReportActions } from "src/shared/community-posts-and-comments/hooks/useHandleAndReportActions"

interface IProps {
    comment: IComment
}

export const ViewCommentBody = observer((props: IProps) => {
    const store = useStore(CommunityPostsAndCommentsStore)
    const gstore = useStore(GlobalStore)
    const { actionsItems } = useHandleAndReportActions()

    const urlLinks = new UrlLinksHandler(props.comment.text)

    const handleEditCommentConfirmClick = useCallback(() => {
        gstore.modals.open(
            () => (
                <ConfirmModal
                    onConfirm={async (confirmed) => {
                        if (confirmed) {
                            await store.startEditingComment(props.comment.id)
                        }
                    }}
                    title={t`view-community-post-modal.edit-comment-swap-confirm-modal.title`}
                    content={t`view-community-post-modal.edit-comment-swap-confirm-modal.content`}
                />
            ),
            { variant: "slide-up-w600" },
        )
    }, [gstore.modals, store, props.comment.id])

    const handleEditCommentClick = useCallback(() => {
        if (store.isEditingOtherComment(props.comment.id)) {
            handleEditCommentConfirmClick()
        } else {
            store.startEditingComment(props.comment.id)
        }
    }, [store, props.comment, handleEditCommentConfirmClick])

    const handleDeleteCommentConfirm = useCallback(
        async (confirmed: boolean) => {
            if (confirmed) {
                trackModuleEvent("Delete comment", {
                    [MixpanelProperties.DeletedFrom]: TrackingSource.Post,
                    [MixpanelProperties.EventDefinition]:
                        'When user confirms the delete with "ok"',
                    [MixpanelProperties.CommentID]: props.comment.id,
                    [MixpanelProperties.PostID]: props.comment.postId,
                    [MixpanelProperties.CommentText]: props.comment.text,
                    [MixpanelProperties.DeletedBy]: gstore.session.user?.name,
                })

                await store.deleteComment(props.comment.id)
            }
        },

        // eslint-disable-next-line react-hooks/exhaustive-deps
        [
            gstore.session.user?.name,
            props.comment.id,
            props.comment.postId,
            props.comment.text,
        ],
    )

    const handleDeleteCommentConfirmModal = useCallback(() => {
        gstore.modals.open(
            () => (
                <ConfirmModal
                    onConfirm={handleDeleteCommentConfirm}
                    title={t`view-community-post-modal.delete-comment-confirm-modal.title`}
                    content={t`view-community-post-modal.delete-comment-confirm-modal.content`}
                />
            ),
            { variant: "slide-up-w600" },
        )
    }, [gstore.modals, handleDeleteCommentConfirm])

    const handleOpenLikesModalClick = useCallback(
        () =>
            gstore.modals.open(
                () => (
                    <CommunityLikesModal
                        postId={props.comment.postId}
                        commentId={props.comment.id}
                    />
                ),
                { variant: "slide-up-w600" },
            ),
        [gstore.modals, props.comment],
    )

    const isLiked = store.isCommentLikedByUser(
        props.comment.id,
        gstore.session.user?.adminId,
    )

    const renderActionOptions = useMemo(
        () => (
            <ActionDropdown
                icon={<MoreHoriz fontSize="small" />}
                items={[
                    ...actionsItems(props.comment),
                    {
                        text: t`view-community-post-modal.edit-comment-button`,
                        onClick: handleEditCommentClick,
                        hidden: !store.canEditComment(
                            props.comment.id,
                            gstore.session.user?.adminId,
                        ),
                    },
                    {
                        text: t`view-community-post-modal.delete-comment-button`,
                        destructive: true,
                        onClick: handleDeleteCommentConfirmModal,
                    },
                ]}
            />
        ),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [
            gstore.session.user?.adminId,
            handleDeleteCommentConfirmModal,
            handleEditCommentClick,
            props.comment.id,
        ],
    )

    return (
        <Stack direction="column" spacing={0.5} flex={1}>
            <Body spacing="10px">
                <Stack
                    direction="row"
                    spacing={1}
                    justifyContent="space-between"
                    alignItems="flex"
                >
                    <Stack direction="row" alignItems="center" spacing={1}>
                        <Author>{props.comment.authorName}</Author>
                        <TimeSince
                            dateTime={props.comment.createdDate?.toLocaleString()}
                        >
                            {props.comment.createdDate != null
                                ? formatDateTime(props.comment.createdDate)
                                : null}
                        </TimeSince>
                        <InternalObjectIDContainer>
                            {t({
                                id: "view-community-post-modal.internal-object-id",
                                values: {
                                    internalObjectId:
                                        props.comment.internalObjectId,
                                },
                            })}
                        </InternalObjectIDContainer>
                    </Stack>
                    <Stack direction="row" spacing={1} alignItems="center">
                        {FlagRenderer(props.comment.flags)}
                        <ActionDropdownPosition>
                            {store.post.accessType === "WRITE" &&
                                renderActionOptions}
                        </ActionDropdownPosition>
                    </Stack>
                </Stack>
                <CommentText>{props.comment.text}</CommentText>
                {urlLinks.getUrlsFromText() !== undefined &&
                    urlLinks.getUrlsFromText()?.map((url, index) => (
                        <LinkTypography key={index} href={url} target="blank">
                            {url}
                        </LinkTypography>
                    ))}
                {props.comment.images.length > 0 && (
                    <Stack direction="row" spacing={1}>
                        {props.comment.images.map((image) => (
                            <a
                                key={image}
                                href={image}
                                target="_blank"
                                rel="noreferrer"
                            >
                                <Image src={image} />
                            </a>
                        ))}
                    </Stack>
                )}

                <LikeFooter direction="row" spacing={0.5} alignItems="center">
                    <OpenLikesModalButton
                        title={t({
                            id: `view-community-post-modal.open-likes-modal-title`,
                            values: { count: props.comment.likes.length },
                        })}
                        onClick={handleOpenLikesModalClick}
                        disabled={props.comment.likes.length === 0}
                        data-testid={`ViewCommentBody/OpenLikesModal/${props.comment.id}`}
                    >
                        {props.comment.likes.length > 0 ? (
                            <Favorite
                                fontSize="inherit"
                                color={isLiked ? "error" : "inherit"}
                            />
                        ) : (
                            <FavoriteBorder fontSize="inherit" />
                        )}
                        <span>{props.comment.likes.length}</span>
                    </OpenLikesModalButton>
                </LikeFooter>
            </Body>
            <div>
                <LikeButton
                    onClick={() =>
                        store.toggleCommentLike(
                            props.comment.id,
                            gstore.session.user?.adminId,
                        )
                    }
                    disabled={
                        gstore.loading.is(
                            CommunityPostsAndCommentsStore.LoadingKeys[
                                "like-comment"
                            ],
                        ) || store.post.accessType === "READ"
                    }
                >
                    {isLiked
                        ? t`view-community-post-modal.comment-unlike-button`
                        : t`view-community-post-modal.comment-like-button`}
                </LikeButton>
            </div>
        </Stack>
    )
})
