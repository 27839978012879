/* eslint-disable import/no-relative-parent-imports */
import { Stack } from "@mui/material"
import { t } from "@lingui/macro"
import { observer } from "mobx-react"

import { DynamicSection } from "../dynamicFields"

import { IDynamicViewsProps } from "./types"

import { FormPanel } from "src/components/FormPanel"
import { IntegrationConnectorItemStore } from "src/modals/integration-connector-detail/store"
import { useStore } from "src/store/lib/useStore"
import { CenteredSpinner } from "src/components/CenteredSpinner"

export const DynamicViews = observer((props: IDynamicViewsProps) => {
    const store = useStore(IntegrationConnectorItemStore)

    if (store.isLoading) {
        return <CenteredSpinner height="45vh" />
    }

    return (
        <FormPanel
            sections={[
                {
                    header: t`integration-connector-modal.configuration`,
                    content: (
                        <Stack spacing={2}>
                            <DynamicSection
                                fields={props.inputFields}
                                setInputFields={props.setInputFields}
                                setDynamicFieldsHasChange={
                                    props.setDynamicFieldsHasChange
                                }
                                disabled={props.disabled}
                                connectorType={props.connectorType}
                            />
                        </Stack>
                    ),
                },
            ]}
        />
    )
})
