import { t } from "@lingui/macro"
import { makeAutoObservable } from "mobx"
import React from "react"

import { IChatbotConfigurationProps } from "./types/configAgentProps"

import { MixpanelProperties } from "src/analytics/constants/properties"
import { trackModuleEvent } from "src/analytics/helpers/mixpanel_tracking"
import {
    avy_api_pkg_segment_SegmentType,
    ChatbotAdminService,
    config_ConfigDefinition,
    config_DynamicConfigMap,
} from "src/api"
import { loads } from "src/channel/utils"
import { DEFAULT_ACCESS_GROUP } from "src/config"
import { FormFields } from "src/lib/form-fields"
import { createLoadingKeys } from "src/lib/loading"
import { Channel } from "src/channel"
import { reportError } from "src/lib/report"

import { IGenericAutoCompleteTextFieldValue } from "src/types/auto-complete-text-field"
import { ConfigMode } from "src/types/dynamic-configurable-form-fields/config-mode"
import { IHandleSubmitConfigurableDynamicFieldsProps } from "src/types/dynamic-configurable-form-fields/handle-submit-props"
import { removeEmptyProperties } from "src/helpers/removeEmptyProperties"

export class ChatbotConfigurationStore {
    static Context = React.createContext<ChatbotConfigurationStore | null>(null)
    static LoadingKeys = createLoadingKeys("init", "submit")

    isLoading = false

    private id: string | null = null
    private _accessGroupId: number = DEFAULT_ACCESS_GROUP.id
    private _accessType: string = "WRITE"
    private _configDefinition: config_ConfigDefinition = {}
    private _segmentIds: Array<number> = []
    private _configTypeOptions: IGenericAutoCompleteTextFieldValue[] = []
    private _publishingLevel?: avy_api_pkg_segment_SegmentType = undefined
    private _configMode: ConfigMode = ConfigMode.Single

    form = new FormFields<IChatbotConfigurationProps>({
        access_group_id: DEFAULT_ACCESS_GROUP.id,
        access_type: "NONE",
        feature_config_id: undefined,
        name: "",
        segment_ids: [],
        configs: {},
        config_type: [],
    })

    constructor() {
        makeAutoObservable(this)
    }

    //#region getters
    get segmentIDs() {
        return this._segmentIds
    }

    get isReadOnly() {
        return this._accessType === "READ"
    }

    get accessGroupId() {
        return this._accessGroupId
    }

    get configDefinition() {
        return this._configDefinition
    }

    get isEditMode() {
        return this.id !== null
    }

    get configTypeOptions() {
        return this._configTypeOptions
    }

    get configMode() {
        return this._configMode
    }

    get publishingLevel(): avy_api_pkg_segment_SegmentType | undefined {
        return this._publishingLevel
    }
    //#endregion

    //#region setters
    setAccessGroupId(id: number) {
        this._accessGroupId = id
    }

    setSegmentIDs(segmentIDs: number[]) {
        this.form.set("segment_ids", segmentIDs)
        this._segmentIds = segmentIDs
    }

    setConfigMode(value?: ConfigMode) {
        this._configMode = value ?? ConfigMode.Single
    }

    setPublishingLevel(level?: avy_api_pkg_segment_SegmentType) {
        this._publishingLevel = level
    }

    setIsLoading = (isLoading: boolean) => (this.isLoading = isLoading)

    private setId(id: string) {
        this.id = id
    }

    private setAccessType(accessType: string) {
        this._accessType = accessType
    }

    private setConfigDefinition(fields: config_ConfigDefinition) {
        this._configDefinition = fields
    }

    private setConfigTypeOptions(
        options: {
            id: string
            name: string
        }[],
    ) {
        this._configTypeOptions = options
    }
    //#endregion

    //#region operations
    @loads(() => ChatbotConfigurationStore.LoadingKeys.init)
    async init(id: string | undefined, accessGroupId: number) {
        try {
            this.setAccessGroupId(accessGroupId)

            const configDefinition =
                await ChatbotAdminService.getV1AdminChatbotAgentConfigConfigDefinition()
            this.setConfigDefinition(configDefinition)
            this.setConfigTypeOptions(
                configDefinition.definition?.map((field) => ({
                    id: field.name ?? "",
                    name: field.name ?? "",
                })) ?? [],
            )
            this.setConfigMode(configDefinition.config_mode as ConfigMode)

            if (id !== undefined) {
                this.setId(id)

                const response =
                    await ChatbotAdminService.getV1AdminChatbotAgentConfig({
                        configId: id,
                    })

                this.form.init({
                    access_group_id:
                        response?.access_group_id ?? DEFAULT_ACCESS_GROUP.id,
                    access_type: response?.access_type ?? "NONE",
                    feature_config_id: response?.feature_config_id,
                    name: response?.name ?? "",
                    segment_ids: response?.segment_ids ?? [],
                    configs: response?.config ?? {},
                    config_type:
                        response?.config !== undefined
                            ? Object.keys(response.config).map((name) => ({
                                  id: name,
                                  name,
                              }))
                            : [],
                })

                // update publishing level after location field is set
                const selectedConfigTypeConfigurationData =
                    this.getSelectedConfigTypeConfigurationData()
                this.setPublishingLevel(
                    selectedConfigTypeConfigurationData?.publishing_level,
                )

                if (response?.segment_ids !== undefined) {
                    this.setSegmentIDs(response.segment_ids)
                }
                this.setAccessGroupId(
                    response?.access_group_id ?? DEFAULT_ACCESS_GROUP.id,
                )
                if (response?.access_type !== undefined) {
                    this.setAccessType(response?.access_type)
                }
            }
        } catch (error) {
            reportError(t`chatbot-configuration-detail.load-data-fail`, error)
            this.setAccessType("READ")
        }
    }

    async update(id: string) {
        const { data } = this.form

        let config = data.config_type.reduce((acc, configType) => {
            acc[configType.id] = data.configs[configType.id] ?? {}
            return acc
        }, {} as config_DynamicConfigMap)

        const cleanedConfig = removeEmptyProperties(config)

        await ChatbotAdminService.putV1AdminChatbotAgentConfig({
            configId: id,
            request: {
                name: data.name,
                access_group_id: data.access_group_id,
                config: cleanedConfig,
            },
        })

        await ChatbotAdminService.putV1AdminChatbotAgentConfigPublish({
            configId: id,
            request: {
                published_in: this._segmentIds,
            },
        })

        Channel.send({
            name: "repository/updated",
            payload: {
                repository: "chatbot-configuration",
                action: "update",
            },
        })
    }

    async create() {
        const { data } = this.form

        // Create initial config from form data
        let config = data.config_type.reduce((acc, configType) => {
            acc[configType.id] = data.configs[configType.id] ?? {}
            return acc
        }, {} as config_DynamicConfigMap)

        const cleanedConfig = removeEmptyProperties(config)

        const response =
            await ChatbotAdminService.postV1AdminChatbotAgentConfig({
                request: {
                    name: data.name,
                    access_group_id: this.accessGroupId,
                    config: cleanedConfig,
                },
            })

        if (response.feature_config_id !== undefined) {
            await ChatbotAdminService.putV1AdminChatbotAgentConfigPublish({
                configId: `${response.feature_config_id}`,
                request: {
                    published_in: this._segmentIds,
                },
            })
        }

        Channel.send({
            name: "repository/updated",
            payload: {
                repository: "chatbot-configuration",
                action: "create",
            },
        })
    }

    @loads(() => ChatbotConfigurationStore.LoadingKeys.submit)
    async submit() {
        const { id } = this
        try {
            await this.form.catchErrors(async () => {
                if (id !== null) {
                    await this.update(id)
                } else {
                    await this.create()
                }
            })
        } catch (error) {
            reportError(t`chatbot-configuration-detail.save-item-fail`, error)
        } finally {
            this.setIsLoading(false)
        }
    }

    async handleSubmit({
        defaultLanguage,
        validateConfigurableDynamicFields,
        getConfigsApiPayload,
    }: IHandleSubmitConfigurableDynamicFieldsProps) {
        this.form.validateRequiredFields([
            { field: "name" },
            {
                field: "config_type",
                validate: (val) =>
                    Array.isArray(val) ? Boolean(val.length) : false,
            },
        ])

        const configurableDynamicFieldsHasError =
            validateConfigurableDynamicFields(defaultLanguage) === false

        if (
            this.form.hasErrors() ||
            configurableDynamicFieldsHasError === true
        ) {
            this.setIsLoading(false)
            return false
        }

        // update form.configs with latest values
        this.form.set("configs", getConfigsApiPayload() ?? {})

        trackModuleEvent("Chatbot Agent Items | Save", {
            [MixpanelProperties.ItemName]: this.form.data.name,
            [MixpanelProperties.AccessGroupID]: this.form.data.access_group_id,
        })

        await this.submit()
        return true
    }
    //#endregion

    //# region helpers
    getSelectedConfigTypeConfigurationData() {
        return this.configDefinition.definition?.find(
            (field) => field.name === this.form.get("config_type")?.[0]?.id,
        )
    }
    //#endregion
}
