import { useEffect, useRef } from "react"
import { observer } from "mobx-react"
import { Button, Stack, styled, TextField as MUITextField } from "@mui/material"
import { t } from "@lingui/macro"

import { useStore } from "src/store/lib/useStore"
import { CommunityPostsAndCommentsStore } from "src/modals/community-posts-and-comments/store"
import { GlobalStore } from "src/store"
import { IComment } from "src/types/community-posts-and-comments/communityPostsAndComments"

const Wrap = styled("div")({ position: "relative", width: "100%" })

const TextField = styled(MUITextField)(({ theme }) => ({
    background: theme.palette.common.white,
    "& .MuiInputBase-root": {
        padding: "10px",
        borderRadius: "0 8px 8px 8px",
        paddingBottom: "50px",
    },
}))

const ActionContainer = styled(Stack)({
    position: "absolute",
    bottom: "10px",
    right: "10px",
})

interface IProps {
    comment: IComment
}

export const EditCommentBody = observer((props: IProps) => {
    const store = useStore(CommunityPostsAndCommentsStore)
    const gstore = useStore(GlobalStore)

    const form = store.getEditingCommentForm(props.comment.id)
    const inputRef = useRef<HTMLInputElement | null>(null)

    const isButtonDisabled = gstore.loading.is(
        CommunityPostsAndCommentsStore.LoadingKeys["update-comment"],
    )

    useEffect(() => {
        const input = inputRef.current
        if (input != null && form != null) {
            input.focus()
            const textLength = form.get("text").length
            input.selectionStart = textLength
            input.selectionEnd = textLength
        }
        // We don't want `form` in deps as we only want to focus once on mount.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inputRef])

    if (form == null) {
        // This should never happen. This text will not be visisble in the ui.
        throw new Error("No comment form found")
    }

    return (
        <Wrap>
            <TextField
                inputRef={inputRef}
                value={form.get("text")}
                onChange={form.setter("text")}
                multiline
            />
            <ActionContainer direction="row" spacing={1}>
                <Button
                    variant="outlined"
                    color="info"
                    size="small"
                    onClick={() => store.cancelEditingComment()}
                    disabled={isButtonDisabled}
                >
                    {t`view-community-post-modal.edit-comment-cancel-button`}
                </Button>
                <Button
                    variant="contained"
                    color="info"
                    size="small"
                    onClick={() => store.submitEditedComment()}
                    disabled={isButtonDisabled}
                >
                    {t`view-community-post-modal.edit-comment-save-button`}
                </Button>
            </ActionContainer>
        </Wrap>
    )
})
