import { observer } from "mobx-react"
import { useCallback, useMemo } from "react"
import { useTheme, Grid, Stack, Typography, Tooltip, Chip } from "@mui/material"

import { convertHexToRgba } from "src/helpers/convertHexToRgba"
import { useGetStatisticsRowItems } from "src/views/community-overview/hooks/useGetStatisticsRowItems"
import { StyledStatisticsRowGrid } from "src/views/community-overview/styled"

export const StatisticsRow = observer(() => {
    const { palette } = useTheme()
    const items = useGetStatisticsRowItems()

    const chipLabel = useCallback(
        (value: string | number) => {
            return (
                <Typography
                    variant="h6"
                    color={palette.utility.darkBlue.main}
                    sx={{
                        paddingX: { xs: 1, xl: 2 },
                        paddingY: { xs: 1 },
                        fontSize: { xs: "0.875rem", sm: "1.25rem" },
                    }}
                >
                    {value}
                </Typography>
            )
        },
        [palette.utility.darkBlue.main],
    )

    const renderItems = useMemo(() => {
        return items.map((item, index) => (
            <StyledStatisticsRowGrid
                item
                key={index}
                padding={{ xs: 1, xl: 2 }}
                xs={12}
                md={6}
                xl={3}
            >
                <Stack
                    display="flex"
                    direction={"row"}
                    spacing={{ xs: 1, sm: 0 }}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                >
                    <Stack direction="row" display="flex" alignItems="center">
                        {item.icon}
                        <Typography
                            variant="h6"
                            marginLeft={2}
                            color={palette.text.secondary}
                            sx={{
                                fontSize: { xs: "0.875rem", sm: "1.25rem" },
                            }}
                        >
                            {item.name}
                        </Typography>
                    </Stack>
                    <Tooltip title={item.tooltip}>
                        <Chip
                            label={chipLabel(item.value)}
                            sx={{
                                background: convertHexToRgba(
                                    palette.utility.darkBlue.main,
                                ),
                                color: palette.utility.darkBlue.main,
                                borderRadius: 100,
                                height: "auto",
                                "& .MuiChip-label": {
                                    padding: 0,
                                },
                            }}
                        />
                    </Tooltip>
                </Stack>
            </StyledStatisticsRowGrid>
        ))
    }, [
        items,
        palette.text.secondary,
        palette.utility.darkBlue.main,
        chipLabel,
    ])

    return (
        <Grid
            container
            sx={{
                borderTop: `1px solid ${palette.grey[200]}`,
                borderBottom: `1px solid ${palette.grey[200]}`,
            }}
        >
            {renderItems}
        </Grid>
    )
})
