import { t } from "@lingui/macro"
import { Link } from "@mui/material"
import { observer } from "mobx-react"
import { useCallback, useEffect, useMemo } from "react"
import { useParams } from "react-router"

import { ListPage } from "src/components/ListPage"
import { SegmentPickerButton } from "src/components/SegmentPickerButton"
import { StatusChip } from "src/components/StatusChip"
import { DataGridProTable } from "src/components/Table/DataGridPro"
import { DEFAULT_ACCESS_GROUP } from "src/config"
import {
    getFilterModelForRepository,
    getSortModelForRepository,
} from "src/lib/data-grid-pro"
import { ConfirmModal } from "src/modals/confirm"
import { LibraryInformationItemModal } from "src/modals/library-information-items"
import { GlobalStore } from "src/store"
import { useStore } from "src/store/lib/useStore"
import { Repository } from "src/types/channel"
import { FilterModel, IColumn, SortModel } from "src/types/data-grid-pro"
import { ILibraryInformationItem } from "src/types/library"
import { IItemTypeValueOptions } from "src/views/library-information-items/types/itemTypeValueOptions"
import { getItemTypeText } from "src/helpers/getItemTypeText"
import { ItemTypes } from "src/types/library-information-items/item-types"
import { LibraryInformationItemsStore } from "src/views/library-information-items/store"
import { StoreProvider } from "src/store/lib/StoreProvider"
import { trackModuleEvent } from "src/analytics/helpers/mixpanel_tracking"
import { MixpanelProperties } from "src/analytics/constants/properties"
import { TableChipCell } from "src/components/TableChipCell"
import { RowCellContainer } from "src/components/RowCellContainer"
import { AccessTypeHeader } from "src/components/AccessTypeHeader"

const repository: Repository = "library-information-items"

const View = observer(() => {
    const store = useStore(LibraryInformationItemsStore)
    const globalStore = useStore(GlobalStore)
    let { informationItemId } = useParams()

    useEffect(() => {
        ;(async () => {
            if (globalStore.session.accessGroup.id !== undefined) {
                await store.init(
                    globalStore.session.accessGroup.id,
                    advanceQuery,
                )
            } else {
                await store.init(DEFAULT_ACCESS_GROUP.id, advanceQuery)
            }
        })()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [store, globalStore.session.accessGroup])

    useEffect(() => {
        ;(async () => {
            if (informationItemId !== undefined) {
                await handleOpenModal(Number(informationItemId))
            }
        })()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const header = {
        header: t`library-view.heading`,
        breadcrumbs: [
            t`library-view.heading`,
            t`library-information-items-view.breadcrumb`,
        ],
        createOptions: {
            onClick: () =>
                globalStore.modals.open(() => <LibraryInformationItemModal />),
            item: "",
        },
        disabled: false,
    }

    const handleOpenModal = useCallback(
        async (id: number) => {
            const item = await store.getItem(id)
            globalStore.modals.open(() => (
                <LibraryInformationItemModal informationItem={item} />
            ))
        },
        [store, globalStore.modals],
    )

    /* This function is used to open modal from both places:
    1. row: which has item object like {id:1, row: itemObject}
    2. action menu: which is itemObject itself
    */
    const handleEditItem = useCallback(
        (item) => {
            if (item != null) {
                trackModuleEvent("Library | Edit", {
                    [MixpanelProperties.ItemID]: item.id,
                    [MixpanelProperties.ItemName]: item.display_name,
                    [MixpanelProperties.AccessGroupName]:
                        globalStore.session.accessGroup?.name,
                    [MixpanelProperties.AccessGroupID]:
                        globalStore.session.accessGroup?.id,
                })
                globalStore.modals.open(() => (
                    <LibraryInformationItemModal
                        informationItem={item.row ?? item}
                    />
                ))
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [globalStore.modals],
    )

    const handleCopyItem = useCallback(
        (item) => {
            if (item != null) {
                trackModuleEvent("Library | Copy", {
                    [MixpanelProperties.ItemID]: item.information_item_id,
                    [MixpanelProperties.ItemName]: item.admin_name,
                    [MixpanelProperties.AccessGroupName]:
                        globalStore.session.accessGroup?.name,
                    [MixpanelProperties.AccessGroupID]:
                        globalStore.session.accessGroup?.id,
                })
                globalStore.modals.open(() => (
                    <LibraryInformationItemModal
                        informationItem={item}
                        mode="Copy"
                    />
                ))
            }
        },
        [
            globalStore.modals,
            globalStore.session.accessGroup?.id,
            globalStore.session.accessGroup?.name,
        ],
    )

    const handleUnpublishItem = useCallback(
        async (informationItemId: number) => {
            await store.unpublishItem(informationItemId)
        },
        [store],
    )

    const handleDeleteItem = useCallback(
        (informationItemId: number, adminName: string) => {
            globalStore.modals.open(
                () => (
                    <ConfirmModal
                        onConfirm={async (confirmed) => {
                            if (confirmed) {
                                await store.deleteItem(informationItemId)
                            }
                        }}
                        title={t`library-information-items-view.are-you-sure`}
                        content={t({
                            id: "library-information-items-view.confirm-delete-item",
                            values: { adminName: adminName },
                        })}
                    />
                ),
                {
                    variant: "slide-up-w600",
                },
            )
        },
        [globalStore.modals, store],
    )

    const handleSegmentChange = useCallback(
        (segments: number[]) => store.loadSegments(segments),
        [store],
    )

    const filter = useMemo(
        () => ({
            query: store.informationItems.meta.search ?? "",
            actions: (
                <>
                    <SegmentPickerButton
                        value={store.segments}
                        onChange={handleSegmentChange}
                    />
                </>
            ),
        }),
        [
            handleSegmentChange,
            store.informationItems.meta.search,
            store.segments,
        ],
    )

    const rowActionsRenderer = useCallback(
        (item: ILibraryInformationItem) => {
            const actions = [
                {
                    text: t`library-information-items-view.edit-item`,
                    onClick: () => handleEditItem(item),
                },
                {
                    text: t`library-information-items-view.copy-item`,
                    onClick: () => handleCopyItem(item),
                },
                {
                    text: t`library-information-items-view.delete-item`,
                    onClick: () =>
                        handleDeleteItem(
                            item.information_item_id as number,
                            item.admin_name as string,
                        ),
                },
            ]

            if (item.published_in != null && item.published_in.length > 0) {
                actions.splice(2, 0, {
                    text: t`library-information-items-view.unpublish-item`,
                    onClick: () =>
                        handleUnpublishItem(item.information_item_id as number),
                })
            }

            return actions
        },
        [handleEditItem, handleCopyItem, handleDeleteItem, handleUnpublishItem],
    )

    const advanceQuery = useMemo(() => {
        const filter = getFilterModelForRepository(
            repository,
            globalStore.session.dataGridFilterModel,
        )
        const sort = getSortModelForRepository(
            repository,
            globalStore.session.dataGridSortModel,
        )
        return { sort, filter }
    }, [
        globalStore.session.dataGridSortModel,
        globalStore.session.dataGridFilterModel,
    ])

    const handleFilterChange = useCallback(
        async (model: FilterModel) => {
            const sort = getSortModelForRepository(
                repository,
                globalStore.session.dataGridSortModel,
            )

            const modifiedModel: FilterModel = {
                ...model,
                items: model.items.map((item) => ({
                    ...item,
                    field: item.field === "tag_names" ? "tag_ids" : item.field,
                })),
            }

            await store.query({ sort, filter: modifiedModel })
        },
        [store, globalStore.session.dataGridSortModel],
    )

    const handleSortChange = useCallback(
        async (model: SortModel) => {
            const filter = getFilterModelForRepository(
                repository,
                globalStore.session.dataGridFilterModel,
            )

            await store.query({ filter, sort: model })
        },
        [store, globalStore.session.dataGridFilterModel],
    )

    const itemTypeValueOptions: IItemTypeValueOptions[] = useMemo(() => {
        return [
            {
                label: t`library-information-items-view.types.page`,
                value: ItemTypes.Page,
            },
            {
                label: t`library-information-items-view.types.link-url`,
                value: ItemTypes.Url,
            },
            {
                label: t`library-information-items-view.types.document`,
                value: ItemTypes.Document,
            },
        ]
    }, [])

    const tagsValueOptions = useMemo(() => {
        return (store.tags ?? []).map((tag) => ({
            label: tag.name,
            value: tag.tag_id,
        }))
    }, [store.tags])

    const columns: IColumn<ILibraryInformationItem>[] = [
        {
            field: "admin_name",
            headerName: t`library-information-items-view.item`,
            renderCell: (params) => (
                <Link component="button" sx={{ textAlign: "left" }}>
                    {params.value}
                </Link>
            ),
            type: "string",
            minWidth: 200,
            flex: 2,
        },
        {
            field: "information_item_id",
            headerName: t`library-information-items-view.item-number`,
            renderCell: (params) => params.value,
            filterable: false,
            sortable: false,
            type: "number",
        },
        {
            field: "item_type",
            headerName: t`library-information-items-view.type`,
            sortable: false,
            renderCell: (params) => (
                <RowCellContainer>
                    {params.value != null ? getItemTypeText(params.value) : ""}
                </RowCellContainer>
            ),
            type: "singleSelect",
            // @ts-ignore
            // Value options is available on one of the options for type of TColumn(GridSingleSelectColDef), but it's not reading here
            valueOptions: itemTypeValueOptions,
        },
        {
            field: "category_names",
            headerName: t`library-information-items-view.category`,
            filterable: false,
            sortable: false,
            renderCell: (params) => params.value?.join(", "),
            minWidth: 120,
            flex: 1.5,
        },
        {
            field: "tag_names",
            headerName: t`library-information-items-view.tags-header`,
            sortable: false,
            renderCell: (params) => (
                <TableChipCell
                    value={params.value}
                    label={t`tags-cell-component.tags`.toLowerCase()}
                />
            ),
            minWidth: 120,
            flex: 1.5,
            type: "singleSelect",
            // @ts-ignore
            // Value options is available on one of the options for type of TColumn(GridSingleSelectColDef), but it's not reading here
            valueOptions: tagsValueOptions,
        },
        {
            field: "segment_names",
            headerName: t`library-information-items-view.visible-for`,
            filterable: false,
            sortable: false,
            renderCell: (params) => {
                const segmentNames =
                    params.value != null &&
                    params.value?.length > 0 &&
                    params.value?.length === 1
                        ? params.value[0]
                        : `(${
                              params.value?.length
                          }) ${t`library-information-items-view.segments`.toLowerCase()}`

                return segmentNames ?? ""
            },
            minWidth: 120,
            flex: 1.5,
        },
        {
            field: "published_in",
            headerName: t`library-information-items-view.status`,
            filterable: false,
            sortable: false,
            renderCell: (params) => {
                const isPublished =
                    params.value != null && params.value.length > 0

                return (
                    <StatusChip
                        label={
                            isPublished
                                ? t`library-information-items-view.published`
                                : t`library-information-items-view.draft`
                        }
                        state={isPublished ? "positive" : "neutral"}
                    />
                )
            },
        },
        {
            field: "include_in_chatbot",
            headerName: t`library-information-items-view.included-in-chatbot`,
            renderCell: (params) =>
                params.value === true
                    ? t`library-information-items-view.included-in-chatbot-yes`
                    : t`library-information-items-view.included-in-chatbot-no`,
            sortable: false,
            type: "boolean",
        },
        {
            field: "access_type",
            headerName: t`library-information-items-view.access-type`,
            filterable: false,
            sortable: false,
            renderCell: (params) => (
                <AccessTypeHeader accessType={params.value} />
            ),
        },
    ]

    return (
        <ListPage
            header={header}
            filter={filter}
            loading={!store.informationItems.meta.initialized}
        >
            <DataGridProTable
                paginator={store.informationItems}
                data={store.informationItems.items}
                columns={columns}
                advancedOperations={{
                    pagination: "server",
                    filtering: "server",
                    sorting: "client",
                }}
                onRowClickEvent={handleEditItem}
                rowActionsRenderer={rowActionsRenderer}
                repository={repository}
                loading={!store.informationItems.meta.initialized}
                onFilterChange={handleFilterChange}
                onSortChange={handleSortChange}
            />
        </ListPage>
    )
})

export const LibraryInformationItemsView = () => (
    <StoreProvider Store={LibraryInformationItemsStore}>
        <View />
    </StoreProvider>
)
