import {
    avy_api_pkg_admin_domain_ResourceAccessType,
    community_CommunityFlags,
} from "src/api"

type IAuthorObjectType = "admin_user" | "tenant" | "none"

interface ICommonProps {
    id: number
    original_id: number
    authorObjectType: IAuthorObjectType
    authorObjectId: number
    authorName: string
    createdDate: Date | null
    accessType: avy_api_pkg_admin_domain_ResourceAccessType
    text: string
    apartmentNumber: string
    internalObjectId: string
    flags: community_CommunityFlags
    is_handled: boolean | null
    images: string[]
}

export interface ILikeAuthor {
    authorName?: string
    objectId: number
    objectType: IAuthorObjectType
}

export enum IPostAndCommentsItemType {
    POST = "post",
    COMMENT = "comment",
}

export interface IPost extends ICommonProps {
    itemType: IPostAndCommentsItemType.POST
    authorId: number
    authorIsBlocked: boolean
    categoryName: string
    commentCount: number
    likeCount: number
    likes: ILikeAuthor[]
    communityId: number
}

export interface IComment extends ICommonProps {
    itemType: IPostAndCommentsItemType.COMMENT
    postId: number
    likes: ILikeAuthor[]
}

export interface IPostAndCommentsItem {
    id: string
    original_id: number
    community_id: number
    parent_id?: number
    post_id?: number
    object_id?: number
    flags?: community_CommunityFlags
    flagsInComments?: community_CommunityFlags
    is_handled: boolean | null
    text: string
    authorObjectType?: string
    authorObjectId?: number
    author_name: string
    likes: number
    comments_count: number
    access_type: string
    created_date: Date
    community_name?: string
    internal_apartment_id: string | number
    apartment_no: string
    updated_at: Date
    isChild?: boolean
    itemType: IPostAndCommentsItemType
    hierarchy: string[]
    commentId?: number | undefined
    __tree_data_group__?: string
    allCommentsIsHandled?: boolean
    hasComments?: boolean
}

export interface IAuthor {
    // `id` is a unique key for this author within Manager. It's not the id for
    // the author in the backend.
    id: string
    name: string
}
