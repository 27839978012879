import { DatePicker } from "@mui/lab"
import { Grid, Stack, TextField } from "@mui/material"
import { observer } from "mobx-react"
import { useCallback } from "react"
import { t } from "@lingui/macro"

import { format } from "date-fns"

import { useStore } from "src/store/lib/useStore"
import { CommunitiesOverviewViewStore } from "src/views/community-overview/store"
import { MultipleSelectCheckmarks } from "src/components/MultipleSelectCheckmarks"
import { TMultipleSelectCheckmarksOption } from "src/components/MultipleSelectCheckmarks/types"
import { trackModuleEvent } from "src/analytics/helpers/mixpanel_tracking"
import { MixpanelProperties } from "src/analytics/constants/properties"

import { formatCommunityNames } from "src/views/community-overview/helpers/communityHelpers"

export const CommunityOverviewHeader = observer(() => {
    const store = useStore(CommunitiesOverviewViewStore)

    const handleDateRangeChange = useCallback(
        (value: Date) => {
            trackModuleEvent("Month picker - community overview", {
                [MixpanelProperties.MonthSelected]: format(value, "yyyy-MM"),
                [MixpanelProperties.EventDefinition]: "Month/year picker",
            })
            return store.setDateRangeFilter(value)
        },
        [store],
    )

    const handleFilterBySelectedCommunity = useCallback(
        (selectedCommunities: TMultipleSelectCheckmarksOption[]) => {
            if (selectedCommunities.length > 0) {
                trackModuleEvent("Community filter - overview", {
                    [MixpanelProperties.CommunityNameFilter]:
                        formatCommunityNames(selectedCommunities),
                    [MixpanelProperties.CommunityCount]:
                        selectedCommunities.length,
                })
            }
            store.filterBySelectedCommunity(selectedCommunities)
        },

        [store],
    )

    return (
        <Grid
            container
            sx={{ paddingX: 1, paddingY: 1, width: "auto", display: "flex" }}
        >
            <Grid
                item
                sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                }}
            >
                <Stack
                    direction="row"
                    sx={{
                        width: {
                            xs: "100%",
                            sm: "260px",
                        },
                        spacing: 1.25,
                    }}
                >
                    <DatePicker
                        views={["year", "month"]}
                        label={t`community-overview.date-range-header.year-month`}
                        minDate={new Date("2012-03-01")}
                        value={store.startDate}
                        onChange={(date) =>
                            date !== null && handleDateRangeChange(date)
                        }
                        renderInput={(params) => (
                            <TextField {...params} helperText={null} />
                        )}
                    />
                </Stack>
                <MultipleSelectCheckmarks
                    label={t`community-view.header`}
                    options={store.communitiesForMultiSelect}
                    onChange={handleFilterBySelectedCommunity}
                />
            </Grid>
        </Grid>
    )
})
