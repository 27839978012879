import { CloseRounded } from "@mui/icons-material"
import { Stack, styled } from "@mui/material"
import { observer } from "mobx-react"
import { useCallback } from "react"

import { CommunityPostsAndCommentsStore } from "src/modals/community-posts-and-comments/store"
import { GlobalStore } from "src/store"
import { useStore } from "src/store/lib/useStore"

const Image = styled("img")(({ theme }) => ({
    display: "block",
    width: "80px",
    height: "80px",
    objectFit: "cover",
    borderRadius: "4px",
    borderWidth: "1px",
    borderStyle: "solid",
    borderColor: theme.palette.grey[500],
}))

const DeleteButton = styled("button")({
    width: "16px",
    height: "16px",
    background: "#333",
    color: "#fff",
    borderRadius: "1000px",
    position: "absolute",
    top: "-8px",
    right: "-8px",
    border: "none",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "12px",
    cursor: "pointer",
})

const Cell = styled("div")({ position: "relative" })

export const ImageList = observer(() => {
    const gstore = useStore(GlobalStore)
    const store = useStore(CommunityPostsAndCommentsStore)

    const isSubmitting = gstore.loading.is(
        CommunityPostsAndCommentsStore.LoadingKeys["submit-comment"],
    )

    const createDeleteClickHandler = useCallback(
        (index: number) => () => {
            store.deleteImageAtIndex(index)
        },
        [store],
    )

    return (
        <Stack direction="row" spacing={1}>
            {store.commentForm.get("images").map((image, index) => (
                <Cell key={image.url}>
                    <Image src={image.url} />
                    <DeleteButton
                        onClick={createDeleteClickHandler(index)}
                        disabled={isSubmitting}
                        type="button"
                    >
                        <CloseRounded fontSize="inherit" />
                    </DeleteButton>
                </Cell>
            ))}
        </Stack>
    )
})
