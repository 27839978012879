import { Box, Stack } from "@mui/material"
import { observer } from "mobx-react"
import { useCallback } from "react"

import { ImageUploadField } from "src/components/ImageUploadField"
import { CommunityPostsAndCommentsEditStore } from "src/modals/community-posts-and-comments-edit/store"
import { useStore } from "src/store/lib/useStore"

export const ImageSection = observer(() => {
    const store = useStore(CommunityPostsAndCommentsEditStore)

    const createUploadImageHandler = useCallback(
        (index: number) => (image: IFile | null) => {
            store.setImageAtIndex(image, index)
        },
        [store],
    )

    return (
        <Stack direction="row" spacing={2}>
            {store.form.get("images").map((image, i) => (
                <Box key={i} sx={{ flex: 1 }}>
                    <ImageUploadField
                        value={image}
                        onChange={createUploadImageHandler(i)}
                        disabled={store.form.get("accessType") === "READ"}
                    />
                </Box>
            ))}
        </Stack>
    )
})
