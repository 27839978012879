import { IconButton, Menu, MenuItem, Typography } from "@mui/material"
import { observer } from "mobx-react"
import React, { useCallback, useEffect, useRef, useState } from "react"
import { debounce } from "lodash"

import { More24 } from "src/components/icons/More24"
import { useUniqueId } from "src/lib/unique-id"

interface IItem {
    text: string
    onClick: (event: React.MouseEvent<Element>) => void
    destructive?: boolean
    hidden?: boolean
    loading?: boolean
}

interface IProps {
    icon?: React.ReactNode
    items: IItem[]
    onActionMenuClose?: () => void
    onActionMenuOpen?: () => void
}

export type IActionDropdownItem = IItem

export const ActionDropdown = observer((props: IProps) => {
    const anchorRef = useRef<HTMLButtonElement | null>(null)

    const buttonId = useUniqueId()
    const menuId = useUniqueId()

    const [open, setOpen] = useState(false)

    useEffect(() => {
        open ? props.onActionMenuOpen?.() : props.onActionMenuClose?.()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open])

    const handleButtonClick = useCallback(
        (event: React.MouseEvent<HTMLButtonElement>) => {
            event.stopPropagation()
            setOpen(true)
        },
        [],
    )

    const handleMenuClose = useCallback(
        (event: React.MouseEvent<HTMLButtonElement>) => {
            event.stopPropagation()
            setOpen(false)
        },
        [],
    )

    const handleItemClick = debounce(
        (event: React.MouseEvent<Element>, fn: IItem["onClick"]) => {
            event.stopPropagation()
            event.preventDefault()
            fn(event)
            setOpen(false)
        },
        300,
    )

    if (props.items.length < 1) {
        return null
    }

    return (
        <div>
            <IconButton
                data-testid="Actions"
                id={buttonId}
                ref={anchorRef}
                aria-haspopup="listbox"
                aria-controls={menuId}
                aria-expanded={open ? "true" : undefined}
                onClick={handleButtonClick}
            >
                {props.icon ?? <More24 />}
            </IconButton>
            <Menu
                data-testid="Actions-menu"
                id={menuId}
                anchorEl={anchorRef.current}
                open={open}
                onClose={handleMenuClose}
                MenuListProps={{
                    "aria-labelledby": buttonId,
                    role: "listbox",
                }}
            >
                {props.items.map((item) =>
                    item.hidden !== true ? (
                        <MenuItem
                            key={item.text}
                            onClick={(event) =>
                                handleItemClick(event, item.onClick)
                            }
                            disabled={item.loading === true}
                        >
                            <Typography
                                color={
                                    item.destructive === true
                                        ? "red"
                                        : undefined
                                }
                            >
                                {item.text}
                            </Typography>
                        </MenuItem>
                    ) : null,
                )}
            </Menu>
        </div>
    )
})
