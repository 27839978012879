/**
 * Enum for standardizing source locations in tracking events
 * Used to ensure consistency in tracking data across the application
 */
export enum TrackingSource {
    Post = "Post",
    ListView = "List View",
    Overview = "Overview",
    Comment = "Comment",
}

/**
 * Enum for standardizing action types in tracking events
 */
export enum TrackingAction {
    Blocked = "block",
    Unblocked = "unblock",
    Deleted = "delete",
    Handled = "handled",
    Unhandled = "unhandled",
    Reported = "reported",
}
