import { useMemo } from "react"
import { t } from "@lingui/macro"
import {
    Stack,
    styled,
    NativeSelect,
    FormControl,
    InputLabel,
    Typography,
} from "@mui/material"

import {
    GRID_TREE_DATA_GROUPING_FIELD,
    GridRenderCellParams,
} from "@mui/x-data-grid-pro"

import { TruncatedText } from "src/components/TruncatedText"
import { IColumn } from "src/types/data-grid-pro"
import { IPostAndCommentsItem } from "src/types/community-posts-and-comments/communityPostsAndComments"
import { formatInteger } from "src/lib/number"
import { Time } from "src/components/Time"
import { AccessTypeHeader } from "src/components/AccessTypeHeader"
import { FlagRenderer } from "src/shared/community-posts-and-comments/components/FlagRenderer"

const NoWrap = styled("span")({
    whiteSpace: "nowrap",
})

const TypographyWrap = styled(Typography)({
    whiteSpace: "normal",
    wordWrap: "break-word",
    display: "-webkit-box",
    overflow: "hidden",
    lineHeight: "1.31em",
    maxHeight: "2.62em",
})
const hasTrueFlags = (flagsObj: { [key: string]: boolean }) => {
    if (flagsObj == null) return false

    return Object.entries(flagsObj).some(([_, value]) => value === true)
}

const communityHandledPostCell = ({ value }: GridRenderCellParams) => {
    const COMMUNITY_POST_STATUS = {
        YES: t`community-posts-view.yes`,
        NO: t`community-posts-view.no`,
        NOT_FLAGGED: t`community-posts-view-not-flagged`,
    } as const

    return value === null
        ? COMMUNITY_POST_STATUS.NOT_FLAGGED
        : value === true
        ? COMMUNITY_POST_STATUS.YES
        : COMMUNITY_POST_STATUS.NO
}

const useCommunityPostsColumns = (): IColumn<IPostAndCommentsItem>[] => {
    return useMemo(
        () => [
            {
                field: "flags",
                headerName: t`community-posts-view.flags-header`,
                type: "string",
                minWidth: 140,
                disableReorder: true,
                renderCell: (params) => {
                    if (params.row["itemType"] === "comment") {
                        return ""
                    }
                    return FlagRenderer(
                        params.value,
                        params.row["flagsInComments"],
                    )
                },
                filterable: false,
                sortable: false,
            },
            {
                field: GRID_TREE_DATA_GROUPING_FIELD,
                filterable: false,
                sortable: false,
            },
            {
                field: "text",
                headerName: t`community-posts-view.text-header`,
                renderCell: (params) => {
                    if (params.row["itemType"] === "comment") {
                        return (
                            <Stack direction="row">
                                <div
                                    style={{
                                        justifyContent: "center",
                                        alignItems: "center",
                                        display: "flex",
                                    }}
                                >
                                    {FlagRenderer(params.row["flags"])}
                                </div>
                                <Stack width={6} />
                                <TypographyWrap
                                    variant="body2"
                                    title={params.value}
                                >
                                    {params.value}
                                </TypographyWrap>
                            </Stack>
                        )
                    }
                    return (
                        <TypographyWrap variant="body2" title={params.value}>
                            {params.value}
                        </TypographyWrap>
                    )
                },
                type: "string",
                minWidth: 420,
                flex: 2,
            },
            {
                field: "is_handled",
                headerName: t`community-posts-view.handled-header`,
                filterable: true,
                sortable: false,
                renderCell: communityHandledPostCell,
                minWidth: 120,
                type: "boolean",
                filterOperators: [
                    {
                        label: "is",
                        value: "is",
                        getApplyFilterFn: (filterItem) => {
                            if (filterItem.value === undefined) {
                                return null
                            }

                            return (params) => {
                                if (filterItem.value === null) {
                                    return hasTrueFlags(params.row["flags"])
                                }
                                return params.value === filterItem.value
                            }
                        },
                        InputComponent: ({ item, applyValue }) => (
                            <FormControl
                                variant="standard"
                                sx={{ m: 0, minWidth: 120 }}
                            >
                                <InputLabel>{t`community-posts-view.value`}</InputLabel>
                                <NativeSelect
                                    value={
                                        item.value === null
                                            ? "not-flagged"
                                            : item.value === undefined
                                            ? "any"
                                            : item.value.toString()
                                    }
                                    inputProps={{
                                        name: "Value",
                                    }}
                                    onChange={(e) => {
                                        const value = e.target.value
                                        applyValue({
                                            ...item,
                                            value:
                                                value === "any"
                                                    ? undefined
                                                    : value === "not-flagged"
                                                    ? null
                                                    : value === "true",
                                        })
                                    }}
                                >
                                    <option value="any">Any</option>
                                    <option value="true">{t`community-posts-view.yes`}</option>
                                    <option value="false">{t`community-posts-view.no`}</option>
                                    <option value="not-flagged">{t`community-posts-view-not-flagged`}</option>
                                </NativeSelect>
                            </FormControl>
                        ),
                    },
                ],
            },
            {
                field: "community_name",
                headerName: t`community-posts-view.community-breadcrumb`,
                renderCell: (params) => (
                    <NoWrap>
                        <TruncatedText text={params.value ?? ""} max={20} />
                    </NoWrap>
                ),
                type: "string",
                minWidth: 120,
            },
            {
                field: "community_id",
                headerName: t`community-posts-view.community-id-header`,
                renderCell: (params) => params.value,
                type: "number",
                minWidth: 120,
            },
            {
                field: "post_id",
                headerName: t`community-posts-view.post-id-header`,
                renderCell: (params) => params.value,
                type: "number",
                minWidth: 120,
            },
            {
                field: "apartment_no",
                headerName: t`community-posts-view.object-id-header`,
                renderCell: (params) => params.value,
                type: "string",
                sortable: false,
                minWidth: 120,
            },
            {
                field: "author_name",
                headerName: t`community-posts-view.author-header`,
                renderCell: (params) => (
                    <NoWrap>
                        <TruncatedText text={params.value ?? ""} max={20} />
                    </NoWrap>
                ),
                minWidth: 120,
                type: "string",
            },

            {
                field: "internal_apartment_id",
                headerName: t`community-posts-view.internal-apartment-id-header`,
                renderCell: (params) => params.value,
                type: "string",
                minWidth: 120,
            },
            {
                field: "likes",
                headerName: t`community-posts-view.likes-header`,
                filterable: false,
                sortable: false,
                renderCell: (params) => formatInteger(params.value),
                minWidth: 120,
            },
            {
                field: "comments_count",
                headerName: t`community-posts-view.comments-header`,
                filterable: false,
                sortable: false,
                renderCell: (params) => formatInteger(params.value),
                minWidth: 120,
            },
            {
                field: "created_date",
                headerName: t`community-posts-view.created-header`,
                renderCell: (params) => <Time date={params.value} />,
                type: "date",
                minWidth: 120,
            },
            {
                field: "access_type",
                headerName: t`community-view.access-type`,
                filterable: false,
                sortable: false,
                renderCell: (params) => (
                    <AccessTypeHeader accessType={params.value} />
                ),
                minWidth: 120,
            },
            {
                field: "updated_at",
                headerName: t`community-comments-view.last-update-header`,
                renderCell: (params) => <Time date={params.value} />,
                type: "date",
                minWidth: 160,
            },
        ],
        [],
    )
}

export default useCommunityPostsColumns
