import { Box, Chip, styled, TextField } from "@mui/material"

export const StyledChip = styled(Chip, {
    shouldForwardProp: (prop) => prop !== "chipColor",
})<{ chipColor?: string }>(({ theme, chipColor }) => ({
    backgroundColor: chipColor ?? theme.palette.primary.main,
    borderRadius: 16,
    "& .MuiChip-deleteIcon": {
        backgroundColor: theme.palette.grey[200],
        borderRadius: "50%",
        padding: 2,
        color: theme.palette.grey[600],
        "&:hover": {
            backgroundColor: theme.palette.grey[400],
        },
        marginRight: 4,
    },
}))

export const FilterContainer = styled(Box)(() => ({
    width: "100%",
}))

export const StyledTextField = styled(TextField)(() => ({
    "& .MuiOutlinedInput-root": {
        height: "52px",
        padding: "6px 8px",
    },
    "& .MuiInputBase-input": {
        padding: "8px 4px",
        height: "unset",
        display: "flex",
        alignItems: "center",
    },
}))
