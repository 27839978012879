import { t } from "@lingui/macro"
import { FormControlLabel, Stack, Switch, Typography } from "@mui/material"
import { observer } from "mobx-react"
import { useCallback } from "react"

import { FormPanel } from "src/components/FormPanel"

import { AccessGroup } from "src/components/ModalAccessGroup/AccessGroup"
import { ModalTextField } from "src/components/ModalTextField"
import { persistFiles } from "src/lib/file"
import { OutputSettingsSection } from "src/modals/contact-forms-form-builder/OutputViewComponents/OutputSettingsSection"
import ImageSelectionSection from "src/modals/contact-forms-form-builder/sections/ImageSelection/ImageSelectionSection"
import { FormDetailStore } from "src/modals/contact-forms-form-builder/store"
import { GlobalStore } from "src/store"
import { useStore } from "src/store/lib/useStore"

export const FormDetailSection = observer(() => {
    const store = useStore(FormDetailStore)
    const globalStore = useStore(GlobalStore)
    const iconUrl = store.formFields.get("iconUrl")

    const handleIconDelete = useCallback(() => {
        store.formFields.set("iconUrl", undefined)
    }, [store.formFields])

    const handleIconChange = useCallback(
        async (images: IFile[]) => {
            const icon = images[0]
            if (Object.keys(icon).length > 0) {
                const images = await persistFiles([icon as ILocalFile], "image")

                if (images.length > 0) {
                    store.formFields.set("iconUrl", images[0].url)
                }
            }
        },
        [store],
    )

    const handleUseAiTitleToggle = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            store.formFields.set("useAiTitle", event.target.checked)
        },
        [store.formFields],
    )

    const statusLabel = Boolean(
        store.formFields.get("showTenantDetailsConfirmation"),
    )
        ? t`contact-form-type-detail-modal.form-detail-section.value-enabled`
        : t`contact-form-type-detail-modal.form-detail-section.value-disabled`

    const handleTenantDetailsToggle = useCallback(
        (_: unknown, checked: boolean) => {
            store.formFields.set("showTenantDetailsConfirmation", checked)
        },
        [store.formFields],
    )

    return (
        <Stack spacing={2}>
            <ModalTextField
                variant="default"
                label={t`contact-form-forms-detail-modal.form-detail-section.name-label`}
                value={store.formFields.get("name")}
                onChange={(event) =>
                    store.formFields.set("name", event.target.value)
                }
                helperText={store.formFields.error("name")}
                error={Boolean(store.formFields.error("name"))}
            />
            <ModalTextField
                variant="default"
                label={t`contact-form-forms-detail-modal.form-detail-section.description-label`}
                value={store.formFields.get("description")}
                onChange={(event) =>
                    store.formFields.set("description", event.target.value)
                }
                helperText={
                    Boolean(store.formFields.error("description"))
                        ? store.formFields.error("description")
                        : `${
                              store.formFields.get("description")?.length ?? 0
                          } ${t`contact-form-forms-detail-modal.form-detail-section.description-label.helper`}`
                }
                error={Boolean(store.formFields.error("description"))}
                rows={3}
                maxLength={40}
            />
            {globalStore.session.hasAccessToModule("ai_chatbot") && (
                <ModalTextField
                    variant="withToolTip"
                    value={store.formFields.get("aiInstructions")}
                    onChange={(event) =>
                        store.formFields.set(
                            "aiInstructions",
                            event.target.value,
                        )
                    }
                    label={t`contact-form-forms-detail-modal.form-detail-section.ai-instructions-label`}
                    tooltipText={t`contact-form-forms-detail-modal.form-detail-section.ai-instructions-tooltip`}
                    helperText={store.formFields.error("aiInstructions")}
                    error={Boolean(store.formFields.error("aiInstructions"))}
                />
            )}
            <FormControlLabel
                control={
                    <Switch
                        checked={store.formFields.get("useAiTitle") ?? false}
                        onChange={handleUseAiTitleToggle}
                        color="primary"
                    />
                }
                label={t`contact-form-forms-detail-modal.form-detail-section.use-ai-title-label`}
            />
            <AccessGroup
                accessGroupId={store.formFields.get("accessGroupId") as number}
                errorMessage={store.formFields.error("accessGroupId") ?? ""}
                disabled={store.isEditMode}
            />
            <FormPanel
                sections={[
                    {
                        header: t`product-categories-create-edit.svg-icon-header`,
                        subHeader: t`product-categories-create-edit.create-svg-icon-subheader`,
                        content: (
                            <ImageSelectionSection
                                imageUrl={iconUrl}
                                handleImageDelete={handleIconDelete}
                                handleImageChange={handleIconChange}
                                errorMessage={
                                    store.formFields.error("iconUrl") ?? ""
                                }
                            />
                        ),
                    },
                ]}
            />
            <OutputSettingsSection />
            <Typography variant="subtitle2">
                {t`contact-form-forms-detail-modal.form-detail-section.show-tenant-details-confirmation`}
            </Typography>
            <FormControlLabel
                checked={
                    store.formFields.get("showTenantDetailsConfirmation") ??
                    false
                }
                control={<Switch color="success" />}
                label={statusLabel}
                onChange={handleTenantDetailsToggle}
            />
        </Stack>
    )
})
