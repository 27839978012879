import { ButtonGroup, Grid, Typography } from "@mui/material"
import { observer } from "mobx-react"
import { useMemo } from "react"
import { t } from "@lingui/macro"

import { useStore } from "src/store/lib/useStore"
import { FilterButton } from "src/views/community-overview/components/FilterButton"
import { CommunitiesOverviewViewStore } from "src/views/community-overview/store"
import { AuthorType, IButtonData } from "src/views/community-overview/types"
import { trackModuleEvent } from "src/analytics/helpers/mixpanel_tracking"
import { MixpanelProperties } from "src/analytics/constants/properties"

export const StatisticsHeader = observer(() => {
    const store = useStore(CommunitiesOverviewViewStore)

    const buttons: IButtonData[] = useMemo(
        () => [
            { label: t`community-view.all`, value: AuthorType.All },
            { label: t`community-view.tenant`, value: AuthorType.Tenant },
            { label: t`community-view.admin`, value: AuthorType.Admin },
        ],
        [],
    )

    return (
        <Grid
            container
            paddingX={{ xs: 1, md: 2 }}
            paddingY={1}
            display="flex"
            height={{ xs: "auto", md: 64 }}
            flexDirection={{ xs: "column", md: "row" }}
            gap={{ xs: 2, md: 0 }}
            marginBottom={{ xs: 2, md: 0 }}
        >
            <Grid item xs={12} md={6} display="flex" alignItems="center">
                <Typography variant="h6">{t`community-overview.date-range-header.engagement`}</Typography>
            </Grid>
            <Grid
                item
                display="flex"
                xs={12}
                md={6}
                justifyContent={{ xs: "flex-start", md: "flex-end" }}
                alignItems="center"
            >
                <ButtonGroup
                    variant="outlined"
                    sx={{
                        marginLeft: { xs: 0, md: 2 },
                        width: { xs: "100%", md: "auto" },
                    }}
                >
                    {buttons.map((button) => (
                        <FilterButton
                            key={button.label}
                            label={button.label}
                            value={button.value}
                            isActive={store.authorType === button.value}
                            onClick={async () => {
                                trackModuleEvent(
                                    "Created by filter - community overview",
                                    {
                                        [MixpanelProperties.CreatedBy]:
                                            button.value,
                                        [MixpanelProperties.EventDefinition]:
                                            "The created by filters",
                                    },
                                )

                                await store.setAuthorType(
                                    button.value as AuthorType,
                                )
                            }}
                        />
                    ))}
                </ButtonGroup>
            </Grid>
        </Grid>
    )
})
