import { Stack, styled } from "@mui/material"
import { observer } from "mobx-react"
import { forwardRef } from "react"

import { EditCommentBody } from "./EditCommentBody"

import { Avatar } from "src/components/Avatar"
import { IComment } from "src/types/community-posts-and-comments/communityPostsAndComments"
import { CommunityPostsAndCommentsStore } from "src/modals/community-posts-and-comments/store"
import { useStore } from "src/store/lib/useStore"
import { ViewCommentBody } from "src/modals/community-posts-and-comments/components/Comment/ViewCommentBody"

const AvatarPosition = styled("div")({ marginTop: "4px" })

interface IProps {
    comment: IComment
}

export const PostComment = observer(
    forwardRef((props: IProps, ref) => {
        const store = useStore(CommunityPostsAndCommentsStore)

        const isEditing = store.isEditingComment(props.comment.id)

        return (
            <Stack ref={ref} direction="row" spacing={1}>
                <AvatarPosition>
                    <Avatar size={32} name={props.comment.authorName} />
                </AvatarPosition>
                {isEditing ? (
                    <EditCommentBody comment={props.comment} />
                ) : (
                    <ViewCommentBody comment={props.comment} />
                )}
            </Stack>
        )
    }),
)
