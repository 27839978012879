import { useTheme, Tooltip, Box } from "@mui/material"
import { ReactNode, useMemo } from "react"

import { community_CommunityFlags } from "src/api"
import { convertHexToRgba } from "src/helpers/convertHexToRgba"
import {
    flagToIconMap,
    flagToColorMap,
    getTranslatedFlagOptions,
} from "src/shared/community-posts-and-comments/constants/flag-constants"

// Helper function to determine the color
const getColor = (isLightColor: boolean, color: string) =>
    isLightColor ? convertHexToRgba(color, 0.5) : color

export const FlagRenderer = (
    flags: community_CommunityFlags,
    flagsInComments?: community_CommunityFlags,
): ReactNode => {
    const { palette } = useTheme()
    const colorMap = useMemo(() => flagToColorMap(palette), [palette])

    const flagOptions = useMemo(() => getTranslatedFlagOptions(), [])

    const flagsToRender = useMemo(
        () =>
            Object.entries(flags).map(([key, value]) => {
                const isInFlags = Boolean(value)
                const isInFlagsInComments = Boolean(
                    flagsInComments?.[key as keyof community_CommunityFlags],
                )

                // If flagsInComments is null, we are rendering the flags in the comments
                if (flagsInComments == null) {
                    if (!isInFlags) return null

                    const IconComponent =
                        flagToIconMap[key as keyof typeof flagToIconMap]
                    if (IconComponent === undefined) return null
                    const color = colorMap[key as keyof typeof colorMap]
                    return <IconComponent key={key} htmlColor={color} />
                }

                // If the flag is not in the flags or flagsInComments, don't render it
                if (!isInFlags && !isInFlagsInComments) {
                    return null
                }

                // Determine if we should use the lightened color
                const isLightColor = isInFlagsInComments && !isInFlags

                const IconComponent =
                    flagToIconMap[key as keyof typeof flagToIconMap]

                if (IconComponent === undefined) return null

                const color = colorMap[key as keyof typeof colorMap]
                const finalColor = getColor(isLightColor, color) // Use isLightColor here

                return (
                    <Box sx={{ cursor: "pointer" }}>
                        <Tooltip
                            arrow
                            title={
                                flagOptions[
                                    key as keyof typeof flagToIconMap
                                ] ?? ""
                            }
                        >
                            <IconComponent key={key} htmlColor={finalColor} />
                        </Tooltip>
                    </Box>
                )
            }),
        [flags, flagsInComments, colorMap, flagOptions],
    )

    return flagsToRender
}
