import { t } from "@lingui/macro"
import { Alert, Button, Typography } from "@mui/material"
import { observer } from "mobx-react"
import { useCallback } from "react"

import { useStore } from "src/store/lib/useStore"

import { Form } from "src/components/Form"
import { FormPanel } from "src/components/FormPanel"

import { ModalHeader } from "src/components/ModalHeader"

import { CenteredSpinner } from "src/components/CenteredSpinner"
import { GlobalStore } from "src/store"
import { StoreProvider } from "src/store/lib/StoreProvider"
import { useCloseConfirmationForForm } from "src/store/modals/use-close-confirmation-for-form"
import { EmbeddedIntegrationPublishSettingsSection } from "src/modals/embedded-integration-v2/sections/PublishSettings"
import { EmbeddedIntegrationSettingsSection } from "src/modals/embedded-integration-v2/sections/Settings"
import { EmbeddedIntegrationContentSection } from "src/modals/embedded-integration-v2/sections/ContentSection"
import { IEmbeddedIntegrationProps } from "src/modals/embedded-integration-v2/types/embeddedIntegration"
import { useInitializer } from "src/lib/initializer"
import { IGeneralDynamicFieldsItemProps } from "src/modals/embedded-integration-v2/types/generalDynamicFieldsItem"
import { ISalesDynamicFieldsItemProps } from "src/modals/embedded-integration-v2/types/salesDynamicFieldsItem"
import {
    validateGeneralDynamicFields,
    validatePurchaseCollapsibleLinkDynamicFields,
    validatePurchaseRichLinkDynamicFields,
    validatePurchaseSimpleLinkDynamicFields,
    validatePurchaseStatusInfoPriceFields,
    validateSalesDynamicFields,
} from "src/modals/embedded-integration-v2/helper/dynamicFieldsValidationCheckHelper"
import { EmbeddedIntegrationStore } from "src/modals/embedded-integration-v2/store"
import { ITabId } from "src/modals/embedded-integration-v2/types/tabId"
import { IPurchaseRichLinkDynamicFieldsItemProps } from "src/modals/embedded-integration-v2/types/purchaseRichLinkFieldsItem"
import { IPurchaseSimpleLinkDynamicFieldsItemProps } from "src/modals/embedded-integration-v2/types/purchaseSimplelinkFieldsItem"
import { IPurchaseCollapsibleLinkDynamicFieldsItemProps } from "src/modals/embedded-integration-v2/types/purchaseCollapsibleLinkFieldsItem"
import { ContentSectionStore } from "src/modals/embedded-integration-v2/sections/ContentSection/store"
import { createUpdateRequestBody } from "src/modals/embedded-integration-v2/helper/createUpdateRequestBodyHelper"
import { EmbeddedIntegrationSettingType } from "src/modals/embedded-integration-v2/types/contentDynamicFields"
import { useUniqueId } from "src/lib/unique-id"

const EmbeddedIntegration = observer(
    ({ id, segments, mode }: IEmbeddedIntegrationProps) => {
        const gstore = useStore(GlobalStore)
        const store = useStore(EmbeddedIntegrationStore)
        const contentStore = useStore(ContentSectionStore)

        const formId = useUniqueId()

        useCloseConfirmationForForm(store.fields)

        const initialized = useInitializer(async () => {
            await store.init(gstore.session.accessGroupId, id, segments, mode)
            await contentStore.init(
                store._generalCategoryFields,
                store._saleTabFields,
                store._purchaseTabFields,
            )
        }, [store, gstore.session.accessGroupId, segments])

        const validateCommonUiFields = useCallback(() => {
            if (
                store.fields.data.selectedConnectorId !== undefined &&
                store.fields.data.selectedConnectorId > 0
            ) {
                store.validateRequiredFields([
                    "internalName",
                    "referenceId",
                    "partner",
                    "type",
                ])
            } else {
                store.validateRequiredFields([
                    "internalName",
                    "referenceId",
                    "partner",
                    "type",
                    "entryUrl",
                ])
            }

            if (store.fields.hasErrors() === true) {
                return true
            }
        }, [store])

        const validateGeneralTabFields = useCallback(() => {
            if (contentStore.generalTabFields.length > 0) {
                const list: IGeneralDynamicFieldsItemProps[] =
                    validateGeneralDynamicFields(
                        contentStore.generalTabFields,
                        store.setHasError,
                    )
                contentStore.fields.set(
                    "generalDynamicFieldsList",
                    list as IGeneralDynamicFieldsItemProps[],
                )
            }
            if (store.hasError) {
                return true
            }
        }, [store, contentStore])

        const validateSalesTabFields = useCallback(() => {
            if (store.fields.data.useSalesPage === true) {
                contentStore.validateRequiredFields([
                    "salesPageTitle",
                    "salesPageHeadline",
                    "salesPageDescription",
                    "ctaPrivacyPolicy",
                    "ctaButtonText",
                    "fileUploadUrl",
                ])
                if (contentStore.fields.hasErrors()) {
                    contentStore.setSelectedTabId(ITabId.SALES)
                    return true
                }
                if (contentStore.salesTabFields.length > 0) {
                    const list: ISalesDynamicFieldsItemProps[] =
                        validateSalesDynamicFields(
                            contentStore.salesTabFields,
                            store.setHasError,
                        )

                    contentStore.fields.set(
                        "salesDynamicFieldsList",
                        list as ISalesDynamicFieldsItemProps[],
                    )
                }

                if (store.hasError) {
                    return true
                }
            }
        }, [store, contentStore])

        const validateActivePurchaseTabFields = useCallback(() => {
            if (
                store.fields.data.type ===
                    EmbeddedIntegrationSettingType.Subscription &&
                contentStore.firstToggleChipOptions === "default"
            ) {
                const purchaseObject = contentStore.fields.data["default"]

                if (purchaseObject.statusBadge.value === true) {
                    validatePurchaseStatusInfoPriceFields(
                        [
                            purchaseObject.statusBadgeText,
                            purchaseObject.statusBadgeBackgroundColor,
                        ],
                        store.setHasError,
                    )
                }
                if (purchaseObject.infoBox.value === true) {
                    validatePurchaseStatusInfoPriceFields(
                        [
                            purchaseObject.infoBoxTitle,
                            purchaseObject.infoBoxTextDescription,
                            purchaseObject.infoBoxBackgroundColor,
                        ],
                        store.setHasError,
                    )
                }
                if (purchaseObject.priceBox.value === true) {
                    validatePurchaseStatusInfoPriceFields(
                        [
                            purchaseObject.priceBoxName,
                            purchaseObject.priceBoxCurrency,
                            purchaseObject.priceBoxPeriod,
                        ],
                        store.setHasError,
                    )
                }
                if (
                    purchaseObject?.purchaseRichLinkDynamicFieldsList?.length >
                    0
                ) {
                    const list: IPurchaseRichLinkDynamicFieldsItemProps[] =
                        validatePurchaseRichLinkDynamicFields(
                            purchaseObject.purchaseRichLinkDynamicFieldsList,
                            store.setHasError,
                        )
                    purchaseObject.purchaseRichLinkDynamicFieldsList = list
                    contentStore.fields.set("default", purchaseObject)
                }
                if (
                    purchaseObject?.purchaseSimpleLinkDynamicFieldsList
                        ?.length > 0
                ) {
                    const list: IPurchaseSimpleLinkDynamicFieldsItemProps[] =
                        validatePurchaseSimpleLinkDynamicFields(
                            purchaseObject.purchaseSimpleLinkDynamicFieldsList,
                            store.setHasError,
                        )
                    purchaseObject.purchaseSimpleLinkDynamicFieldsList = list
                    contentStore.fields.set("default", purchaseObject)
                }
                if (
                    purchaseObject?.purchaseCollapsibleLinkDynamicFieldsList
                        ?.length > 0
                ) {
                    const list: IPurchaseCollapsibleLinkDynamicFieldsItemProps[] =
                        validatePurchaseCollapsibleLinkDynamicFields(
                            purchaseObject.purchaseCollapsibleLinkDynamicFieldsList,
                            store.setHasError,
                        )
                    purchaseObject.purchaseCollapsibleLinkDynamicFieldsList =
                        list
                    contentStore.fields.set("default", purchaseObject)
                }
                if (store.hasError) {
                    contentStore.setSelectedTabId(ITabId.PURCHASE)
                    contentStore.fields.set("salesPurchaseType", "default")
                    return true
                }
            }
        }, [store, contentStore])

        const handleSubmit = useCallback(async () => {
            store.setHasError(false)

            if (store.fields.data.useSalesPage === false) {
                contentStore.resetSalesDynamicFieldsList()
            }

            if (
                validateCommonUiFields() ||
                validateGeneralTabFields() ||
                validateSalesTabFields() ||
                validateActivePurchaseTabFields()
            ) {
                return
            }

            const request = createUpdateRequestBody(
                store.fields,
                contentStore.fields,
                contentStore.generalTabFields,
                contentStore.salesTabFields,
                contentStore._toggleChipOptions,
            )

            await store.submit(request)

            if (!store.fields.hasErrors()) {
                gstore.modals.pop()
            }
        }, [
            store,
            validateCommonUiFields,
            validateGeneralTabFields,
            validateSalesTabFields,
            validateActivePurchaseTabFields,
            contentStore,
            gstore.modals,
        ])

        if (!initialized) {
            gstore.loading.is(EmbeddedIntegrationStore.LoadingKeys.init)
            return <CenteredSpinner height="100vh" />
        }

        return (
            <Form formId={formId} onSubmit={handleSubmit}>
                <ModalHeader>
                    <Button
                        type="submit"
                        variant="contained"
                        disabled={false}
                    >{t`embed-integrations-modal.publish-button`}</Button>
                </ModalHeader>
                {Boolean(store.fields.error("generic")) && (
                    <Alert severity="error">
                        {store.fields.error("generic")}
                    </Alert>
                )}
                <FormPanel
                    sections={[
                        {
                            header: t`embed-integrations-modal.embed-id-section.header`,
                            content: (
                                <Typography variant="subtitle2">
                                    {mode !== "Copy" ? id : "-"}
                                </Typography>
                            ),
                        },
                    ]}
                />
                <FormPanel
                    sections={[
                        {
                            header: t`embed-integrations-modal.setting-section.header`,
                            content: <EmbeddedIntegrationSettingsSection />,
                        },
                    ]}
                />
                <FormPanel
                    sections={[
                        {
                            header: t`embed-integrations-modal.publish-settings-section.header`,
                            content: (
                                <EmbeddedIntegrationPublishSettingsSection />
                            ),
                        },
                    ]}
                />

                <FormPanel
                    sections={[
                        {
                            header: t`embed-integrations-modal.publish-content-section.header`,
                            content: <EmbeddedIntegrationContentSection />,
                        },
                    ]}
                />
            </Form>
        )
    },
)

export const EmbeddedIntegrationsModal = (props: IEmbeddedIntegrationProps) => (
    <StoreProvider Store={ContentSectionStore}>
        <StoreProvider Store={EmbeddedIntegrationStore}>
            <EmbeddedIntegration {...props} />
        </StoreProvider>
    </StoreProvider>
)
