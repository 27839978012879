import { useCallback, useEffect } from "react"
import { observer } from "mobx-react"
import { t } from "@lingui/macro"
import { Link } from "@mui/material"

import { PropertyStore } from "./store"

import { IPropertyOwner } from "src/views/client-management/store"
import { StoreProvider } from "src/store/lib/StoreProvider"
import { Table } from "src/components/Table"
import { useStore } from "src/store/lib/useStore"
import { reportSuccess, reportUnhandledApiError } from "src/lib/report"
import { CreateNewPropertyModal } from "src/modals/create-new-property-modal"
import { GlobalStore } from "src/store"
import { LoadInvoicesModal } from "src/modals/load-invoices-modal"

const View = observer(
    ({ propertyOwner, legalEntityId, propertyOwnersList }: IProps) => {
        const store = useStore(PropertyStore)
        const gstore = useStore(GlobalStore)

        useEffect(() => {
            ;(async () => {
                await store.init(propertyOwner?.propertyOwnerId, legalEntityId)
            })()
        }, [store, propertyOwner, legalEntityId])

        const propertyOwnerHasFastApiEnabled: boolean =
            propertyOwner?.integrationType === "fastapi"

        const handlePropertyFastApiClick = useCallback(
            (propertyOwnerId: number, propertyId: number, fullLoad: boolean) =>
                async () => {
                    try {
                        await store.propertyFastApiRequest(
                            propertyOwnerId,
                            propertyId,
                            fullLoad,
                        )
                        reportSuccess("The load was successful")
                    } catch (e) {
                        reportUnhandledApiError(e)
                    }
                },
            [store],
        )
        const handleOpenOnEditClick = useCallback(
            (item: number) => {
                gstore.modals.open(() => (
                    <CreateNewPropertyModal
                        id={item}
                        propertyOwnersList={propertyOwnersList}
                        chosenPropertyOwnerId={propertyOwner?.propertyOwnerId}
                    />
                ))
            },
            [gstore, propertyOwnersList, propertyOwner],
        )

        const handleOffboardProperty = useCallback(
            (propertyId: number) => async () => {
                try {
                    await store.offboardPropertyRequest(propertyId)
                    reportSuccess(t`property-view.offboard-success-message`)
                } catch (e) {
                    reportUnhandledApiError(e)
                }
            },
            [store],
        )

        const handleLoadInvoicesClick = useCallback(
            (item: { propertyOwnerId: number; propertyId: number }) => {
                gstore.modals.open(
                    () => (
                        <LoadInvoicesModal
                            propertyOwnerId={item.propertyOwnerId}
                            propertyId={item.propertyId}
                            store={store}
                        />
                    ),
                    {
                        variant: "slide-up-w600",
                    },
                )
            },
            [gstore, store],
        )

        const tableHeaders: {
            key:
                | "propertyId"
                | "name"
                | "city"
                | "externalId"
                | "integrationId"
                | "integrationActive"
                | "selfOnboardingEnabled"
                | "selfOnboardingCode"
                | "leaflet"
            name: string
            hidden?: boolean
        }[] = [
            {
                key: "propertyId",
                name: t`property-view.property-id-header`,
            },
            {
                key: "name",
                name: t`property-view.name-header`,
            },
            {
                key: "city",
                name: t`property-view.city-header`,
            },
            {
                key: "externalId",
                name: t`property-view.external-id-header`,
            },
            {
                key: "integrationId",
                name: t`property-view.integration-id-header`,
            },
            {
                key: "integrationActive",
                name: t`property-view.integration-active-header`,
            },
            {
                key: "selfOnboardingEnabled",
                name: t`property-view.self-onboarding-enabled-header`,
            },
            {
                key: "selfOnboardingCode",
                name: t`property-view.self-onboarding-code-header`,
            },
            {
                key: "leaflet",
                name: t`property-view.self-leaflet-header`,
            },
        ]

        const rowsRenderers = (item: {
            propertyId: number
            name: string
            city: string
            externalId: string
            integrationId: number | undefined
            integrationActive: boolean
            selfOnboardingEnabled: boolean
            selfOnboardingCode: number | string
            leaflet: string | null
        }) => ({
            propertyId: item.propertyId,
            name: (
                <Link
                    component="button"
                    onClick={() => handleOpenOnEditClick(item.propertyId)}
                    data-testid="edit-modal-button"
                    sx={{ textAlign: "left" }}
                >
                    {item.name}
                </Link>
            ),
            city: item.city,
            externalId: item.externalId,
            integrationId: item.integrationId,
            integrationActive:
                item.integrationActive === true
                    ? t`property-view.integration-active-true`
                    : t`property-view.integration-active-false`,
            selfOnboardingEnabled:
                item.selfOnboardingEnabled === true
                    ? t`property-view.self-onboarding-true`
                    : t`property-view.self-onboarding-false`,
            selfOnboardingCode: item.selfOnboardingCode,
            leaflet:
                item.leaflet != null && item.leaflet !== "" ? (
                    <Link href={item.leaflet.toString()} target="_blank">
                        {t`property-view.leaflet-link`}
                    </Link>
                ) : null,
        })

        const rowActionsRenderer = (item: {
            propertyOwnerId: number
            propertyId: number
        }) => {
            let rowActionOptions = [
                {
                    text: t`row-action-renderer.offboard-property`,
                    onClick: handleOffboardProperty(item.propertyId),
                },
            ]

            if (propertyOwnerHasFastApiEnabled) {
                rowActionOptions = rowActionOptions.concat([
                    {
                        text: t`row-action-renderer-fast-api.48h-load`,
                        onClick: handlePropertyFastApiClick(
                            item.propertyOwnerId,
                            item.propertyId,
                            false,
                        ),
                    },
                    {
                        text: t`row-action-renderer-fast-api.full-load`,
                        onClick: handlePropertyFastApiClick(
                            item.propertyOwnerId,
                            item.propertyId,
                            true,
                        ),
                    },
                    {
                        text: t`row-action-renderer-fast-api.load-invoices`,
                        onClick: () => {
                            handleLoadInvoicesClick(item)
                            return Promise.resolve()
                        },
                    },
                ])
            }

            return rowActionOptions
        }
        return (
            <Table
                paginator={store.property}
                headers={tableHeaders}
                rowRenderer={rowsRenderers}
                rowActionsRenderer={rowActionsRenderer}
            />
        )
    },
)

export const PropertyTableView = ({
    propertyOwnersList,
    propertyOwner,
    legalEntityId,
    onEditClick,
}: IProps) => (
    <StoreProvider Store={PropertyStore}>
        <View
            propertyOwner={propertyOwner}
            legalEntityId={legalEntityId}
            propertyOwnersList={propertyOwnersList}
            onEditClick={onEditClick}
        />
    </StoreProvider>
)

interface IProps {
    propertyOwner?: IPropertyOwner
    legalEntityId?: number
    onEditClick: (item: {}) => void
    propertyOwnersList: IPropertyOwner[]
}
