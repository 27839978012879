import { useState, useCallback } from "react"
import {
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    Stack,
    TextField,
    TextFieldProps,
} from "@mui/material"
import { observer } from "mobx-react"
import { t } from "@lingui/macro"
import { DatePicker } from "@mui/lab"

import {
    getYesterdayDate,
    getYesterdayDateObject,
    formatDateToYYYYMMDD,
} from "./helpers"

import type { ILoadInvoicesModalProps } from "./types"

import { useStore } from "src/store/lib/useStore"
import { GlobalStore } from "src/store"
import { reportSuccess, reportUnhandledApiError } from "src/lib/report"

export const LoadInvoicesModal = observer(
    ({ propertyOwnerId, propertyId, store }: ILoadInvoicesModalProps) => {
        const gstore = useStore(GlobalStore)
        const [selectedDate, setSelectedDate] = useState<string>(
            getYesterdayDate(),
        )

        const handleClose = () => {
            gstore.modals.pop()
        }

        const handleSubmit = async () => {
            if (selectedDate.length > 0) {
                try {
                    await store.loadInvoices(
                        propertyOwnerId,
                        propertyId,
                        selectedDate,
                    )
                    reportSuccess(t`row-action-renderer-fast-api.load-success`)
                    handleClose()
                } catch (e) {
                    reportUnhandledApiError(e)
                }
            }
        }

        const renderDatePickerInput = useCallback(
            (props: TextFieldProps) => (
                <TextField
                    {...props}
                    sx={{ width: "100%", mt: 2 }}
                    inputProps={{
                        ...props.inputProps,
                        readOnly: true,
                    }}
                />
            ),
            [],
        )

        return (
            <Stack padding="8px">
                <DialogTitle>
                    {t`row-action-renderer-fast-api.load-invoices`}
                </DialogTitle>
                <DialogContent>
                    <DatePicker
                        label={t`global.select-date`}
                        value={selectedDate}
                        onChange={(newValue) => {
                            if (newValue != null) {
                                setSelectedDate(formatDateToYYYYMMDD(newValue))
                            }
                        }}
                        maxDate={getYesterdayDateObject()}
                        minDate={getYesterdayDateObject()}
                        renderInput={renderDatePickerInput}
                    />
                </DialogContent>
                <DialogActions>
                    <Button color="info" onClick={handleClose}>
                        {t`global.cancel`}
                    </Button>
                    <Button
                        onClick={handleSubmit}
                        variant="contained"
                        color="info"
                        disabled={selectedDate.length === 0}
                    >
                        {t`global.load`}
                    </Button>
                </DialogActions>
            </Stack>
        )
    },
)
