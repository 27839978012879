import { Box, Stack } from "@mui/material"
import { GridRenderCellParams, useGridApiContext } from "@mui/x-data-grid-pro"
import { KeyboardArrowDown, KeyboardArrowRight } from "@mui/icons-material"
import { useCallback } from "react"

import { isPostItem } from "src/views/community-overview/helpers/communityHelpers"
import { trackModuleEvent } from "src/analytics/helpers/mixpanel_tracking"
import { MixpanelProperties } from "src/analytics/constants/properties"

export const CustomGridTreeDataGroupingCell = ({
    id,
    field,
    rowNode,
    row,
}: GridRenderCellParams) => {
    const apiRef = useGridApiContext()

    const handleClick: React.MouseEventHandler<HTMLDivElement> = useCallback(
        (event) => {
            if (rowNode.type !== "group") {
                return
            }

            if (!Boolean(rowNode.childrenExpanded)) {
                trackModuleEvent("Expand post", {
                    [MixpanelProperties.EventDefinition]:
                        "clicks the arrow on the post to expand for comments",
                })
            }

            apiRef.current.setRowChildrenExpansion(
                id,
                !(rowNode.childrenExpanded ?? false),
            )
            apiRef.current.setCellFocus(id, field)
            event.stopPropagation()
        },
        [apiRef, field, id, rowNode],
    )

    return (
        <Box sx={{ ml: rowNode.depth * 4 }}>
            {isPostItem(row) ? (
                <div onClick={handleClick}>
                    <Stack flexDirection="row">
                        {rowNode.type === "group" && (
                            <>
                                {rowNode.childrenExpanded === false ? (
                                    <KeyboardArrowRight />
                                ) : (
                                    <KeyboardArrowDown />
                                )}
                            </>
                        )}
                        {row[field]}
                    </Stack>
                </div>
            ) : (
                row[field]
            )}
        </Box>
    )
}
