import { observer } from "mobx-react"
import { useTheme, Stack, Chip, Tooltip } from "@mui/material"
import { useCallback, useMemo } from "react"
import { useNavigate } from "react-router"

import { useStore } from "src/store/lib/useStore"
import { CommunitiesOverviewViewStore } from "src/views/community-overview/store"

import {
    FlagsMap,
    ICommunityOverviewFlagsColors,
    IWaringFlagsHeaderProps,
} from "src/views/community-overview/types"
import useWarningFlags from "src/views/community-overview/hooks/useCommunityFlagsLable"

export const WarningFlagChipsRow = observer(
    (props: IWaringFlagsHeaderProps) => {
        const { palette } = useTheme()
        const navigate = useNavigate()
        const warningFlags: FlagsMap = useWarningFlags()
        const store = useStore(CommunitiesOverviewViewStore)

        const borderColor: ICommunityOverviewFlagsColors = useMemo(
            () => ({
                customer_support: palette.info.main,
                reported_by_admin: palette.error.main,
                reported_by_tenant: palette.utility.clementine.main,
            }),
            [palette],
        )

        const onChipClick = useCallback(
            (flag: string) =>
                navigate(
                    `/community/posts-and-comments?${store.generatePostCommentUrl(
                        flag,
                        props.communityId.toString(),
                    )}`,
                ),
            // eslint-disable-next-line react-hooks/exhaustive-deps
            [props.communityId],
        )

        return (
            <Stack direction="row" spacing={2} justifyContent="end">
                {Object.entries(props.flags).map(([key, value], index) => {
                    if (value === 0) return null
                    return (
                        <Tooltip
                            key={index}
                            title={warningFlags[key]?.label ?? ""}
                            placement="top"
                            arrow
                            disableFocusListener
                            disableTouchListener
                        >
                            <Chip
                                key={key}
                                label={value}
                                onClick={() => onChipClick(key)}
                                sx={{
                                    backgroundColor:
                                        warningFlags[key]?.lightColor,
                                    border: `1px solid ${borderColor[key]}`,
                                    color: palette.primary.main,
                                    borderRadius: "4px",
                                    "&:hover": {
                                        backgroundColor:
                                            warningFlags[key]?.lightColor,
                                    },
                                }}
                            />
                        </Tooltip>
                    )
                })}
            </Stack>
        )
    },
)
