import { t } from "@lingui/macro"
import { FormControlLabel, Radio, RadioGroup } from "@mui/material"
import { observer } from "mobx-react"
import { useCallback } from "react"

import { FormFieldSet } from "src/components/FormFieldSet"
import { CommunitySelect } from "src/modals/community-posts-and-comments-edit/formFields/CommunitySelect"
import { CommunityPostsAndCommentsEditStore } from "src/modals/community-posts-and-comments-edit/store"
import { GlobalStore } from "src/store"
import { useStore } from "src/store/lib/useStore"
import { DateTimePicker } from "src/components/DateTimePicker"
import { environment } from "src/config/variables"

export const SettingsSection = observer(() => {
    const gstore = useStore(GlobalStore)
    const store = useStore(CommunityPostsAndCommentsEditStore)

    const handlePinChange = useCallback(
        (_: unknown, value: string) => {
            store.form.set("pin", value === "true")
        },
        [store],
    )

    const handleScheduledAtChange = useCallback((date: Date | null) => {
        store.form.set("scheduledAt", date)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            <FormFieldSet
                header={t`community-post-detail-modal.settings-section.community-header`}
            >
                <CommunitySelect />
            </FormFieldSet>

            {environment !== "production" && (
                <FormFieldSet
                    header={t`community-post-detail-modal.schedule-section.header`}
                    tooltip={t`community-post-detail-modal.schedule-section.tooltip`}
                >
                    <DateTimePicker
                        value={store.form.get("scheduledAt")}
                        onChangePicker={handleScheduledAtChange}
                        minDate={new Date()}
                        error={Boolean(store.form.error("scheduledAt"))}
                        helperText={store.form.error("scheduledAt")}
                        inputId="scheduled-at"
                        disabled={store.form.get("accessType") === "READ"}
                    />
                </FormFieldSet>
            )}

            {gstore.features.enabled("ShowCommunityPostPinnedCheckbox") && (
                <FormFieldSet
                    header={t`community-post-detail-modal.settings-section.pinned-header`}
                >
                    <RadioGroup
                        value={store.form.get("pin")}
                        onChange={handlePinChange}
                        row
                    >
                        <FormControlLabel
                            control={<Radio value={true} />}
                            label={t`community-post-detail-modal.settings-section.pinned-yes`}
                            disabled={store.form.get("accessType") === "READ"}
                        />
                        <FormControlLabel
                            control={<Radio value={false} />}
                            label={t`community-post-detail-modal.settings-section.pinned-no`}
                            disabled={store.form.get("accessType") === "READ"}
                        />
                    </RadioGroup>
                </FormFieldSet>
            )}
        </>
    )
})
