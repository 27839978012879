/* eslint-disable import/no-relative-parent-imports */
import { TextField } from "@mui/material"
import { observer } from "mobx-react"
import { t } from "@lingui/macro"

import { IntegrationConnectorItemStore } from "../store"

import { useStore } from "src/store/lib/useStore"

export const Name = observer(() => {
    const store = useStore(IntegrationConnectorItemStore)

    const handleChangeClick = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        store.formFields.set("name", event.target.value)
    }

    return (
        <TextField
            label={t`integration-connector-modal.name`}
            onChange={handleChangeClick}
            value={store.formFields.get("name") ?? ""}
            error={Boolean(store.formFields.error("name"))}
            helperText={store.formFields.error("name")}
            disabled={store.formFields.get("accessType") === "READ"}
        />
    )
})
