import { useMemo } from "react"

import { GlobalStore } from "src/store"
import { useStore } from "src/store/lib/useStore"
import { IAuthor } from "src/types/community-posts-and-comments/communityPostsAndComments"

export const useGetAuthors = () => {
    const gstore = useStore(GlobalStore)

    const hasAccessToAuthorNameModule = useMemo(
        () => gstore.session.hasAccessToModule("community_author_name"),
        [gstore.session],
    )

    const propertyOwners = useMemo(
        () =>
            gstore.session.propertyOwners.filter((po) => Boolean(po.legalName)),
        [gstore.session.propertyOwners],
    )

    const legalEntities = useMemo(
        () =>
            propertyOwners.flatMap(
                (po) =>
                    po.legalEntities?.filter((le) => Boolean(le.legalName)) ??
                    [],
            ),
        [propertyOwners],
    )

    const authors: IAuthor[] = useMemo(() => {
        const selfAuthor = {
            id: "self",
            name: gstore.session.user?.name ?? "",
        }

        if (!hasAccessToAuthorNameModule) {
            return [selfAuthor]
        }

        return [
            selfAuthor,
            ...propertyOwners.map((po) => ({
                id: `po-${po.id}`,
                name: po.legalName ?? "",
            })),
            ...legalEntities.map((le) => ({
                id: `le-${le.id}`,
                name: le.legalName ?? "",
            })),
        ]
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [gstore.session.user?.name, propertyOwners, legalEntities])

    return { authors, hasAccessToAuthorNameModule }
}
