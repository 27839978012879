import { t } from "@lingui/macro"
import {
    CommentRounded,
    FeedRounded,
    PercentRounded,
    VisibilityRounded,
} from "@mui/icons-material"
import { useTheme } from "@mui/material"
import { useMemo } from "react"

import { Icon } from "src/components/icons"

import { useStore } from "src/store/lib/useStore"
import { CommunitiesOverviewViewStore } from "src/views/community-overview/store"

const iconSize = { fontSize: 35 }

export const useGetStatisticsRowItems = () => {
    const store = useStore(CommunitiesOverviewViewStore)
    const { palette } = useTheme()

    return useMemo(
        () => [
            {
                icon: (
                    <Icon
                        icon={<FeedRounded sx={iconSize} />}
                        size={36}
                        color={palette.utility.darkBlue.main}
                    />
                ),
                name: t`community-overview.statistics-row.number-of-posts`,
                value: store.numberOfPosts,
                tooltip: t`community-overview.statistics-row.number-of-posts-tooltip`,
            },
            {
                icon: (
                    <Icon
                        icon={<CommentRounded sx={iconSize} />}
                        size={36}
                        color={palette.utility.darkBlue.main}
                    />
                ),
                name: t`community-overview.statistics-row.number-of-comments`,
                value: store.numberOfComments,
                tooltip: t`community-overview.statistics-row.number-of-comments-tooltip`,
            },
            {
                icon: (
                    <Icon
                        icon={<VisibilityRounded sx={iconSize} />}
                        size={36}
                        color={palette.utility.darkBlue.main}
                    />
                ),
                name: t`community-overview.statistics-row.read-rate`,
                value: `${store.readRate}%`,
                tooltip: t`community-overview.statistics-row.read-rate-tooltip`,
            },
            {
                icon: (
                    <Icon
                        icon={<PercentRounded sx={iconSize} />}
                        size={36}
                        color={palette.utility.darkBlue.main}
                    />
                ),
                name: t`community-overview.statistics-row.active-users`,
                value: `${store.activeUsers}%`,
                tooltip: t`community-overview.statistics-row.active-users-tooltip`,
            },
        ],

        [
            palette.utility.darkBlue.main,
            store.activeUsers,
            store.numberOfComments,
            store.numberOfPosts,
            store.readRate,
        ],
    )
}
