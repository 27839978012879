import moment from "moment"

import { IPostInitialStateFilterItem } from "src/views/community-posts-and-comments/types"

export const getOperatorForField = (
    field: string,
    type: string | undefined,
    value: unknown,
): string => {
    // Special case for community_id since it depends on value type
    if (field === "community_id") {
        return Array.isArray(value) ? "isAnyOf" : "="
    }

    switch (true) {
        case field === "post_id":
            return "isAnyOf"
        case type === "number":
            return "="
        case type === "boolean":
            return "is"
        default:
            return "contains"
    }
}

export const parseFieldValue = (
    field: string,
    rawValue: string,
): string | number | boolean | string[] => {
    if (field === "post_id") {
        return [JSON.parse(rawValue)]
    }

    if (field === "community_id") {
        return rawValue.includes(",")
            ? rawValue.split(",").map((id) => id.trim())
            : [rawValue]
    }

    if (field === "is_handled") {
        const normalizedValue = rawValue.toLowerCase()
        if (!["true", "false"].includes(normalizedValue)) {
            throw new Error("Invalid boolean value")
        }
        return normalizedValue === "true"
    }

    return rawValue
}

export const handleDateFilter = (
    value: string,
    field: string,
): IPostInitialStateFilterItem[] => {
    const date = moment(value, "YYYY-MM-DD")
    if (!date.isValid()) {
        return []
    }

    const firstDay = date.clone().startOf("month").subtract(1, "day")
    const lastDay = date.clone().endOf("month").add(1, "day")

    return [
        {
            field,
            operator: "after",
            value: firstDay.format("YYYY-MM-DD"),
        },
        {
            field,
            operator: "before",
            value: lastDay.format("YYYY-MM-DD"),
        },
    ]
}
