export enum MixpanelProperties {
    AccessGroupID = "Access Group ID",
    AccessGroupName = "Access Group Name",
    UserID = "User ID",
    SessionDuration = "Session Time",
    PageName = "Page Name",
    SegmentName = "Segment Name",
    SegmentID = "Segment ID",
    TenantID = "Tenant ID",
    InvoiceStatus = "Status Name",
    SelfRegistrationTabName = "Request",
    Filters = "Filters",
    PublishStartDate = "Publish Start Date",
    PublishEndDate = "Publish End Date",
    PushNotification = "Push Notification",
    ItemID = "Item ID",
    ItemName = "Item name",
    ShowInApp = "Show in app",
    UsedByChatbot = "Used by chatbot",
    CommunityName = "Community name",
    ResourceType = "Resource Type",
    ProductTypeName = "Product Type Name",
    MonthSelected = "Month selected",
    EventDefinition = "Event Definition",
    BlockedBy = "Blocked By",
    BlockedUser = "Blocked User",
    BlockedFrom = "Blocked From",
    PostID = "Post ID",
    PostText = "Post Text",
    DeletedFrom = "Deleted from",
    DeletedBy = "Deleted By",
    CommentID = "Comment ID",
    CommentText = "Comment Text",
    PreviewedBy = "Previewed By",
    PostAuthor = "Post Author",
    BrowsedBy = "Browsed By",
    Action = "Action",
    HandledBy = "Handled By",
    HandledFrom = "Handled from",
    ReportedFrom = "Reported from",
    ReportedBy = "Reported By",
    UnhandledBy = "Unhandled By",
    UnhandledFrom = "Unhandled from",
    CreatedBy = "Created by",
    CommunityNameFilter = "Community name",
    CommunityCount = "Community count",
    HandledFilter = "Handled filter",
    EntryPoint = "Entry point",
    SelectedFlag = "Selected flag",
    CommunityFilterCount = "Community filter - count",
    CommunityFilterNames = "Community filter - names",
    MonthFilter = "Month filter",
    Flags = "Flags",
    Scheduled = "Scheduled",
    ScheduledFor = "Scheduled for",
    CreatedDate = "Created date",
}
