import React from "react"
import { makeAutoObservable } from "mobx"
import { t } from "@lingui/macro"

import { FormFields } from "src/lib/form-fields"
import {
    InfoCenterAdminService,
    infocenter_InformationItem,
    infocenter_InformationItemTypeEnum,
} from "src/api"
import { Channel } from "src/channel"
import { reportError } from "src/lib/report"
import { persistFiles } from "src/lib/file"
import { ILibraryInformationItem } from "src/types/library"
import { ISelectedAccessGroup } from "src/types/local-storage"
import { DEFAULT_ACCESS_GROUP } from "src/config"
import { ItemTypes } from "src/types/library-information-items/item-types"
import { ILibraryCategoryFlat } from "src/modals/library-information-items/types/libraryCategoryFlat"
import { ILibraryInformationItemFormFields } from "src/modals/library-information-items/types/formFields"
import { shouldPublishSegments } from "src/helpers/shouldPublishSegments"

export class LibraryInformationItemStore {
    static Context = React.createContext<LibraryInformationItemStore | null>(
        null,
    )
    formFields = new FormFields<ILibraryInformationItemFormFields>({})
    categories: ILibraryCategoryFlat[] = []
    itemTypes: ItemTypes[] = []
    isLoading = false
    accessType: string = ""
    accessGroups: ISelectedAccessGroup[] = []
    accessGroupId: number = DEFAULT_ACCESS_GROUP.id
    isEditMode = false

    constructor() {
        makeAutoObservable(this)
    }

    init = (
        isSuperUser: boolean,
        informationItem?: ILibraryInformationItem,
        isCopyMode: boolean = false,
    ) => {
        const isEditMode = !isCopyMode && informationItem?.id !== undefined

        this.formFields.init({
            category: informationItem?.category_ids?.[0],
            document:
                informationItem?.item_type === "document"
                    ? informationItem.content
                    : undefined,
            internalName: informationItem?.admin_name ?? "",
            url:
                informationItem?.item_type === "url"
                    ? informationItem.content
                    : undefined,
            nameInApp: informationItem?.display_name ?? "",
            publishedIn: isCopyMode ? [] : informationItem?.segment_ids ?? [],
            page:
                informationItem?.item_type === "page"
                    ? informationItem.content
                    : undefined,
            type: informationItem?.item_type ?? "",
            accessType: isCopyMode ? "" : informationItem?.access_type ?? "",
            accessGroupId:
                informationItem?.access_group_id ?? this.accessGroupId,
            showInApp: isEditMode ? informationItem?.show_in_app : !isSuperUser,
            includeInChatbot:
                isEditMode || isCopyMode
                    ? informationItem?.include_in_chatbot
                    : isSuperUser,
            tag_ids: informationItem?.tag_ids ?? [],
        })
        this.isEditMode = isEditMode
    }

    setCategories = (categories: ILibraryCategoryFlat[]) => {
        this.categories = categories
    }

    setAccessGroups = (accessGroups: ISelectedAccessGroup[]) => {
        this.accessGroups = accessGroups
    }

    setSelectedAccessGroupId = (accessGroupId: number) => {
        this.accessGroupId = accessGroupId
    }

    setItemTypes = (itemTypes: ItemTypes[]) => {
        this.itemTypes = itemTypes
    }
    createItem = async () => {
        const publishedIn = this.formFields.get("publishedIn")
        let itemResponse: infocenter_InformationItem = {}

        try {
            // Create
            await this.formFields.catchErrors(async () => {
                itemResponse =
                    await InfoCenterAdminService.infocenterCreateItem({
                        request: await this.getRequestData(),
                    })
            })

            if (itemResponse.information_item_id != null) {
                // tags update
                await this.handleTagsSubmit(
                    itemResponse.information_item_id as number,
                )

                // publish
                if (shouldPublishSegments(publishedIn)) {
                    await InfoCenterAdminService.infocenterPublish({
                        informationItemId:
                            itemResponse.information_item_id as number,
                        request: {
                            published_in: publishedIn,
                        },
                    })
                }
            }

            Channel.send({
                name: "repository/updated",
                payload: {
                    repository: "library-information-items",
                    action: "update",
                },
            })
        } catch (error) {
            reportError(t`edit-information-item-modal.save-item-fail`, error)
        } finally {
            this.setIsLoading(false)
        }
    }

    updateItem = async (item: ILibraryInformationItem) => {
        const publishedIn = this.formFields.get("publishedIn")

        try {
            // Update
            await this.formFields.catchErrors(async () => {
                await InfoCenterAdminService.infocenterUpdateItem({
                    informationItemId: item.information_item_id as number,
                    request: await this.getRequestData(),
                })
            })

            // set tags
            await this.handleTagsSubmit(item.information_item_id as number)

            // Publish
            await InfoCenterAdminService.infocenterPublish({
                informationItemId: item.information_item_id as number,
                request: {
                    published_in: publishedIn,
                },
            })

            Channel.send({
                name: "repository/updated",
                payload: {
                    repository: "library-information-items",
                    action: "update",
                },
            })
        } catch (error) {
            reportError(t`edit-information-item-modal.save-item-fail`, error)
        } finally {
            this.setIsLoading(false)
        }
    }

    updateTags = async (tag_ids: number[], informationItemId: number) => {
        try {
            await InfoCenterAdminService.infocenterTag({
                informationItemId,
                request: {
                    tag_ids,
                },
            })
            Channel.send({
                name: "repository/updated",
                payload: {
                    repository: "library-information-items",
                    action: "update",
                },
            })
        } catch (error) {
            reportError(t`edit-information-item-modal.save-item-fail`, error)
        } finally {
            this.setIsLoading(false)
        }
    }

    handleTagsSubmit = async (information_item_id: number) => {
        const tags = this.formFields.get("tag_ids")
        const shouldUpdateTags = this.formFields.getIsFieldDirty("tag_ids")

        if (tags !== undefined && shouldUpdateTags) {
            await this.updateTags(tags, information_item_id)
        }
    }

    urlString = (url: string) => {
        let newUrl = ""
        if (url.startsWith("http://")) {
            newUrl = `https://${url.split("http//")[1]}`
        } else if (url.startsWith("https://")) {
            newUrl = url
        } else {
            newUrl = `https://${url}`
        }
        return newUrl
    }

    getContent = async (formData: ILibraryInformationItemFormFields) => {
        let content = ""

        if (formData.type === "document") {
            if (typeof formData.document === "string") {
                content = formData.document
            } else {
                try {
                    content =
                        (
                            await persistFiles(
                                [formData.document as IFile],
                                "document",
                            )
                        )[0].url ?? ""
                } catch (error) {
                    reportError(
                        t`edit-information-item-modal.upload-document-fail`,
                        error,
                    )
                }
            }
        } else if (formData.type === "url") {
            content = this.urlString(formData.url ?? "") ?? ""
        } else if (formData.type === "page") {
            content = formData.page ?? ""
        }

        return content
    }

    getRequestData = async () => ({
        access_group_id:
            this.formFields.data.accessGroupId ?? DEFAULT_ACCESS_GROUP.id,
        admin_name: this.formFields.data.internalName ?? "",
        category_ids:
            this.formFields.data.category != null
                ? [this.formFields.data.category]
                : [],
        content: await this.getContent(this.formFields.data),
        display_name: this.formFields.data.nameInApp ?? "",
        item_type: (this.formFields.data.type ??
            "") as infocenter_InformationItemTypeEnum,
        accessType: this.formFields.data.accessType,
        show_in_app: this.formFields.data.showInApp,
        include_in_chatbot: this.formFields.data.includeInChatbot,
    })

    validateRequiredFields = (requiredFields: string[]) => {
        this.formFields.clearErrors()

        requiredFields.forEach((field) => {
            const requiredField =
                field as keyof ILibraryInformationItemFormFields
            const formField = this.formFields.get(requiredField)

            if (
                formField == null ||
                formField === "" ||
                formField === "<p></p>\n"
            ) {
                this.formFields.setError(requiredField, t`errors.required`)
            } else {
                this.formFields.clearError(requiredField)
            }
        })
    }

    setIsLoading = (isLoading: boolean) => (this.isLoading = isLoading)
    setAccessTypes = (accessType: string) => {
        this.accessType = accessType
    }

    getAccessGroupIdForPublishSection() {
        if (!this.isEditMode) {
            return this.accessGroupId != null
                ? this.accessGroupId
                : this.formFields.get("accessGroupId")
        } else {
            return this.formFields.get("accessGroupId")
        }
    }
}
