import { makeAutoObservable } from "mobx"
import React from "react"

import { ContactFormV2AdminService } from "src/api"
import { Channel } from "src/channel"
import { loads } from "src/channel/utils"
import { parseDate } from "src/lib/date"
import { FormFields } from "src/lib/form-fields"
import { createLoadingKeys } from "src/lib/loading"

import {
    IFormFields,
    IHandler,
} from "src/modals/cases-v2-detail/types/fieldsProps"
import { parseStatusCasesV2 } from "src/helpers/parseStatusCasesV2"
import { StatusCasesV2 } from "src/types/status-casesV2"

export enum ITabId {
    DETAIL = "detail",
    CHAT = "chat",
}

export class CaseDetailStore {
    static Context = React.createContext<CaseDetailStore | null>(null)
    static LoadingKeys = createLoadingKeys("submit", "init")

    //#region init variable
    conversationId?: string = ""
    private _selectedTabId: string = ITabId.DETAIL
    private _messagingEnabled: boolean = false
    handlers: IHandler[] = []
    private id: number | null = null

    form = new FormFields<IFormFields>({
        assignedTo: null,
        assignedToName: "",
        conversation_id: "",
        createdAt: null,
        externalFormId: "",
        fieldsWithAnswers: [],
        formId: 0,
        formTypeId: 0,
        formTypeName: "",
        implementationDate: null,
        objectAddress: "",
        objectId: "",
        status: StatusCasesV2.Unknown,
        title: "",
        updatedAt: null,
        tenantEmail: "",
        tenantPhoneNumber: "",
        tenantFullName: "",
        tenantId: 0,
        contactEmail: "",
        contactPhoneNumber: "",
    })
    //#endregion

    constructor() {
        makeAutoObservable(this)
    }

    //#region getters
    get selectedTabId() {
        return this._selectedTabId
    }

    get messagingEnabled() {
        return this._messagingEnabled
    }
    //#endregion

    //#region setters
    setSelectedTabId(id: string) {
        this._selectedTabId = id
    }

    setMessagingEnabled(value: boolean) {
        this._messagingEnabled = value
    }

    setConversationId(id: string) {
        this.conversationId = id
    }

    private setHandlers(handlers: IHandler[]) {
        this.handlers = handlers
    }

    private setId(id: number) {
        this.id = id
    }
    //#endregion

    //#region store operations
    @loads(() => CaseDetailStore.LoadingKeys.init)
    async init(id: number) {
        this.setId(id)

        const [handlers, casesForm] = await Promise.all([
            await ContactFormV2AdminService.getV2AdminContactFormAdmins1({
                formId: id,
            }),
            await ContactFormV2AdminService.getV2AdminContactForm1({
                formId: id,
            }),
        ])

        if (handlers !== null) {
            this.setHandlers(
                handlers.map((handler) => ({
                    id: handler.admin_id ?? -1,
                    name: handler.name ?? "",
                })),
            )
        }

        this.setMessagingEnabled(casesForm.messaging_enabled ?? false)

        this.form.init({
            assignedTo: casesForm.assigned_to ?? null,
            assignedToName: casesForm.assigned_to_name ?? "",
            conversation_id: casesForm.conversation_id ?? "",
            createdAt: parseDate(casesForm.created_at),
            externalFormId: casesForm.external_form_id ?? "",
            fieldsWithAnswers: casesForm.fields_with_answers ?? [],
            formId: casesForm.form_id ?? 0,
            formTypeId: casesForm.form_type_id ?? 0,
            formTypeName: casesForm.form_type_name ?? "",
            implementationDate: parseDate(casesForm.implementation_date),
            objectAddress: casesForm.object_address ?? "",
            objectId: casesForm.object_id ?? "",
            status: parseStatusCasesV2(casesForm.status ?? ""),
            title: casesForm.title ?? "",
            updatedAt: parseDate(casesForm.updated_at),
            tenantEmail: casesForm.tenant_email ?? "",
            tenantId: casesForm.tenant_id ?? 0,
            tenantPhoneNumber: casesForm.tenant_phone_number ?? "",
            tenantFullName: casesForm.tenant_name ?? "",
            contactEmail: casesForm.contact_email ?? "",
            contactPhoneNumber: casesForm.contact_phone ?? "",
        })

        this.setConversationId(casesForm.conversation_id ?? "")
    }

    @loads(() => CaseDetailStore.LoadingKeys.submit)
    async submit() {
        const { assignedTo, implementationDate, status, title } = this.form.data
        const { id } = this

        if (id == null) {
            return
        }

        await this.form.catchErrors(async () => {
            await ContactFormV2AdminService.putV2AdminContactForm({
                formId: id,
                request: {
                    assigned_to: assignedTo ?? undefined,
                    implementation_date:
                        implementationDate?.toISOString() ?? undefined,
                    status,
                },
            })

            Channel.send({
                name: "repository/updated",
                payload: {
                    repository: "cases-v2",
                    action: "update",
                    item: { id, name: title },
                },
            })
        })
    }
    //#endregion
}
