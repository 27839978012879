import { t } from "@lingui/macro"
import {
    ChatBubbleOutline,
    Favorite,
    FavoriteBorder,
} from "@mui/icons-material"
import { IconButton, Stack, styled } from "@mui/material"
import { observer } from "mobx-react"
import { useCallback } from "react"

import { CategoryChip } from "src/components/CategoryChip"
import { CommunityLikesModal } from "src/modals/community-posts-and-comments-likes"
import { CommunityPostsAndCommentsStore } from "src/modals/community-posts-and-comments/store"
import { GlobalStore } from "src/store"
import { useStore } from "src/store/lib/useStore"

const Wrap = styled(Stack)({ marginTop: "16px" })

const LikeButton = styled(IconButton)({
    padding: "4px",
})

const OpenLikesModalButton = styled("button")(({ theme }) => ({
    margin: 0,
    padding: "4px",
    border: 0,
    cursor: "pointer",
    background: "transparent",
    color: theme.palette.grey[900],
    fontSize: "16px",
    "&:active": { border: 0 },
    "&:hover": {
        background: theme.palette.grey[300],
        borderRadius: "4px",
    },
}))

export const PostFooter = observer(() => {
    const store = useStore(CommunityPostsAndCommentsStore)
    const gstore = useStore(GlobalStore)

    const handleOpenLikesModalClick = useCallback(() => {
        gstore.modals.open(
            () => <CommunityLikesModal postId={store.post.id} />,
            {
                variant: "slide-up-w600",
            },
        )
    }, [gstore.modals, store.post])

    const isLiked = store.isPostLikedByUser(gstore.session.user?.adminId)

    return (
        <Wrap direction="row" justifyContent="space-between">
            <Stack direction="row" spacing={4}>
                <Stack direction="row" alignItems="center">
                    <LikeButton
                        title={
                            isLiked
                                ? t`view-community-post-modal.unlike-post-title`
                                : t`view-community-post-modal.like-post-title`
                        }
                        onClick={() =>
                            store.post.accessType === "WRITE"
                                ? store.togglePostLike(
                                      gstore.session.user?.adminId,
                                  )
                                : null
                        }
                    >
                        {store.post.likes.length > 0 ? (
                            <Favorite color={isLiked ? "error" : "inherit"} />
                        ) : (
                            <FavoriteBorder />
                        )}
                    </LikeButton>
                    <OpenLikesModalButton
                        title={t({
                            id: `view-community-post-modal.open-likes-modal-title`,
                            values: { count: store.post.likes.length },
                        })}
                        onClick={handleOpenLikesModalClick}
                        disabled={store.post.likes.length === 0}
                        data-testid={`Footer/OpenLikesModal/${store.post.id}`}
                    >
                        {store.post.likes.length}
                    </OpenLikesModalButton>
                </Stack>
                <Stack direction="row" spacing={1} alignItems="center">
                    <ChatBubbleOutline />
                    <span>
                        {t({
                            id: "view-community-post-modal.footer-comment-count",
                            values: { count: store.post.commentCount },
                        })}
                    </span>
                </Stack>
            </Stack>
            {store.post.categoryName.length > 0 && (
                <CategoryChip label={store.post.categoryName} />
            )}
        </Wrap>
    )
})
