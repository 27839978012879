import { t } from "@lingui/macro"
import { observer } from "mobx-react"

import { useCallback, useEffect, useMemo } from "react"

import { EmbedIntegrationStore } from "./store"

import useColumns from "./hooks/useColumns"

import { DataGridProTable } from "src/components/Table/DataGridPro"
import { FilterModel, SortModel } from "src/types/data-grid-pro"

import {
    getFilterModelForRepository,
    getSortModelForRepository,
} from "src/lib/data-grid-pro"

import { DEFAULT_ACCESS_GROUP } from "src/config"

import { ListPage } from "src/components/ListPage"
import { EmbeddedIntegrationsModal } from "src/modals/embedded-integration-v2"
import { GlobalStore } from "src/store"
import { StoreProvider } from "src/store/lib/StoreProvider"
import { useStore } from "src/store/lib/useStore"
import { IPageFilterProps } from "src/components/PageFilter"
import { SegmentPickerButton } from "src/components/SegmentPickerButton"
import { Repository } from "src/types/channel"

const repository: Repository = "embed-integrations"

const View = observer(() => {
    const store = useStore(EmbedIntegrationStore)
    const globalStore = useStore(GlobalStore)
    const columns = useColumns()

    const advanceQuery = useMemo(() => {
        const filter = getFilterModelForRepository(
            repository,
            globalStore.session.dataGridFilterModel,
        )
        const sort = getSortModelForRepository(
            repository,
            globalStore.session.dataGridSortModel,
        )
        return { sort, filter }
    }, [
        globalStore.session.dataGridSortModel,
        globalStore.session.dataGridFilterModel,
    ])

    useEffect(() => {
        ;(async () => {
            if (globalStore.session.accessGroup.id !== undefined) {
                await store.init(
                    globalStore.session.accessGroup.id,
                    advanceQuery,
                )
            } else {
                await store.init(DEFAULT_ACCESS_GROUP.id, advanceQuery)
            }
        })()

        return () => {
            store.dispose()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [globalStore.session.accessGroup, globalStore.session.segmentIds])

    const handleOpenIntegrationClick = useCallback((item) => {
        globalStore.modals.open(() => (
            <EmbeddedIntegrationsModal
                id={item.row.id}
                segments={item.row.segments}
            />
        ))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const header = useMemo(
        () => ({
            header: t`embed-integrations-view.header`,
            breadcrumbs: [t`embed-integrations-view.header`],
            createOptions: {
                onClick: () =>
                    globalStore.modals.open(() => (
                        <EmbeddedIntegrationsModal id={undefined} />
                    )),
                item: t`embed-integrations-view.header`,
            },
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    )

    const handleSegmentChange = useCallback(
        (segments: number[]) => store.loadSegments(segments),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    )

    const filter = useMemo(
        (): IPageFilterProps => ({
            actions: (
                <>
                    <SegmentPickerButton
                        value={store.segments}
                        onChange={handleSegmentChange}
                    />
                </>
            ),
        }),
        [handleSegmentChange, store.segments],
    )

    const handleFilterChange = useCallback(
        async (model: FilterModel) => {
            globalStore.session.setDataGridFilterModel({
                [repository]: model,
            })
            const sort = getSortModelForRepository(
                repository,
                globalStore.session.dataGridSortModel,
            )
            await store.query({ sort, filter: model })
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [globalStore.session.dataGridSortModel],
    )

    const handleSortChange = useCallback(
        async (model: SortModel) => {
            globalStore.session.setDataGridSortModel({
                [repository]: model,
            })
            const filter = getFilterModelForRepository(
                repository,
                globalStore.session.dataGridFilterModel,
            )
            await store.query({ filter, sort: model })
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [globalStore.session.dataGridFilterModel],
    )

    const handleCopyItem = useCallback(
        (item) => {
            if (item != null) {
                globalStore.modals.open(() => (
                    <EmbeddedIntegrationsModal
                        id={item.id}
                        segments={item.segments}
                        mode={"Copy"}
                    />
                ))
            }
        },
        [globalStore.modals],
    )

    const advancedOperations = useMemo(
        () => ({
            pagination: "server" as const,
            filtering: "server" as const,
            sorting: "server" as const,
        }),
        [],
    )

    const rowActions = useCallback(
        (item) => [
            {
                text: t`embed-integrations-view.copy-item`,
                onClick: () => handleCopyItem(item),
            },
        ],
        [handleCopyItem],
    )

    const tableIsLoading = useMemo(
        () => !store.embedIntegrations.meta.initialized,
        [store.embedIntegrations.meta.initialized],
    )

    return (
        <ListPage header={header} filter={filter} loading={tableIsLoading}>
            <DataGridProTable
                paginator={store.embedIntegrations}
                data={store.embedIntegrations.items}
                columns={columns}
                advancedOperations={advancedOperations}
                onRowClickEvent={handleOpenIntegrationClick}
                rowActionsRenderer={rowActions}
                repository={repository}
                hideToolbar={false}
                loading={tableIsLoading}
                onFilterChange={handleFilterChange}
                onSortChange={handleSortChange}
            />
        </ListPage>
    )
})

export const EmbeddedIntegrationsView2 = () => (
    <StoreProvider Store={EmbedIntegrationStore}>
        <View />
    </StoreProvider>
)
