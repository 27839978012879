import { useMemo } from "react"
import { t } from "@lingui/macro"

import { IColumn } from "src/types/data-grid-pro"
import {
    EmbedIntegrationItemTypes,
    IEmbedIntegrationFormItemProps,
} from "src/views/embedded-integrations-v2/typed"
import { RowCellContainer } from "src/components/RowCellContainer"
import { AccessTypeHeader } from "src/components/AccessTypeHeader"

export default function useColumns() {
    const itemTypeValueOptions = useMemo(() => {
        return [
            {
                label: t`embed-integrations.types.simple`,
                value: EmbedIntegrationItemTypes.Simple,
            },
            {
                label: t`embed-integrations.types.subscription`,
                value: EmbedIntegrationItemTypes.Subscription,
            },
        ]
    }, [])

    return useMemo(
        () =>
            [
                {
                    field: "id",
                    headerName: t`embed-integrations.id`,
                    width: 100,
                    renderCell: (params: { value: string }) => (
                        <RowCellContainer>{params.value}</RowCellContainer>
                    ),
                    type: "string",
                    filterable: true,
                },
                {
                    field: "name",
                    headerName: t`embed-integrations.internal-name`,
                    width: 200,
                    renderCell: (params: { value: string }) => (
                        <RowCellContainer>{params.value}</RowCellContainer>
                    ),
                    type: "string",
                    filterable: true,
                },
                {
                    field: "type",
                    headerName: t`embed-integrations.type`,
                    width: 200,
                    renderCell: (params: { value: string }) => (
                        <RowCellContainer>{params.value}</RowCellContainer>
                    ),
                    type: "singleSelect",
                    filterable: true,
                    valueOptions: itemTypeValueOptions,
                },
                {
                    field: "partner_name",
                    headerName: t`embed-integrations.partner`,
                    width: 150,
                    renderCell: (params: { value: string }) => (
                        <RowCellContainer>{params.value}</RowCellContainer>
                    ),
                    type: "string",
                    filterable: true,
                },
                {
                    field: "categories",
                    headerName: t`embed-integrations.category`,
                    width: 150,
                    renderCell: (params: { value: string[] }) => (
                        <RowCellContainer>
                            {params.value.join(", ")}
                        </RowCellContainer>
                    ),
                    type: "string",
                    filterable: false,
                },
                {
                    field: "segments",
                    headerName: t`embed-integrations.segments`,
                    width: 150,
                    renderCell: (params: { value: number[] | undefined }) => (
                        <RowCellContainer>
                            {`${String(
                                params.value?.length,
                            )} ${t`contact-form-forms-view.published.value`}`}
                        </RowCellContainer>
                    ),
                    type: "string",
                    filterable: false,
                    sortable: false,
                },
                {
                    field: "access_type",
                    headerName: t`embed-integrations.access-type`,
                    width: 100,
                    filterable: false,
                    sortable: false,
                    renderCell: (params) => (
                        <AccessTypeHeader accessType={params.value} />
                    ),
                },
            ] as IColumn<IEmbedIntegrationFormItemProps>[],
        [itemTypeValueOptions],
    )
}
