import { SvgIconComponent } from "@mui/icons-material"

import { batch_CommunityFlagsCount } from "src/api"

export type WarningFlag = {
    icon: React.ReactElement<SvgIconComponent>
    color: string
    lightColor: string
    value: number
    label: string
}

export type WarningFlags = {
    security_risk: WarningFlag
    community_guidelines_violation: WarningFlag
    reported_by_admin: WarningFlag
    reported_by_tenant: WarningFlag
    maintenance: WarningFlag
    customer_support: WarningFlag
}

export type FlagsMap = { [key: string]: WarningFlag }

export interface ICommunityOverviewCommunityItem {
    id: number
    name: string
}

export interface ICommunityFlagsOverview {
    id: number
    name: string
    flags: batch_CommunityFlagsCount
}

export interface IWaringFlagsHeaderProps {
    flags: batch_CommunityFlagsCount
    communityId: number
}

export interface ICommunityOverviewFlagsColors {
    [key: string]: string
}

export interface IButtonData {
    label: string
    value: boolean | AuthorType | null
}

export enum AuthorType {
    All = "All",
    Tenant = "tenant",
    Admin = "admin_user",
}
