import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank"
import CheckBoxIcon from "@mui/icons-material/CheckBox"
import { useCallback, useEffect } from "react"
import { observer } from "mobx-react"
import { Autocomplete, Checkbox } from "@mui/material"
import CancelIcon from "@mui/icons-material/Cancel"

import { GlobalStore } from "src/store"
import { useStore } from "src/store/lib/useStore"
import { SegmentPickerStore } from "src/components/SegmentPicker/store"
import {
    StyledChip,
    FilterContainer,
    StyledTextField,
} from "src/components/SegmentPicker/components/SegmentsPropertyFilter/styled"
import {
    ISegmentsPublishGroupOption,
    ISegmentsFilterProps,
} from "src/components/SegmentPicker/components/SegmentsPropertyFilter/types"

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
const checkedIcon = <CheckBoxIcon fontSize="small" />

export const SegmentsPropertyFilter = observer(
    ({
        label = "Filter",
        options,
        selectedOptions,
        onChange,
        helperText,
        chipColor,
        disabled = false,
        fullWidth = true,
        sx,
        getOptionLabel = (option) => option.title,
        isOptionEqualToValue = (option, value) => option.id === value.id,
        pathsToMatch = [],
    }: ISegmentsFilterProps) => {
        const globalStore = useStore(GlobalStore)
        const store = useStore(SegmentPickerStore)

        useEffect(() => {
            const checkUrl = () => {
                // If no paths specified, show for all URLs
                // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
                if (!pathsToMatch || pathsToMatch.length === 0) {
                    store.setPublishGroupVisible(true)
                    return
                }

                const currentUrl = window.location.href
                const currentPath = window.location.pathname

                const shouldShow = pathsToMatch.some(
                    (path) =>
                        currentUrl.includes(path) || currentPath.includes(path),
                )

                const hasRightSlideIn = globalStore.modals.active.some(
                    (modal) => modal.options?.variant === "right-slide-in",
                )

                store.setPublishGroupVisible(shouldShow && hasRightSlideIn)
            }

            checkUrl()
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [pathsToMatch])

        const renderFilterOption = useCallback(
            (props, option: ISegmentsPublishGroupOption) => {
                const isSelected = selectedOptions.some((selectedOption) =>
                    isOptionEqualToValue(selectedOption, option),
                )
                return (
                    <li key={option.id} {...props}>
                        <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 2 }}
                            checked={isSelected}
                        />
                        {getOptionLabel(option)}
                    </li>
                )
            },
            [selectedOptions, isOptionEqualToValue, getOptionLabel],
        )

        const renderInput = useCallback(
            (params) => (
                <StyledTextField
                    {...params}
                    fullWidth
                    label={label}
                    variant="outlined"
                    helperText={helperText}
                />
            ),
            [helperText, label],
        )

        if (!store.publishGroupVisible) {
            return null
        }

        return (
            <FilterContainer sx={sx}>
                <Autocomplete
                    multiple
                    style={{ padding: 0 }}
                    options={options}
                    value={selectedOptions}
                    onChange={(_, newValue) => onChange(newValue)}
                    disableCloseOnSelect
                    getOptionLabel={getOptionLabel}
                    isOptionEqualToValue={isOptionEqualToValue}
                    renderOption={renderFilterOption}
                    renderInput={renderInput}
                    fullWidth={fullWidth}
                    disabled={disabled}
                    renderTags={(value, getTagProps) =>
                        value?.map((option, index) => (
                            <StyledChip
                                label={getOptionLabel(option)}
                                chipColor={chipColor}
                                {...getTagProps({ index })}
                                deleteIcon={
                                    <CancelIcon
                                        fontSize="small"
                                        color={"action"}
                                    />
                                }
                            />
                        ))
                    }
                />
            </FilterContainer>
        )
    },
)
