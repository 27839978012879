import { t } from "@lingui/macro"
import {
    Table as MUITable,
    TableBody,
    TableCell as MUITableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow as MUITableRow,
    SxProps,
    Theme,
} from "@mui/material"
import { observer } from "mobx-react"
import React, { useCallback } from "react"

import { NoWrap, TableContainerPaper, TableRow, EmptyState } from "./styled"

import {
    ActionDropdown,
    IActionDropdownItem,
} from "src/components/ActionDropdown"

import { Pagination } from "src/lib/pagination"

type IRenderedItem = { [key: string]: React.ReactNode }

interface IProps<
    TItem extends { id: string | number },
    TRenderedItem extends IRenderedItem,
    THeaders extends keyof TRenderedItem,
> {
    paginator: Pagination<TItem, unknown>
    headers: {
        key: THeaders
        name: React.ReactNode
        hidden?: boolean
    }[]
    tableContainerStyle?: SxProps<Theme>
    rowRenderer: (item: TItem) => TRenderedItem
    rowActionsRenderer?: (item: TItem) => IActionDropdownItem[]

    /**
     * Table uses the `id` property as the row key be default. This works for in
     * the majority of cases. But sometimes the `id` isn't unique for each row,
     * e.g. the same resource is rendered multiple times. In those cases
     * `rowKeyResolver` can be used to set an appropriate key.
     */
    rowKeyResolver?: (item: TItem) => React.Key
    onRowClick?: (item: TItem) => void
    emptyStateText?: string
}

export const Table = observer(
    <
        TItem extends { id: string | number },
        TRenderedItem extends IRenderedItem,
        THeaders extends keyof TRenderedItem,
    >(
        props: IProps<TItem, TRenderedItem, THeaders>,
    ) => {
        /**
         * We need to store the `onRowClick` function in a variable because
         * a table's row cell can have a component with a clickable action,
         * e.g. ActionDropdown, clicking which trigger the onRowClick as well.
         * Below onRowClick is set to undefined to prevent the row from being clicked
         * when the action menu is open.
         */
        let _onRowClick = props.onRowClick

        const handlePageChange = useCallback(
            (_: unknown, page: number) => props.paginator.loadPage(page),
            [props],
        )

        const handleRowsPerChange = useCallback(
            (
                event: React.ChangeEvent<
                    HTMLInputElement | HTMLTextAreaElement
                >,
            ) => props.paginator.loadPageSize(Number(event.target.value)),
            [props],
        )

        return (
            <TableContainer
                component={TableContainerPaper}
                sx={props.tableContainerStyle}
            >
                <MUITable size="small">
                    <TableHead sx={{ height: "56px" }}>
                        <MUITableRow>
                            {props.rowActionsRenderer != null && (
                                <MUITableCell padding="none" />
                            )}
                            {props.headers.map((header) =>
                                header.hidden !== true ? (
                                    <MUITableCell key={header.key.toString()}>
                                        <NoWrap>{header.name}</NoWrap>
                                    </MUITableCell>
                                ) : null,
                            )}
                        </MUITableRow>
                    </TableHead>
                    <TableBody>
                        {props.paginator.items.map((item) => (
                            <TableRow
                                data-testid="Table/Row"
                                key={props.rowKeyResolver?.(item) ?? item.id}
                                onClick={
                                    _onRowClick != null
                                        ? () => _onRowClick?.(item)
                                        : undefined
                                }
                                role={
                                    props.onRowClick != null ? "button" : "row"
                                }
                            >
                                {props.rowActionsRenderer != null && (
                                    <MUITableCell
                                        align="right"
                                        padding="none"
                                        width="40px"
                                    >
                                        <ActionDropdown
                                            items={props.rowActionsRenderer(
                                                item,
                                            )}
                                            onActionMenuClose={() => {
                                                _onRowClick = props.onRowClick
                                            }}
                                            onActionMenuOpen={() => {
                                                _onRowClick = undefined
                                            }}
                                        />
                                    </MUITableCell>
                                )}

                                {props.headers.map((header) =>
                                    header.hidden !== true ? (
                                        <MUITableCell
                                            key={header.key.toString()}
                                        >
                                            {
                                                props.rowRenderer(item)[
                                                    header.key
                                                ]
                                            }
                                        </MUITableCell>
                                    ) : null,
                                )}
                            </TableRow>
                        ))}
                        {props.paginator.items.length === 0 && (
                            <TableRow>
                                <MUITableCell
                                    colSpan={props.headers.length + 1}
                                    sx={{ textAlign: "center" }}
                                >
                                    <EmptyState
                                        spacing={1}
                                        data-testid="Table/NoData"
                                    >
                                        <span>{t`table-pagination-component.no-information`}</span>
                                        {props.emptyStateText != null && (
                                            <span>{props.emptyStateText}</span>
                                        )}
                                    </EmptyState>
                                </MUITableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </MUITable>
                <TablePagination
                    align="left"
                    component="div"
                    count={props.paginator.meta.count ?? -1}
                    page={props.paginator.meta.page}
                    onPageChange={handlePageChange}
                    onRowsPerPageChange={handleRowsPerChange}
                    rowsPerPage={props.paginator.meta.pageSize}
                    labelRowsPerPage={t`table-pagination-component.rows-per-page`}
                    labelDisplayedRows={(info) =>
                        t({
                            id: "table-pagination-component.displayed-rows",
                            values: {
                                from: info.from,
                                count: info.count,
                                to: info.to,
                                page: info.page,
                            },
                        })
                    }
                />
            </TableContainer>
        )
    },
)
