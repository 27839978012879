import { t } from "@lingui/macro"
import { Typography } from "@mui/material"
import { useMemo } from "react"

import { NoWrap } from "src/components/NoWrap"
import { IColumn } from "src/types/data-grid-pro"
import { WarningFlagChipsRow } from "src/views/community-overview/components/WarningFlagChipsRow"
import { WarningFlagsHeader } from "src/views/community-overview/components/WarningFlagsHeader"
import { ICommunityFlagsOverview } from "src/views/community-overview/types"

export const useGetCommunityOverviewColumns =
    (): IColumn<ICommunityFlagsOverview>[] =>
        useMemo(
            () => [
                {
                    field: "name",
                    headerName: "",
                    renderHeader: () => (
                        <Typography variant="h6">{t`community-overview.table-header-community-name`}</Typography>
                    ),
                    renderCell: (params) => <NoWrap>{params.value}</NoWrap>,
                    filterable: false,
                    sortable: false,
                    resizable: false,
                    flex: 1,
                },
                {
                    field: "flags",
                    type: "actions",
                    headerName: "",
                    headerAlign: "right",
                    renderHeader: () => <WarningFlagsHeader />,
                    align: "right",
                    renderCell: (params) => (
                        <WarningFlagChipsRow
                            flags={params.row.flags}
                            communityId={params.row.id}
                        />
                    ),
                    filterable: false,
                    sortable: false,
                    resizable: false,
                    flex: 3,
                },
            ],
            [],
        )
