import { Grid, styled } from "@mui/material"

export const StyledStatisticsRowGrid = styled(Grid)(({ theme }) => {
    const border = `1px solid ${theme.palette.grey[200]}`

    return {
        [theme.breakpoints.up("xl")]: {
            borderLeft: border,
            "&:first-of-type": {
                borderLeft: "none",
            },
        },
        [theme.breakpoints.between("md", "xl")]: {
            "&:nth-of-type(-n+2)": {
                borderBottom: border,
            },
            "&:nth-of-type(odd)": {
                borderRight: border,
            },
        },
        [theme.breakpoints.down("md")]: {
            borderBottom: border,
            "&:last-of-type": {
                borderBottom: "none",
            },
            "&:first-of-type": {
                borderTop: "none",
            },
        },
    }
})
