/* eslint-disable import/no-relative-parent-imports */
import {
    FormControl,
    FormHelperText,
    InputLabel,
    MenuItem,
    Select,
} from "@mui/material"
import { observer } from "mobx-react"
import { t } from "@lingui/macro"

import { useEffect } from "react"

import camelcaseKeys from "camelcase-keys"

import { IntegrationConnectorItemStore } from "../store"

import { IConnectorProps } from "../components/types"

import { useStore } from "src/store/lib/useStore"
import { reportError } from "src/lib/report"
import { ConnectorAdminService } from "src/api"
import { ResourceType } from "src/types/resource"

export const Connector = observer((props: IConnectorProps) => {
    const store = useStore(IntegrationConnectorItemStore)

    useEffect(() => {
        ;(async () => {
            if (store.isEditMode && store.selectedConnectorConfig != null) {
                store.setConnector([
                    camelcaseKeys(store.selectedConnectorConfig),
                ])
                return
            }
            // get connectors list for create new case
            try {
                store.setConnector(
                    camelcaseKeys(
                        await ConnectorAdminService.getV1AdminConnectorIntegrationType(),
                    ).map((types) => ({
                        ...types,
                    })),
                )
            } catch (error) {
                reportError(
                    t`edit-information-item-modal.load-types-fail`,
                    error,
                )
            }
        })()
    }, [store])

    const value = () => {
        // This Connector component is used in 2 components currently and only popupView component should have a hardcoded connectorID.
        if (props.isPopUpView === true) {
            const connectorTypeToBeMatchedWith =
                props.resourceType === ResourceType.BookingEngine
                    ? "accessy"
                    : "external_access_scheduler"
            const connector = store.connectors?.find(
                (connector) => connector.type === connectorTypeToBeMatchedWith,
            )

            store.formFields.set(
                "connectorTypeId",
                connector?.integrationTypeId ?? -1,
            )
        }

        return store.formFields.get("connectorTypeId")
    }

    const onSelectValueChange = async (connectorTypeId: string) => {
        const findIndex = store.connectors?.findIndex(
            (i) => i.integrationTypeId === Number(connectorTypeId),
        )
        if (findIndex !== -1) {
            store.formFields.set("type", store.connectors[findIndex].type)
        }
        store.formFields.set("connectorTypeId", Number(connectorTypeId))
        // TODO: Currently getV1AdminConnectorIntegrationType has all the connector related data, but we plan to remove it and use the data from the below API.
        await store.getSelectedConnectorConfig()
    }

    return (
        <FormControl style={{ breakInside: "avoid" }}>
            <InputLabel id="type-label">{t`integration-connector-modal.connector-type`}</InputLabel>
            {store.connectors.length > 0 && (
                <Select
                    data-testid="connector-dropdown"
                    labelId="connector-label"
                    id="connector"
                    value={String(value())}
                    label={t`integration-connector-modal.connector-type`}
                    disabled={Boolean(store.isEditMode) || props.isPopUpView}
                    onChange={(event) =>
                        onSelectValueChange(event.target.value)
                    }
                    error={Boolean(store.formFields.error("connectorTypeId"))}
                >
                    {store.connectors.map((item) => (
                        <MenuItem
                            value={item.integrationTypeId}
                            key={item.integrationTypeId}
                        >
                            {item.displayName}
                        </MenuItem>
                    ))}
                </Select>
            )}
            {Boolean(store.formFields.error("connectorTypeId")) && (
                <FormHelperText error={true}>
                    {store.formFields.error("connectorTypeId")}
                </FormHelperText>
            )}
        </FormControl>
    )
})
