import { t } from "@lingui/macro"
import { format, startOfMonth, endOfMonth } from "date-fns"
import { makeAutoObservable } from "mobx"
import { createContext } from "react"

import { trackModuleEvent } from "src/analytics/helpers/mixpanel_tracking"

import {
    batch_CommunitiesAndTimePeriodRequest,
    batch_CommunityFlagsCount,
    batch_FlagsByCommunity,
    CommunityAdminService,
    CommunityInsightsAdminService,
} from "src/api"
import { loads } from "src/channel/utils"
import { TMultipleSelectCheckmarksOption } from "src/components/MultipleSelectCheckmarks/types"
import { DEFAULT_ACCESS_GROUP } from "src/config"
import { createLoadingKeys } from "src/lib/loading"
import { Pagination } from "src/lib/pagination"
import { reportError } from "src/lib/report"
import {
    ICommunityFlagsOverview,
    ICommunityOverviewCommunityItem,
    AuthorType,
} from "src/views/community-overview/types"
import { MixpanelProperties } from "src/analytics/constants/properties"

const DATE_FORMAT_SHORT = "yyyy-MM-dd"

export class CommunitiesOverviewViewStore implements IDisposable {
    static Context = createContext<CommunitiesOverviewViewStore | null>(null)
    static LoadingKeys = createLoadingKeys("init", "loading")

    private _accessGroupId: number = DEFAULT_ACCESS_GROUP.id
    private allCommunities: ICommunityOverviewCommunityItem[] = []
    private _showHandled: boolean | null = null
    private _authorType: AuthorType = AuthorType.All

    flagsByCommunities: ICommunityFlagsOverview[] = []
    totalFlags: batch_CommunityFlagsCount = {}
    selectedCommunities: number[] = []
    numberOfPosts: number = 0
    numberOfComments: number = 0
    activeUsers: string = "0.00"
    readRate: string = "0.00"
    communityFlags: batch_CommunityFlagsCount = {}
    private _storedDate: string | null = window.localStorage.getItem(
        "overview_created_date",
    )
    startDate: string =
        this._storedDate ?? format(startOfMonth(new Date()), DATE_FORMAT_SHORT)
    endDate: string =
        this._storedDate ?? format(endOfMonth(new Date()), DATE_FORMAT_SHORT)

    communitiesPaginator = new Pagination(
        (params) => {
            const pageStart = (params.page - 1) * params.pageSize
            const communities = this.flagsByCommunities
            const pageCommunities = communities.slice(
                pageStart,
                pageStart + params.pageSize,
            )

            return {
                items: pageCommunities,
                sourceItems: this.flagsByCommunities,
                count: this.flagsByCommunities.length,
            }
        },
        {
            static: true,
        },
    )

    get communitiesForMultiSelect(): TMultipleSelectCheckmarksOption[] {
        return this.allCommunities.map((community) => ({
            value: community.id.toString(),
            name: community.name,
        }))
    }

    get showHandled() {
        return this._showHandled
    }

    get authorType() {
        return this._authorType
    }

    private repositoryUpdatesListenerDisposer?: () => void

    constructor() {
        makeAutoObservable(this)
    }

    dispose(): void {
        this.repositoryUpdatesListenerDisposer?.()
    }

    private getRequestPayload = () => {
        const isHandledFilter: { is_handled?: boolean } =
            this._showHandled !== null ? { is_handled: this._showHandled } : {}

        const request: batch_CommunitiesAndTimePeriodRequest = {
            access_group_id:
                this._accessGroupId !== DEFAULT_ACCESS_GROUP.id
                    ? this._accessGroupId
                    : undefined,
            start_month: format(new Date(this.startDate), "yyyyMM"),
            end_month: format(new Date(this.endDate), "yyyyMM"),
            communities: this.selectedCommunities,
            ...isHandledFilter,
            author_type:
                this.authorType === AuthorType.All
                    ? undefined
                    : this.authorType,
        }

        return request
    }

    @loads(() => CommunitiesOverviewViewStore.LoadingKeys.init)
    async init(accessGroupId: number) {
        this.setAccessGroupId(accessGroupId)
        await this.loadCommunities()
        await this.loadStatistics()
    }

    @loads(() => CommunitiesOverviewViewStore.LoadingKeys.init)
    loadStatistics() {
        const request = this.getRequestPayload()
        // load statistics
        return Promise.all([
            this.loadNumberOfPosts(request),
            this.loadNumberOfComments(request),
            this.loadReadRate(request),
            this.loadEngagementRate(request),
        ]).catch((error) => {
            reportError(
                t`community-overview-store.failed-to-load-community-overview-statistics`,
                error,
            )
        })
    }

    private async loadCommunities() {
        const rawCommunities = await CommunityAdminService.getV1AdminCommunity({
            accessGroupId:
                this._accessGroupId !== DEFAULT_ACCESS_GROUP.id
                    ? this._accessGroupId
                    : undefined,
        })
        this.setCommunities(
            (rawCommunities ?? [])
                .filter(
                    (community) =>
                        this._accessGroupId === DEFAULT_ACCESS_GROUP.id ||
                        community.access_group_id === this._accessGroupId,
                )
                .map((community) => {
                    return {
                        id: community.community_id ?? 0,
                        name: community.internal_name ?? "",
                    }
                }),
        )
    }

    // https://avy.atlassian.net/browse/AVY-14499
    @loads(() => CommunitiesOverviewViewStore.LoadingKeys.loading)
    async loadCommunityFlags() {
        const request = this.getRequestPayload()

        try {
            const communitiesFlags =
                await CommunityInsightsAdminService.postV1AdminCommunityInsightsOverviewNbrFlags(
                    { request },
                )

            this.setFlagsByCommunities(
                communitiesFlags.flags_by_communities ?? [],
            )
            this.totalFlags = communitiesFlags.total_flags ?? {}
            await this.communitiesPaginator.loadInitialPage()
        } catch (error) {
            reportError(
                t`community-overview-store.failed-to-load-community-overview-endpoints`,
                error,
            )
        }
    }
    private async loadNumberOfPosts(
        request: batch_CommunitiesAndTimePeriodRequest,
    ) {
        const numberOfPosts =
            await CommunityInsightsAdminService.postV1AdminCommunityInsightsOverviewNbrPosts(
                { request },
            )

        this.numberOfPosts = numberOfPosts.count ?? 0
    }
    private async loadNumberOfComments(
        request: batch_CommunitiesAndTimePeriodRequest,
    ) {
        const numberOfComments =
            await CommunityInsightsAdminService.postV1AdminCommunityInsightsOverviewNbrComments(
                { request },
            )

        this.numberOfComments = numberOfComments.count ?? 0
    }

    async loadEngagementRate(request: batch_CommunitiesAndTimePeriodRequest) {
        const engagementRate =
            await CommunityInsightsAdminService.postV1AdminCommunityInsightsOverviewEngagementRate(
                { request },
            )

        this.activeUsers = engagementRate.rate?.toFixed(2) ?? "0.00"
    }

    async loadReadRate(request: batch_CommunitiesAndTimePeriodRequest) {
        const readRate =
            await CommunityInsightsAdminService.postV1AdminCommunityInsightsOverviewReadRate(
                { request },
            )

        this.readRate = readRate.read_rate?.toFixed(2) ?? "0.00"
    }

    private setCommunities(communities: ICommunityOverviewCommunityItem[]) {
        this.allCommunities = communities
    }

    private setAccessGroupId(accessGroupId: number) {
        this._accessGroupId = accessGroupId
    }

    setFlagsByCommunities(flagsByCommunities: batch_FlagsByCommunity[]) {
        const flagsByCommunitiesNew: ICommunityFlagsOverview[] =
            flagsByCommunities.map((community) => {
                return {
                    id: community.community_id ?? 0,
                    name: community.community_internal_name ?? "",
                    flags: community.flags ?? {},
                }
            })
        this.flagsByCommunities = flagsByCommunitiesNew
    }

    setDateRangeFilter(value: Date) {
        this.startDate = format(startOfMonth(value), DATE_FORMAT_SHORT)
        this.endDate = format(endOfMonth(value), DATE_FORMAT_SHORT)
        // setting to local storage as this is needed for the date range picker, when we come back from some other screen
        window.localStorage.setItem("overview_created_date", this.startDate)
    }

    async setShowHandled(value: boolean | null) {
        this._showHandled = value
        await this.reload()
    }

    async setAuthorType(value: AuthorType) {
        this._authorType = value
        await this.reload()
    }

    async reload() {
        await this.loadStatistics()
        await this.loadCommunityFlags()
    }

    filterBySelectedCommunity(communities: TMultipleSelectCheckmarksOption[]) {
        const selectedCommunitiesIds = communities.map((community) =>
            parseInt(community.value),
        )

        this.selectedCommunities = selectedCommunitiesIds
    }

    generatePostCommentUrl(flag?: string, communityId?: string) {
        let urlParams: string = ``

        // flag as parameter
        if (flag !== undefined) {
            urlParams += `&flag=${flag}`
        }

        // community_id as parameter
        const communitiesIDs =
            this.selectedCommunities.length > 0
                ? this.selectedCommunities.map((id) => id.toString())
                : this.communitiesForMultiSelect.map((community) =>
                      community.value.toString(),
                  )
        urlParams += `&community_id=${
            communityId !== undefined ? communityId : communitiesIDs.join(",")
        }`

        // is_handled as parameter
        if (this.showHandled !== null) {
            urlParams += `&is_handled=${this.showHandled.toString()}`
        }

        // start_date as parameter
        if (this.startDate !== null) {
            urlParams += `&created_date=${this.startDate}`
        }

        trackModuleEvent("Open posts and comments", {
            [MixpanelProperties.EntryPoint]: "Overview",
            [MixpanelProperties.SelectedFlag]: flag,
            [MixpanelProperties.CommunityFilterCount]: communitiesIDs.length,
            [MixpanelProperties.CommunityFilterNames]:
                this.selectedCommunities.length > 0
                    ? this.selectedCommunities.map((id) => {
                          const community = this.communitiesForMultiSelect.find(
                              (c) => c.value === id.toString(),
                          )
                          return community?.name ?? ""
                      })
                    : this.communitiesForMultiSelect.map((community) =>
                          community.name.toString(),
                      ),
            [MixpanelProperties.MonthFilter]: this.startDate,
        })

        return urlParams
    }
}
