import React from "react"
import { makeAutoObservable } from "mobx"

import { endOfMonth, subYears, startOfMonth, format } from "date-fns"

import { Pagination } from "src/lib/pagination"
import {
    batch_CommunityScore,
    batch_CommunityTopic,
    CommunityAdminService,
    CommunityInsightsAdminService,
} from "src/api"
import { createLoadingKeys } from "src/lib/loading"
import { DEFAULT_ACCESS_GROUP } from "src/config"
import { loads } from "src/channel/utils"
import {
    ICommunity,
    ICommunitiesInsight,
    IDateRangeFilter,
} from "src/views/community-insights/types"
import { IDateRangeValue } from "src/types/date_range_picker"
import {
    getCommunityPeriods,
    getMonthsPeriodsFromDateRange,
} from "src/views/community-insights/helpers"

export class CommunityInsightsStore {
    static Context = React.createContext<CommunityInsightsStore | null>(null)
    static CommunityInsightsKeys = createLoadingKeys("init", "loading")

    //#region Observables
    allCommunities: ICommunity[] = []
    allCommunitiesInsights: ICommunitiesInsight[] = []
    periods: string[] = []
    selectedCommunities: number[] = []
    segments: number[] = []
    communityTopics: batch_CommunityTopic[] = []
    selectedTopic?: string
    dateRangeFilter: IDateRangeFilter = {
        from: startOfMonth(subYears(new Date(), 1)),
        to: endOfMonth(new Date()),
    }

    communityInsightsPagination = new Pagination(
        (params) => {
            const pageStart = (params.page - 1) * params.pageSize
            const communities = this.allCommunitiesInsights
            const pageCommunities = communities.slice(
                pageStart,
                pageStart + params.pageSize,
            )

            return {
                items: pageCommunities,
                sourceItems: this.allCommunitiesInsights,
                count: this.allCommunitiesInsights.length,
            }
        },
        {
            static: true,
        },
    )

    get selectedCommunitiesIds() {
        return this.selectedCommunities.map((id) => id)
    }
    //# endregion

    constructor() {
        makeAutoObservable(this)
    }

    dispose() {
        this.listenToRepositoryChangesDisposer?.()
    }

    private accessGroupId: number = DEFAULT_ACCESS_GROUP.id
    private listenToRepositoryChangesDisposer?: () => void

    //#region Async loaders
    @loads(() => CommunityInsightsStore.CommunityInsightsKeys.init)
    async init(accessGroupId: number) {
        this.setAccessGroupId(accessGroupId)
        await this.fetchCommunities()
        await this.fetchCommunityInsightsTopics()
    }

    @loads(() => CommunityInsightsStore.CommunityInsightsKeys.loading)
    async loadPeriods() {
        await this.loadCommunityInsightPeriods()
        await this.communityInsightsPagination.loadInitialPage()
    }

    @loads(() => CommunityInsightsStore.CommunityInsightsKeys.loading)
    async loadCommunityInsightPeriods() {
        if (this.allCommunities.length === 0) {
            return this.resetInsights()
        }

        const rowCommunitiesInsights = await this.fetchCommunityInsights()

        if (rowCommunitiesInsights?.length === 0) {
            return this.resetInsights()
        }

        const periodColumns = this.extractPeriodColumns()
        const monthlyScores = this.buildMonthlyScores(rowCommunitiesInsights)

        this.setPeriods(periodColumns)
        this.setAllCommunitiesInsights(monthlyScores)
    }
    //#endregion

    //#region Private fetchers
    private async fetchCommunities() {
        const communities = await CommunityAdminService.getV1AdminCommunity({
            accessGroupId:
                this.accessGroupId !== DEFAULT_ACCESS_GROUP.id
                    ? this.accessGroupId
                    : undefined,
        })

        const filteredCommunities =
            communities
                ?.filter(
                    (community) =>
                        this.accessGroupId === DEFAULT_ACCESS_GROUP.id ||
                        community.access_group_id === this.accessGroupId,
                )
                .map((community) => ({
                    id: community.community_id ?? 0,
                    name: community.name !== undefined ? community?.name : "",
                    internal_name: community.internal_name ?? "",
                })) ?? []

        this.setCommunities([...filteredCommunities])
        await this.loadPeriods()
    }

    private async fetchCommunityInsightsTopics() {
        const topics =
            await CommunityInsightsAdminService.getV1AdminCommunityInsightsInsightsTopic()
        this.setTopics(topics?.topics ?? [])
        this.setSelectedTopic(topics?.topics?.[0]?.community_topic_id ?? 1)
    }

    private async fetchCommunityInsights() {
        const communityIds = this.selectedCommunities
        return await CommunityInsightsAdminService.postV1AdminCommunityInsightsInsightsMonthlyCommunityScores(
            {
                request: {
                    access_group_id:
                        this.accessGroupId !== DEFAULT_ACCESS_GROUP.id
                            ? this.accessGroupId
                            : undefined,
                    communities: communityIds,
                    start_month: format(this.dateRangeFilter.from, "yyyyMM"),
                    end_month: format(this.dateRangeFilter.to, "yyyyMM"),
                    topic_id: parseInt(this.selectedTopic ?? "1"),
                },
            },
        )
    }
    //#endregion

    //#region Private helpers
    private resetInsights() {
        this.setPeriods([])
        this.setAllCommunitiesInsights([])
    }

    private extractPeriodColumns(): string[] {
        return getMonthsPeriodsFromDateRange(
            this.dateRangeFilter.from,
            this.dateRangeFilter.to,
        )
    }

    private buildMonthlyScores(
        rowCommunitiesInsights: batch_CommunityScore[],
    ): ICommunitiesInsight[] {
        return rowCommunitiesInsights.reduce(
            (acc: ICommunitiesInsight[], row) => {
                const community = this.findCommunityById(row.community_id)
                if (community == null) return acc

                const periods = getCommunityPeriods(row.monthly_scores)
                acc.push({
                    id: row.community_id ?? 0,
                    name: community.name ?? "",
                    internal_name: community.internal_name ?? "",
                    periods,
                })
                return acc
            },
            [],
        )
    }

    private findCommunityById(communityId?: number) {
        return this.allCommunities.find(
            (community) => community.id === communityId,
        )
    }

    //#endregion

    //#region Private setters
    private setAllCommunitiesInsights(communities: ICommunitiesInsight[]) {
        this.allCommunitiesInsights = communities
    }

    private setCommunities(communities: ICommunity[]) {
        this.allCommunities = communities
    }

    private setAccessGroupId(accessGroupId: number) {
        this.accessGroupId = accessGroupId
    }

    private setTopics(topics: batch_CommunityTopic[]) {
        this.communityTopics = topics
    }
    //#endregion

    //#region Public setters
    setDateRangeFilter(value: IDateRangeValue) {
        const from = startOfMonth(value.from ?? new Date())
        const to = endOfMonth(value.to ?? new Date())
        this.dateRangeFilter = { from, to }
    }

    setPeriods(periods: string[]) {
        this.periods = periods
    }

    setSelectedCommunities(communityIds: number[]) {
        this.selectedCommunities = communityIds
    }

    setSelectedTopic(topicId: string | number) {
        this.selectedTopic = topicId.toString()
    }
    //#endregion
}
