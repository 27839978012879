import { t } from "@lingui/macro"
import { Close, Favorite } from "@mui/icons-material"
import { IconButton, Stack, styled } from "@mui/material"
import { observer } from "mobx-react"
import { useEffect } from "react"

import { CommunityLikesStore } from "./store"

import { Avatar } from "src/components/Avatar"
import { GlobalStore } from "src/store"
import { StoreProvider } from "src/store/lib/StoreProvider"
import { useStore } from "src/store/lib/useStore"
import { CenteredSpinner } from "src/components/CenteredSpinner"

const Header = styled(Stack)(({ theme }) => ({
    padding: "0 12px 0 24px",
    height: "64px",
    position: "sticky",
    top: 0,
    background: theme.palette.common.white,
    zIndex: 1,
}))

const LikeCount = styled("span")({
    fontSize: "20px",
    lineHeight: "32px",
    fontWeight: 500,
})

const List = styled("ul")({
    padding: "0 24px 24px",
    margin: 0,
})

const ListItem = styled("li")({
    flex: 1,
    listStyle: "none",
    padding: "4px 0",
})

type IPropsForPost = { postId: number }
type IPropsForComment = { postId: number; commentId: number }
type IProps = IPropsForPost | IPropsForComment

const Modal = observer((props: IProps) => {
    const gstore = useStore(GlobalStore)
    const store = useStore(CommunityLikesStore)

    useEffect(() => {
        ;(async () => {
            "commentId" in props && props.commentId != null
                ? await store.init(props.postId, props.commentId)
                : await store.init(props.postId)
        })()
    }, [props, store])

    if (gstore.loading.is(CommunityLikesStore.LoadingKeys.init)) {
        return <CenteredSpinner height="20vh" />
    }

    return (
        <div>
            <Header
                direction="row"
                justifyContent="space-between"
                alignItems="center"
            >
                <Stack direction="row" alignItems="center" spacing={1}>
                    <Favorite />
                    <LikeCount>
                        {t({
                            id: "community-likes-modal.like-count",
                            values: { count: store.likes.length },
                        })}
                    </LikeCount>
                </Stack>
                <IconButton onClick={() => gstore.modals.pop()}>
                    <Close />
                </IconButton>
            </Header>
            <List>
                {store.likes.map((like, i) => (
                    <ListItem key={`${i}-${like.authorName}`}>
                        <Stack
                            direction="row"
                            alignItems="center"
                            spacing={1}
                            component="span"
                        >
                            <Avatar size={40} name={like.authorName} />
                            <span>{like.authorName}</span>
                        </Stack>
                    </ListItem>
                ))}
            </List>
        </div>
    )
})

export const CommunityLikesModal = observer((props: IProps) => {
    return (
        <StoreProvider Store={CommunityLikesStore}>
            <Modal {...props} />
        </StoreProvider>
    )
})
