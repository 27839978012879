import { t } from "@lingui/macro"
import { TextField, styled, FormHelperText } from "@mui/material"
import { observer } from "mobx-react"

import { ImageList } from "src/modals/community-posts-and-comments/components/CommentForm/ImageList"
import { CommunityPostsAndCommentsStore } from "src/modals/community-posts-and-comments/store"
import { GlobalStore } from "src/store"
import { useStore } from "src/store/lib/useStore"

const Positioner = styled("div")(() => ({
    position: "relative",
}))

const ImagesWrap = styled("div")({
    position: "absolute",
    bottom: "8px",
    right: "8px",
    left: "8px",
})

const InputField = styled(TextField, {
    shouldForwardProp: (name) => name !== "hasImages",
})<{ hasImages: boolean }>(({ hasImages }) => ({
    "& .MuiInputBase-root": {
        ...(hasImages ? { paddingBottom: "100px" } : {}),
    },
}))

export const Input = observer(() => {
    const gstore = useStore(GlobalStore)
    const store = useStore(CommunityPostsAndCommentsStore)

    const isSubmitting = gstore.loading.is(
        CommunityPostsAndCommentsStore.LoadingKeys["submit-comment"],
    )

    return (
        <div>
            <Positioner>
                <InputField
                    onChange={store.commentForm.setter("text")}
                    value={store.commentForm.get("text")}
                    hasImages={store.commentForm.get("images").length > 0}
                    disabled={isSubmitting}
                    placeholder={t`view-community-post-modal.comment-form-text-input-placeholder`}
                    error={Boolean(store.commentForm.error("text"))}
                    size="small"
                    multiline
                />
                {store.commentForm.get("images").length > 0 && (
                    <ImagesWrap>
                        <ImageList />
                    </ImagesWrap>
                )}
            </Positioner>
            {Boolean(store.commentForm.error("text")) && (
                <FormHelperText
                    error={Boolean(store.commentForm.error("text"))}
                >
                    {store.commentForm.error("text")}
                </FormHelperText>
            )}
        </div>
    )
})
