import { t } from "@lingui/macro"
import { useCallback } from "react"

import { ConfirmModal } from "src/modals/confirm"
import {
    IComment,
    IPost,
    IPostAndCommentsItemType,
    IPostAndCommentsItem,
} from "src/types/community-posts-and-comments/communityPostsAndComments"
import { GlobalStore } from "src/store"
import { useStore } from "src/store/lib/useStore"
import {
    handledComment,
    handledPost,
    handledReportComment,
    handledReportPost,
} from "src/shared/community-posts-and-comments/helpers/postAndCommentsActions"
import { trackModuleEvent } from "src/analytics/helpers/mixpanel_tracking"
import { MixpanelProperties } from "src/analytics/constants/properties"
import { TrackingSource, TrackingAction } from "src/analytics/constants/sources"

import { IConfirmModalProps } from "src/views/community-posts-and-comments/types"

type TItem = IPostAndCommentsItem | IPost | IComment

export const useHandleAndReportActions = () => {
    const globalStore = useStore(GlobalStore)

    const isPost = (item: TItem) =>
        item.itemType === IPostAndCommentsItemType.POST

    const isHandled = (item: TItem) => Boolean(item.is_handled)

    const getReportSource = (item: TItem): TrackingSource =>
        "post_id" in item ? TrackingSource.ListView : TrackingSource.Post

    const getPostId = (item: TItem) => {
        if (isPost(item)) return item.original_id
        if ("postId" in item) return item.postId
        if ("post_id" in item) return item.post_id
        return undefined
    }

    const openModal = useCallback(
        (
            ModalComponent: React.ComponentType<IConfirmModalProps>,
            props: IConfirmModalProps,
        ) => {
            globalStore.modals.open(() => <ModalComponent {...props} />, {
                variant: "slide-up-w600",
            })
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    )

    const handleReportPost = useCallback(
        (item: TItem, source: TrackingSource) => async () => {
            const username = globalStore.session.user?.name

            trackModuleEvent("Report post", {
                [MixpanelProperties.ReportedFrom]: source,
                [MixpanelProperties.EventDefinition]:
                    'When user confirms the report with "ok"',
                [MixpanelProperties.PostID]: item.original_id,
                [MixpanelProperties.PostText]: item.text,
                [MixpanelProperties.ReportedBy]: username,
            })

            await handledReportPost(item.original_id)
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    )

    const handleReportComment = useCallback(
        (item: TItem, source: TrackingSource) => async () => {
            const username = globalStore.session.user?.name

            trackModuleEvent("Report comment", {
                [MixpanelProperties.ReportedFrom]: source,
                [MixpanelProperties.EventDefinition]:
                    'When user confirms the report with "ok"',
                [MixpanelProperties.CommentID]: item.original_id,
                [MixpanelProperties.PostID]: getPostId(item),
                [MixpanelProperties.CommentText]: item.text,
                [MixpanelProperties.ReportedBy]: username,
            })

            await handledReportComment(item.original_id)
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    )

    const handlePostHandledStatus = useCallback(
        (item: TItem, source: TrackingSource) => async () => {
            const username = globalStore.session.user?.name
            const isItemHandled = isHandled(item)

            if (isItemHandled) {
                trackModuleEvent("Mark post as unhandled", {
                    [MixpanelProperties.UnhandledFrom]: source,
                    [MixpanelProperties.EventDefinition]:
                        "User marking as unhandled",
                    [MixpanelProperties.PostID]: item.original_id,
                    [MixpanelProperties.PostText]: item.text,
                    [MixpanelProperties.UnhandledBy]: username,
                })
            } else {
                trackModuleEvent("Mark post as handled", {
                    [MixpanelProperties.HandledFrom]: source,
                    [MixpanelProperties.EventDefinition]:
                        "User marking as handled",
                    [MixpanelProperties.PostID]: item.original_id,
                    [MixpanelProperties.PostText]: item.text,
                    [MixpanelProperties.HandledBy]: username,
                })
            }

            await handledPost(item.original_id, isItemHandled)
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    )

    const handleCommentHandledStatus = useCallback(
        (item: TItem) => async () => {
            // Handle comment without tracking
            await handledComment(item.original_id, isHandled(item))
        },
        [],
    )

    const handleOrReportPostCommentAction = useCallback(
        (
                action: TrackingAction.Reported | TrackingAction.Handled,
                item: TItem,
            ) =>
            async (confirmed: boolean) => {
                if (!confirmed) return

                const source = getReportSource(item)

                if (action === TrackingAction.Reported) {
                    if (isPost(item)) {
                        await handleReportPost(item, source)()
                    } else {
                        await handleReportComment(item, source)()
                    }
                } else if (isPost(item)) {
                    await handlePostHandledStatus(item, source)()
                } else {
                    await handleCommentHandledStatus(item)()
                }
            },
        [
            handleReportPost,
            handleReportComment,
            handlePostHandledStatus,
            handleCommentHandledStatus,
        ],
    )

    const openActionConfirmModalForHandle = useCallback(
        (item: TItem) => () => {
            const title = isHandled(item)
                ? t`community-posts-comments.mark-unhandled-post`
                : t`community-posts-comments.mark-handled-post`

            const content = isHandled(item)
                ? t`community-posts-comments.are-mark-unhandled-post?`
                : t`community-posts-comments.are-mark-handled-post?`

            openModal(ConfirmModal, {
                onConfirm: handleOrReportPostCommentAction(
                    TrackingAction.Handled,
                    item,
                ),
                title,
                content,
            })
        },
        [openModal, handleOrReportPostCommentAction],
    )

    const openActionConfirmModalForReport = useCallback(
        (item: TItem) => () => {
            const title = isPost(item)
                ? t`community-posts-comments.report-post`
                : t`community-posts-comments.report-comment`

            const content = isPost(item)
                ? t`community-posts-comments.are-report-post?`
                : t`community-posts-comments.are-report-comment?`

            openModal(ConfirmModal, {
                onConfirm: handleOrReportPostCommentAction(
                    TrackingAction.Reported,
                    item,
                ),
                title,
                content,
            })
        },
        [openModal, handleOrReportPostCommentAction],
    )

    const actionsItems = useCallback(
        (item: TItem) => [
            {
                text: isPost(item)
                    ? t`community-posts-comments.report-post`
                    : t`community-posts-comments.report-comment`,
                onClick: openActionConfirmModalForReport(item),
                hidden: item?.flags?.reported_by_admin ?? false,
            },
            {
                text: isHandled(item)
                    ? t`community-posts-comments.mark-unhandled-post`
                    : t`community-posts-comments.mark-handled-post`,
                onClick: openActionConfirmModalForHandle(item),
                hidden: !isPost(item),
            },
        ],
        [openActionConfirmModalForReport, openActionConfirmModalForHandle],
    )

    return { actionsItems, openModal }
}
