import { Palette, SvgIconProps } from "@mui/material"
import {
    ErrorRounded,
    FlagRounded,
    OutlinedFlagRounded,
    WarningRounded,
    ConstructionRounded,
    ContactSupportRounded,
} from "@mui/icons-material"
import { t } from "@lingui/macro"
import React from "react"

import { community_CommunityFlags } from "src/api"

// Flag keys as constants with consistent naming
export const FLAG_KEYS = {
    SECURITY_RISK: "security_risk",
    COMMUNITY_GUIDELINES_VIOLATION: "community_guidelines_violation",
    REPORTED_BY_ADMIN: "reported_by_admin",
    REPORTED_BY_TENANT: "reported_by_tenant",
    MAINTENANCE: "maintenance",
    CUSTOMER_SUPPORT: "customer_support",
} as const

// Type definitions for improved type safety
export type FlagKey = typeof FLAG_KEYS[keyof typeof FLAG_KEYS]
export type FlagKeyConstant = keyof typeof FLAG_KEYS

// Flag metadata - centralized definition for all flag properties
export const FLAG_METADATA = {
    [FLAG_KEYS.SECURITY_RISK]: {
        translationKey: "community-posts-view.flags.security-risk",
        icon: ErrorRounded,
        getColor: (palette: Palette) => palette.error.main,
    },
    [FLAG_KEYS.COMMUNITY_GUIDELINES_VIOLATION]: {
        translationKey:
            "community-posts-view.flags.community-guidelines-violation",
        icon: WarningRounded,
        getColor: (palette: Palette) => palette.utility.clementine.main,
    },
    [FLAG_KEYS.REPORTED_BY_ADMIN]: {
        translationKey: "community-posts-view.flags.reported-by-admin",
        icon: FlagRounded,
        getColor: (palette: Palette) => palette.error.main,
    },
    [FLAG_KEYS.REPORTED_BY_TENANT]: {
        translationKey: "community-posts-view.flags.reported-by-tenant",
        icon: OutlinedFlagRounded,
        getColor: (palette: Palette) => palette.utility.clementine.main,
    },
    [FLAG_KEYS.MAINTENANCE]: {
        translationKey: "community-posts-view.flags.maintenance",
        icon: ConstructionRounded,
        getColor: (palette: Palette) => palette.info.main,
    },
    [FLAG_KEYS.CUSTOMER_SUPPORT]: {
        translationKey: "community-posts-view.flags.customer-support",
        icon: ContactSupportRounded,
        getColor: (palette: Palette) => palette.info.main,
    },
} as const

// Helper function to get the translation key for a flag
export const getFlagTranslationKey = (flagKey: string): string => {
    return FLAG_METADATA[flagKey as FlagKey]?.translationKey ?? flagKey
}

// Type for MUI SVG Icon components that accepts htmlColor
type SvgIconComponent = React.ComponentType<SvgIconProps>

// Derived maps from FLAG_METADATA for backward compatibility
export const flagToIconMap = Object.entries(FLAG_METADATA).reduce(
    (acc, [key, meta]) => {
        acc[key as FlagKey] = meta.icon
        return acc
    },
    {} as Record<FlagKey, SvgIconComponent>,
)

export const flagToColorMap = (palette: Palette) =>
    Object.entries(FLAG_METADATA).reduce((acc, [key, meta]) => {
        acc[key as FlagKey] = meta.getColor(palette)
        return acc
    }, {} as Record<FlagKey, string>)

// Get translated flag options with translations (memoize at component level)
export const getTranslatedFlagOptions = () => ({
    [FLAG_KEYS.SECURITY_RISK]: t`community-posts-view.flags.security-risk`,
    [FLAG_KEYS.COMMUNITY_GUIDELINES_VIOLATION]: t`community-posts-view.flags.community-guidelines-violation`,
    [FLAG_KEYS.REPORTED_BY_ADMIN]: t`community-posts-view.flags.reported-by-admin`,
    [FLAG_KEYS.REPORTED_BY_TENANT]: t`community-posts-view.flags.reported-by-tenant`,
    [FLAG_KEYS.MAINTENANCE]: t`community-posts-view.flags.maintenance`,
    [FLAG_KEYS.CUSTOMER_SUPPORT]: t`community-posts-view.flags.customer-support`,
})

// Get flag options for multiselect dropdown (memoize at component level)
export const getFlagOptionsForDropdown = () => [
    {
        id: FLAG_KEYS.SECURITY_RISK,
        title: t`community-posts-view.flags.security-risk`,
    },
    {
        id: FLAG_KEYS.COMMUNITY_GUIDELINES_VIOLATION,
        title: t`community-posts-view.flags.community-guidelines-violation`,
    },
    {
        id: FLAG_KEYS.MAINTENANCE,
        title: t`community-posts-view.flags.maintenance`,
    },
    {
        id: FLAG_KEYS.CUSTOMER_SUPPORT,
        title: t`community-posts-view.flags.customer-support`,
    },
    {
        id: FLAG_KEYS.REPORTED_BY_ADMIN,
        title: t`community-posts-view.flags.reported-by-admin`,
    },
    {
        id: FLAG_KEYS.REPORTED_BY_TENANT,
        title: t`community-posts-view.flags.reported-by-tenant`,
    },
]

// Helper to convert flag keys to flag object
export const flagKeysToFlagObject = (
    flagKeys: string[],
): community_CommunityFlags => {
    const flagObject: Partial<community_CommunityFlags> = {}

    const validFlagKeys = Object.values(FLAG_KEYS)

    flagKeys.forEach((key) => {
        if (validFlagKeys.includes(key as FlagKey)) {
            flagObject[key as keyof community_CommunityFlags] = true
        }
    })

    return flagObject as community_CommunityFlags
}
