export const formatDateToYYYYMMDD = (date: Date): string => {
    return date.toISOString().split("T")[0] // Returns YYYY-MM-DD format
}

export const getTodayDateObject = (): Date => {
    return new Date()
}

export const getYesterdayDateObject = (): Date => {
    const yesterday = new Date()
    yesterday.setDate(yesterday.getDate() - 1)
    return yesterday
}

export const getYesterdayDate = (): string => {
    return formatDateToYYYYMMDD(getYesterdayDateObject())
}

export const getTodayDate = (): string => {
    return formatDateToYYYYMMDD(getTodayDateObject())
}
