import { t } from "@lingui/macro"
import {
    Stack,
    TextField,
    Typography,
    useMediaQuery,
    useTheme,
} from "@mui/material"
import { observer } from "mobx-react"

import { FormFieldSet } from "src/components/FormFieldSet"
import { CaseDetailStore } from "src/modals/cases-v2-detail/store"
import { useStore } from "src/store/lib/useStore"

export const ContactDetailsSection = observer(() => {
    const store = useStore(CaseDetailStore)
    const theme = useTheme()

    const hasDetails =
        Boolean(store.form.get("contactEmail")) &&
        Boolean(store.form.get("contactPhoneNumber"))
    const smallScreen = useMediaQuery(theme.breakpoints.down("sm"))

    if (!hasDetails) {
        return (
            <Typography variant="body1">{t`cases-detail-modal.contact-details-section.no-contact-details`}</Typography>
        )
    }
    return (
        <FormFieldSet>
            <Stack spacing={2} direction={smallScreen ? "column" : "row"}>
                <TextField
                    label={t`cases-detail-modal.contact-details-section.email-address-label`}
                    value={store.form.get("contactEmail")}
                    onChange={store.form.setter("contactEmail")}
                    disabled
                />
                <TextField
                    label={t`cases-detail-modal.contact-details-section.phone-number-label`}
                    value={store.form.get("contactPhoneNumber")}
                    onChange={store.form.setter("contactPhoneNumber")}
                    disabled
                />
            </Stack>
        </FormFieldSet>
    )
})
