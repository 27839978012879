import { makeAutoObservable, reaction } from "mobx"
import React from "react"

import {
    avy_api_pkg_segment_Dimension,
    avy_api_pkg_segment_SegmentForSearch,
    avy_api_pkg_segment_SegmentType,
    SegmentsAdminService,
} from "src/api"
import { CustomSegmentsAdminService } from "src/api/_custom/services/SegmentsAdminService"

import { loads } from "src/channel/utils"
import { ISegmentItemListPrefixType } from "src/components/SegmentPicker/components/SegmentItemListPrefix/types"
import {
    SEGMENT_TYPE_GROUP_STANDARD,
    SEGMENT_WHITELIST_TYPES,
} from "src/components/SegmentPicker/constants"
import {
    ISegmentFilter,
    ISegmentMap,
    ISegmentPickerInitParams,
    ISegmentType,
    ISegmentTypeGroupMap,
    ISegmentTypeIndexMap,
    SegmentTypeGroup,
} from "src/components/SegmentPicker/type"
import { DEFAULT_ACCESS_GROUP } from "src/config"
import { createLoadingKeys } from "src/lib/loading"

import { Pagination } from "src/lib/pagination"
import { ISegmentsPublishGroupOption } from "src/components/SegmentPicker/components/SegmentsPropertyFilter/types"

/**
 * The scope of the segments used in the segment picker. Can be either `full`
 * or `limited`. Full means that all segments the user has access to is
 * available in the picker, limited means that only a subset is available.
 *
 * This setting affects empty states atm.
 */
export type IScope = "full" | "limited"

export class SegmentPickerStore {
    static Context = React.createContext<SegmentPickerStore | null>(null)
    static LoadingKeys = createLoadingKeys("init")

    private segmentListItemPrefixType?: ISegmentItemListPrefixType
    allowedPrefixSegmentTypes: ISegmentType[] = []

    /**
     * A map of segment ids and segments. This is a performance optimization
     * to improve speed of segment look ups used when navigating between
     * segments.
     */
    private segmentMap: ISegmentMap = {}

    /**
     * A map of segment type and their position in the hierarchy. Used when
     * sorting segments in the correct order.
     */
    private segmentTypeIndexMap: ISegmentTypeIndexMap = {}

    /**
     * Keeps track of navigation history. When the user navigates from one
     * segment to another an entry is pushed on this stack. When they click the
     * Back button the previous entry is applied to the filter.
     */
    private filterHistory: ISegmentFilter[] = []

    private segmentsPublishGroup: avy_api_pkg_segment_Dimension[] = []
    private _selectedSegmentsPublishGroup: ISegmentsPublishGroupOption[] = []
    private _publishGroupSelectedSegments: avy_api_pkg_segment_SegmentForSearch[] =
        []
    private _publishGroupVisible: boolean = false
    private _publishGroupLoading: boolean = false

    private get defaultFilter(): ISegmentFilter {
        return {
            segmentType: "legalentity",
            parent: null,
            query: null,
        }
    }

    private filter = this.defaultFilter
    private accessGroupId = DEFAULT_ACCESS_GROUP.id
    private _initialized = false

    private populatedSegmentTypes = new Set<ISegmentType>()
    segments: ISegmentForSearch[] = []

    get scope(): IScope {
        return this.accessGroupId === DEFAULT_ACCESS_GROUP.id
            ? "full"
            : "limited"
    }

    get segmentListItemPrefixTypeValue() {
        return this.segmentListItemPrefixType
    }

    /**
     * The segment types are split into group. Each group has a linear hierarchy
     * where each subsequent group is a child type of the one before.
     * NOTE - Features is a separate segment type group and is not a child type of its predecessor
     */
    private segmentTypeGroups: ISegmentTypeGroupMap = {
        [SegmentTypeGroup.Standard]: SEGMENT_TYPE_GROUP_STANDARD,
        [SegmentTypeGroup.Custom]: ["other"],
    }

    /**
     * Creates a map of Segment type => Index in hierarchy. This is for faster
     * hierarchy position look-ups.
     */
    private createSegmentTypeIndexMap() {
        this.segmentTypeIndexMap = {}

        this.segmentTypeGroups[SegmentTypeGroup.Standard].forEach((type, i) => {
            this.segmentTypeIndexMap[type] = i
        })
        this.segmentTypeGroups[SegmentTypeGroup.Custom].forEach((type, i) => {
            this.segmentTypeIndexMap[type] = i
        })
    }

    // List of types that should not be considered to be part of the type
    // hierarchy. These types cannot be navigated to when browsing in the
    // segment picker.
    private segmentTypesNotInHierarchy: ISegmentType[] = ["other"]

    segmentTypeGroup = SegmentTypeGroup.Standard

    /**
     * Returns the populated segment types for the currently selected group.
     * Some segment types in the standard group are whitelisted and will always
     * be visible even if they are empty.
     */
    get segmentTypes() {
        const types = this.segmentTypeGroups[this.segmentTypeGroup]

        // The standard group has a whitelist of segment types that always must be visible.
        if (this.segmentTypeGroup === SegmentTypeGroup.Standard) {
            const whitelist: ISegmentTypeGroupMap[SegmentTypeGroup.Standard] =
                SEGMENT_WHITELIST_TYPES

            return types.filter(
                (type) =>
                    (this.allowedSegmentTypes.length === 0 ||
                        this.allowedSegmentTypes.includes(type)) &&
                    (this.hasSegmentsOfType(type) || whitelist.includes(type)),
            )
        }

        return types.filter((type) => this.hasSegmentsOfType(type))
    }

    selectedSegmentIds = new Set<number>()

    allowedSegmentTypes = new Array<ISegmentForSearch["type"]>()

    get segmentType() {
        return this.filter.segmentType
    }

    get parent() {
        return this.filter.parent
    }

    get query() {
        return this.filter.query
    }

    get initialized() {
        return this._initialized
    }

    get selectedSegmentsPublishGroup() {
        return this._selectedSegmentsPublishGroup
    }

    get publishGroupVisible() {
        return this._publishGroupVisible
    }

    get publishGroupLoading() {
        return this._publishGroupLoading
    }

    getAncestorAtIndex(segmentId: number, ancestorIndex: number) {
        const ancestors = this.segmentMap[segmentId]?.labelPathSlice ?? []
        if (ancestorIndex > ancestors.length - 1) {
            return null
        }
        return ancestors[ancestorIndex]
    }

    typeIsLowerThanOrEqualToParent(type: ISegmentType) {
        if (this.filter.parent == null) {
            return false
        }

        return (
            this.segmentTypes.indexOf(this.filter.parent.type) >=
            this.segmentTypes.indexOf(type)
        )
    }

    private _segmentIdsNoRelatedAccessGroup: number[] = []

    get segmentIdsNoRelatedAccessGroup() {
        return this._segmentIdsNoRelatedAccessGroup
    }

    setSegmentIdsNoRelatedAccessGroup = (ids: number[]): void => {
        this._segmentIdsNoRelatedAccessGroup = ids
    }

    /**
     * Paginator of the selected segments
     */
    selectedSegments = new Pagination(
        (query) => {
            const pageStart = (query.page - 1) * query.pageSize

            const publishGroupSegmentsMap = new Map(
                (this._publishGroupSelectedSegments ?? []).map(
                    (segment: avy_api_pkg_segment_SegmentForSearch) => [
                        segment.id,
                        segment,
                    ],
                ),
            )

            const sourceItems = this.segments
                .filter((segment) => this.selectedSegmentIds.has(segment.id))
                .filter((segment) =>
                    this.segmentMatchesQuery(segment, query.search),
                )
                .map((segment) => {
                    const segmentCopy = { ...segment }

                    if (publishGroupSegmentsMap.has(segment.id)) {
                        const publishGroupSegment = publishGroupSegmentsMap.get(
                            segment.id,
                        )
                        if (
                            publishGroupSegment != null &&
                            publishGroupSegment.tenant_count !== undefined
                        ) {
                            segmentCopy.tenantCount =
                                publishGroupSegment.tenant_count
                        }
                    }

                    return segmentCopy
                })

            const sourceItemIds = new Set(sourceItems.map((item) => item.id))
            this.setSegmentIdsNoRelatedAccessGroup(
                [...this.selectedSegmentIds].filter(
                    (id) => !sourceItemIds.has(id),
                ),
            )

            const items = sourceItems.slice(
                pageStart,
                pageStart + query.pageSize,
            )

            return { items, sourceItems, count: sourceItems.length }
        },
        { static: true },
    )

    /**
     * Paginator of the available segments
     */
    availableSegments = new Pagination(
        (query) => {
            const pageStart = (query.page - 1) * query.pageSize

            const sourceItems = this.segments
                .filter((segment) => this.filter.segmentType === segment.type)
                .filter((segment) =>
                    this.segmentMatchesQuery(segment, this.filter.query),
                )
                .filter(
                    (segment) =>
                        this.filter.parent === null ||
                        segment.labelPathSlice?.some(
                            (e) => e === this.filter.parent?.id,
                        ),
                )

            const items = sourceItems.slice(
                pageStart,
                pageStart + query.pageSize,
            )

            return { items, sourceItems, count: sourceItems.length }
        },
        { static: true },
    )

    constructor() {
        makeAutoObservable(this)
        this.createSegmentTypeIndexMap()
        // TODO: dispose
        reaction(
            () => this.selectedSegmentIds.size,
            async () => {
                // TODO: reload instead
                await this.loadSegmentsBasePublishGroup()
                await this.selectedSegments.loadInitialPage()
            },
        )
        // TODO: dispose
        reaction(
            () => this.segments,
            async () => {
                // TODO: reload instead
                await this.availableSegments.loadInitialPage()
                await this.selectedSegments.loadInitialPage()
            },
        )
    }

    /**
     *
     * @param accessGroupId
     * @param initialSegmentIds
     * @param allowedSegmentTypes array of allowed segment types that will be shown in the picker.
     * @param publishingLevel This comes from API, and a concept of lowest level in hierarchy is introduced.
     * All types higher than the publishingLevel are allowed, including the publishingLevel. If nothing is returned, all types are allowed.
     * NOTE: publishingLevel is a new concept we are trying and wish to widely use it in future.
     */
    @loads(() => SegmentPickerStore.LoadingKeys.init)
    async init(params: ISegmentPickerInitParams) {
        this.setSegmentListItemPrefixType(params.segmentListItemPrefixType)
        this.allowedPrefixSegmentTypes = params.allowedPrefixSegmentTypes ?? []
        try {
            this.setAccessGroupId(params.accessGroupId)
            this.setInitialSegmentIds(params.initialSegmentIds)
            this.setAllowedSegmentTypes(
                this.getAllowedSegmentTypes(
                    params.allowedSegmentTypes,
                    params.publishingLevel,
                ),
            )
            // set segment type to the first available allowedSegmentType. This overrides the defaultFilter segment type
            const segment = this.allowedSegmentTypes.at(0)
            segment !== undefined && this.setSegmentType(segment)
            await this.loadSegments()
            await this.selectedSegments.loadInitialPage()
            await this.availableSegments.loadInitialPage()
            await this.loadSegmentsPublishGroup()
        } finally {
            this.setInitialized()
        }
    }

    // allowedSegmentTypes are given precedence over publishingLevel
    private getAllowedSegmentTypes(
        allowedSegmentTypes?: ISegmentForSearch["type"][],
        publishingLevel?: avy_api_pkg_segment_SegmentType,
    ) {
        if (allowedSegmentTypes !== undefined) {
            return allowedSegmentTypes
        }

        if (publishingLevel !== undefined) {
            return Object.values(this.segmentTypeGroups)
                .flatMap((types) => types)
                .filter(
                    (type) =>
                        this.segmentTypeIndexMap[type] <=
                        this.segmentTypeIndexMap[publishingLevel],
                )
        }

        return Array<ISegmentForSearch["type"]>()
    }

    deselectSegment(segment: ISegmentForSearch) {
        this.selectedSegmentIds.delete(segment.id)
        void this.selectedSegments.loadInitialPage()
    }

    selectOneSegment(segment: ISegmentForSearch) {
        this.selectedSegmentIds.clear()
        this.selectedSegmentIds.add(segment.id)
        void this.selectedSegments.loadInitialPage()
    }

    selectSegment(segment: ISegmentForSearch) {
        this.selectedSegmentIds.add(segment.id)
    }

    hasSelectedSegment(segment: ISegmentForSearch) {
        return this.selectedSegmentIds.has(segment.id)
    }

    handleRadioSelection(segment: ISegmentForSearch) {
        if (this.hasSelectedSegment(segment)) {
            this.deselectSegment(segment)
        } else {
            this.selectOneSegment(segment)
        }
    }

    hasSelectedAncestorSegment(segment: ISegmentForSearch) {
        return (
            segment.labelPathSlice?.some((x) =>
                this.selectedSegmentIds.has(x),
            ) === true
        )
    }

    getSegmentWithId(id: number) {
        return this.segmentMap[id] ?? null
    }

    deselectSegmentsFromSource() {
        this.availableSegments.sourceItems.forEach((item) =>
            this.selectedSegmentIds.delete(item.id),
        )
    }

    selectSegmentsFromSource() {
        const segmentIds = this.availableSegments.sourceItems.map(
            (item) => item.id,
        )

        this.selectedSegmentIds = new Set([
            ...this.selectedSegmentIds,
            ...segmentIds,
        ])
    }

    hasSegmentsInGroup(segmentTypeGroup: SegmentTypeGroup) {
        for (const type of this.segmentTypeGroups[segmentTypeGroup]) {
            if (this.hasSegmentsOfType(type)) {
                return true
            }
        }
        return false
    }

    hasSegmentTypesInAllowedSegments(segmentTypeGroup: SegmentTypeGroup) {
        if (this.allowedSegmentTypes.length === 0) {
            return true
        }

        for (const type of this.segmentTypeGroups[segmentTypeGroup]) {
            if (this.allowedSegmentTypes.includes(type)) {
                return true
            }
        }
        return false
    }

    hasSegmentsOfType(type: ISegmentType) {
        return this.populatedSegmentTypes.has(type)
    }

    setSegmentTypeGroup(segmentTypeGroup: SegmentTypeGroup) {
        if (this.segmentTypeGroup !== segmentTypeGroup) {
            this.segmentTypeGroup = segmentTypeGroup

            this.setFilter({
                parent: null,
                segmentType: this.getFirstSegmentTypeInGroup(),
            })
        }
    }

    setNonStandardSegmentTypeGroup(segmentTypeGroup: SegmentTypeGroup) {
        this.segmentTypeGroup = segmentTypeGroup
    }

    setSegmentType(segmentType: ISegmentType) {
        this.setFilter({ segmentType })
    }

    setParent(parent: ISegmentForSearch | null) {
        const segmentType =
            parent != null
                ? this.getChildSegmentType(parent)
                : this.filter.segmentType

        this.setNextFilter({
            parent,
            segmentType: segmentType ?? this.getFirstSegmentTypeInGroup(),
            query: null,
        })
    }

    setParentId(id: number | null) {
        if (id == null) {
            this.setParent(null)
        } else {
            const parent = this.segmentMap[id] ?? null
            this.setParent(parent)
        }
    }

    setQuery(query: string | null) {
        this.setFilter({ query })
    }

    setSegmentListItemPrefixType = (type?: ISegmentItemListPrefixType) => {
        this.segmentListItemPrefixType = type
    }

    get availableSegmentsPageStatus() {
        const selectedSegmentsInSource =
            this.availableSegments.sourceItems.filter((segment) =>
                this.selectedSegmentIds.has(segment.id),
            )

        if (selectedSegmentsInSource.length > 0) {
            if (
                selectedSegmentsInSource.length ===
                this.availableSegments.sourceItems.length
            ) {
                return "all"
            } else {
                return "partial"
            }
        }
        return "none"
    }

    setPreviousFilter() {
        const previousFilter = this.filterHistory.pop()
        if (previousFilter != null) {
            this.setFilter(previousFilter)
        }
    }

    resetFilter() {
        this.setFilter(this.defaultFilter)
    }

    setSegmentsPublishGroup(publishGroup: avy_api_pkg_segment_Dimension[]) {
        this.segmentsPublishGroup = publishGroup
    }

    setPublishGroupVisible(visible: boolean) {
        this._publishGroupVisible = visible
    }

    setPublishGroupLoading(loading: boolean) {
        this._publishGroupLoading = loading
    }

    public hasChildSegmentType(segment: ISegmentForSearch) {
        return Boolean(this.getChildSegmentType(segment))
    }

    private getChildSegmentType(
        parent: ISegmentForSearch,
    ): ISegmentType | null {
        const parentTypeIndex = this.segmentTypes.indexOf(parent.type)
        const childTypeIndex = parentTypeIndex + 1

        if (
            childTypeIndex < this.segmentTypes.length &&
            !this.segmentTypesNotInHierarchy.includes(
                this.segmentTypes[childTypeIndex],
            )
        ) {
            return this.segmentTypes[childTypeIndex]
        }

        return null
    }

    private getFirstSegmentTypeInGroup(): ISegmentType {
        const groups = this.segmentTypeGroups[this.segmentTypeGroup]
        for (const index in groups) {
            if (this.hasSegmentsOfType(groups[index])) {
                return groups[index]
            }
        }

        throw new Error(
            `No segment type found in group ${this.segmentTypeGroup}. This should not be possible.`,
        )
    }

    private setNextFilter(filter: ISegmentFilter) {
        // Only save changes to parent in filter history
        if (this.filter.parent?.id !== filter.parent?.id) {
            this.filterHistory.push(this.filter)
        }

        this.setFilter(filter)
    }

    private setFilter(filter: Partial<ISegmentFilter>) {
        this.filter = { ...this.filter, ...filter }
    }

    private setAccessGroupId(accessGroupId: number | null) {
        if (accessGroupId != null) {
            this.accessGroupId = accessGroupId
        } else {
            this.accessGroupId = DEFAULT_ACCESS_GROUP.id
        }
    }

    private setSegments(segments: ISegmentForSearch[]) {
        this.segments = segments
        this.normalizeSegmentTypes()
        this.setPopulatedSegmentTypes()
        this.createSegmentMap()
    }

    public setInitialSegmentIds(selectedSegmentIds: number[]) {
        this.selectedSegmentIds = new Set(selectedSegmentIds)
    }

    private setInitialized() {
        this._initialized = true
    }

    private setAllowedSegmentTypes(segmentTypes: ISegmentForSearch["type"][]) {
        this.allowedSegmentTypes = segmentTypes
    }

    /**
     * Normalizes the segment types in the segment list. If a segment has a
     * type that isn't supported {@see this.segmentTypeGroups} its type will be
     * set to 'other'.
     *
     * 'other' is a special segment type that contains all unsupported
     * segments. It's shown last in the custom segment tab.
     */
    private normalizeSegmentTypes() {
        const supportedSegmentTypes = new Set(
            Object.values(this.segmentTypeGroups).flatMap((types) => types),
        )

        this.segments.forEach((segment) => {
            if (!supportedSegmentTypes.has(segment.type)) {
                segment.type = "other"
            }
        })
    }

    /**
     * Marks segment types as having at least one segment. Only segment types
     * with segments will be visible in the UI.
     */
    private setPopulatedSegmentTypes() {
        const populatedSegmentTypes = new Set<ISegmentType>()

        this.segments.forEach((segment) => {
            populatedSegmentTypes.add(segment.type)
        })

        this.populatedSegmentTypes = populatedSegmentTypes
    }

    /**
     * Creates an Segment id -> Segment map for faster segment look-ups.
     */
    private createSegmentMap() {
        this.segmentMap = {}
        this.segments.forEach((segment) => {
            this.segmentMap[segment.id] = segment
        })
    }

    private segmentMatchesQuery(
        segment: ISegmentForSearch,
        query: string | null,
    ) {
        if (query == null) {
            return true
        }

        const lowerCaseQuery = query.toLowerCase()
        const lowerCaseName = segment.name.toLowerCase()
        const lowerCasePath = segment.path.toLowerCase()

        return (
            lowerCaseName.includes(lowerCaseQuery) ||
            lowerCasePath.includes(lowerCaseQuery)
        )
    }

    /**
     * Reloads all segment data and updates the paginators
     */
    async reload() {
        await this.loadSegments()
        await this.availableSegments.reload()
        await this.selectedSegments.reload()
    }

    async loadSegments() {
        const segments =
            await CustomSegmentsAdminService.getV1AdminSegmentSearch({
                accessGroupId:
                    this.accessGroupId !== DEFAULT_ACCESS_GROUP.id
                        ? String(this.accessGroupId)
                        : undefined,
            })

        const transformed = segments.map(this.transformSegment)
        this.sortSegments(transformed)

        this.setSegments(transformed)
    }

    /**
     * Sorts segments in hierarchical order.
     *
     * The segments must be in this order for breadcrumb navigation to work properly.
     *
     * @param segments List of segments from the api
     */
    private sortSegments(segments: ISegmentForSearch[]) {
        segments.sort(
            (a, b) =>
                this.segmentTypeIndexMap[a.type] -
                this.segmentTypeIndexMap[b.type],
        )
    }

    /**
     * Maps a segment from the api to our internal segment data structure.
     *
     * @param segment Segment from the api
     * @returns Same segment in our internal data structure
     */
    private transformSegment = (
        segment: avy_api_pkg_segment_SegmentForSearch,
    ): ISegmentForSearch => ({
        id: segment.id ?? -1,
        descendants: segment.descendants ?? [],
        name: segment.name ?? "",
        path: segment.path ?? "",
        label_path: segment.label_path ?? "",
        labelPathSlice:
            segment.label_path
                ?.split(".")
                .map((ascendant) => Number(ascendant)) ?? [],
        tenantCount: segment.tenant_count ?? 0,
        type: segment.type as ISegmentType,
    })

    setSegmentsPublishGroupByType(
        type?: string,
    ): ISegmentsPublishGroupOption[] {
        return type != null
            ? this.segmentsPublishGroup
                  .filter((item) => item.type === type)
                  .map((item) => ({
                      id: Number(item.id),
                      title: item.name ?? "",
                  }))
            : this.segmentsPublishGroup.map((item) => ({
                  id: Number(item.id),
                  title: item.name ?? "",
              }))
    }

    setSelectedSegmentsPublishGroup = async (
        filterSegmentsPublishGroup: ISegmentsPublishGroupOption[],
    ) => {
        this._selectedSegmentsPublishGroup = filterSegmentsPublishGroup
        await this.loadSegmentsBasePublishGroup()
        await this.selectedSegments.loadInitialPage()
    }

    async loadSegmentsPublishGroup() {
        try {
            const publishGroup =
                await SegmentsAdminService.getV1AdminDimension()
            this.setSegmentsPublishGroup(publishGroup)
        } catch (error) {
            this.setSegmentsPublishGroup([])
        }
    }

    async loadSegmentsBasePublishGroup() {
        if (
            [...this.selectedSegmentIds].length > 0 &&
            this._selectedSegmentsPublishGroup.length > 0
        ) {
            try {
                this.setPublishGroupLoading(true)
                const ids =
                    this._selectedSegmentsPublishGroup
                        ?.map((item: ISegmentsPublishGroupOption) =>
                            Number(item.id),
                        )
                        .filter((id) => id !== undefined) ?? []

                const publishGroupSelectedSegments =
                    await SegmentsAdminService.postV1AdminSegmentSelectedSegments(
                        {
                            request: {
                                dimension_ids: ids,
                                segment_ids: [...this.selectedSegmentIds],
                            },
                        },
                    )
                this._publishGroupSelectedSegments =
                    publishGroupSelectedSegments
                this.setPublishGroupLoading(false)
            } catch (error) {
                this.setPublishGroupLoading(false)
                this._publishGroupSelectedSegments = []
            }
        } else {
            this._publishGroupSelectedSegments = []
        }
    }
}
