import { observer } from "mobx-react"
import { Stack, Typography, Tooltip } from "@mui/material"
import { useMemo, useCallback } from "react"
import { useNavigate } from "react-router"

import { Icon } from "src/components/icons"
import { FlagsMap } from "src/views/community-overview/types"
import useWarningFlags from "src/views/community-overview/hooks/useCommunityFlagsLable"
import { useStore } from "src/store/lib/useStore"
import { CommunitiesOverviewViewStore } from "src/views/community-overview/store"

export const WarningFlagsHeader = observer(() => {
    const warningFlags: FlagsMap = useWarningFlags() // Add the index signature
    const store = useStore(CommunitiesOverviewViewStore)
    const navigate = useNavigate()

    const onFlagsChipClick = useCallback(
        (flag: string) =>
            navigate(
                `/community/posts-and-comments?${store.generatePostCommentUrl(
                    flag,
                )}`,
            ),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    )

    const warningTypesIteration = useMemo(
        () =>
            Object.entries(warningFlags).map(([key, value], index) => (
                <Tooltip
                    key={index}
                    title={warningFlags[key]?.label ?? ""}
                    placement="top"
                    arrow
                    disableFocusListener
                    disableTouchListener
                >
                    <Stack
                        key={index}
                        direction="row"
                        alignItems="center"
                        paddingX={1.5}
                        paddingY={0.4}
                        borderRadius={1}
                        bgcolor={warningFlags[key].lightColor}
                        border={1}
                        borderColor={warningFlags[key].color}
                        spacing={0.5}
                        onClick={() => value.value > 0 && onFlagsChipClick(key)}
                        sx={() =>
                            value.value > 0 ? { cursor: "pointer" } : null
                        }
                    >
                        <Stack direction="row" alignItems="center">
                            <Icon icon={value.icon} color={value.color} />
                        </Stack>
                        <Typography variant="subtitle1" color={value.color}>
                            {value.value}
                        </Typography>
                    </Stack>
                </Tooltip>
            )),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [warningFlags],
    )

    return (
        <Stack direction="row" spacing={2} flex={1} justifyContent="end">
            {warningTypesIteration}
        </Stack>
    )
})
