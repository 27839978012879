import { t } from "@lingui/macro"
import { Cancel } from "@mui/icons-material"
import {
    Box,
    Chip,
    FormControl,
    FormHelperText,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    styled,
    SxProps,
    Theme,
} from "@mui/material"
import { observer } from "mobx-react"
import { useCallback, useMemo } from "react"

import { useUniqueId } from "src/lib/unique-id"
import { CommunityPostsAndCommentsEditStore } from "src/modals/community-posts-and-comments-edit/store"
import { useStore } from "src/store/lib/useStore"

const ValuesWrap = styled(Box)({
    display: "flex",
    flexWrap: "wrap",
    gap: "8px",
})

export const CommunitySelect = observer(() => {
    const store = useStore(CommunityPostsAndCommentsEditStore)
    const communitySelectId = useUniqueId()

    const communityLabels = useMemo(() => {
        return Object.fromEntries(
            store.communities.map((community) => [
                community.id,
                community.name,
            ]),
        )
    }, [store.communities])

    const handleCommunityChange = useCallback(
        (event: SelectChangeEvent<number[]>) => {
            const value = event.target.value
            const ids =
                typeof value === "string"
                    ? value.split(",").map((x) => Number(x))
                    : value
            store.form.set("communityIds", ids)
        },
        [store],
    )

    const valueRenderer = useCallback(
        (values: number[]) => (
            <ValuesWrap>
                {values.map((value) =>
                    store.isEditing ? (
                        <Chip key={value} label={communityLabels[value]} />
                    ) : (
                        <Chip
                            key={value}
                            label={communityLabels[value]}
                            clickable
                            deleteIcon={
                                <Cancel
                                    onMouseDown={(event) =>
                                        event.stopPropagation()
                                    }
                                />
                            }
                            onDelete={() => {
                                store.deselectCommunity(value)
                            }}
                        />
                    ),
                )}
            </ValuesWrap>
        ),
        [store, communityLabels],
    )

    // Apply custom padding on the select element to make sure the input height
    // is kept at 56px.
    const sx: SxProps<Theme> =
        store.form.get("communityIds").length > 0
            ? {
                  "& .MuiSelect-select": {
                      paddingTop: "12px",
                      paddingBottom: "12px",
                  },
              }
            : {}

    return (
        <FormControl>
            <InputLabel id={communitySelectId}>
                {t`community-post-detail-modal.settings-section.community-label`}
            </InputLabel>
            <Select
                labelId={communitySelectId}
                label={t`community-post-detail-modal.settings-section.community-label`}
                renderValue={valueRenderer}
                value={store.form.get("communityIds")}
                onChange={handleCommunityChange}
                error={Boolean(store.form.error("communityIds"))}
                sx={sx}
                disabled={
                    store.isEditing || store.form.get("accessType") === "READ"
                }
                multiple
            >
                {store.communities.map((community) => (
                    <MenuItem key={community.id} value={community.id}>
                        {community.name}
                    </MenuItem>
                ))}
            </Select>
            {Boolean(store.form.error("communityIds")) && (
                <FormHelperText error={true}>
                    {store.form.error("communityIds")}
                </FormHelperText>
            )}
        </FormControl>
    )
})
