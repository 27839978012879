import { List, ListItem } from "@mui/material"
import { observer } from "mobx-react"

import { ConfigurableDynamicFormFieldsUnhandledElementUi } from "src/components/ConfigurableDynamicFormFields/components/UnhandledUIElement"
import { ConfigurableDynamicFormFieldsTextField } from "src/components/ConfigurableDynamicFormFields/formFields/text-field"
import { IConfigurableDynamicFormFieldsTextListProps } from "src/components/ConfigurableDynamicFormFields/types/form-field"

export const ConfigurableDynamicFormFieldsTextList = observer(
    ({ field, sectionName }: IConfigurableDynamicFormFieldsTextListProps) => {
        if (field.length === undefined) {
            return <ConfigurableDynamicFormFieldsUnhandledElementUi />
        }

        return (
            <List component="ol" sx={{ marginLeft: 4, listStyle: "decimal" }}>
                {Array.from({ length: field.length }, (_, index) => (
                    <ListItem sx={{ display: "list-item" }} key={index}>
                        <ConfigurableDynamicFormFieldsTextField
                            field={field}
                            sectionName={sectionName}
                            index={index}
                            variant="default"
                        />
                    </ListItem>
                ))}
            </List>
        )
    },
)
