import { observer } from "mobx-react"
import { t } from "@lingui/macro"
import { Send, Photo } from "@material-ui/icons"
import { Button, Stack, styled } from "@mui/material"
import React, { useCallback, useEffect, useRef } from "react"

import { useStore } from "src/store/lib/useStore"
import { CommunityPostsAndCommentsStore } from "src/modals/community-posts-and-comments/store"
import { GlobalStore } from "src/store"

const SquareButton = styled(Button)({
    padding: "6px 8px",
    minWidth: "auto",
})

const UploadButton = styled(SquareButton)(({ theme }) => ({
    borderColor: theme.palette.grey[500],
    background: theme.palette.common.white,
    color: theme.palette.grey[700],
}))

export const Controls = observer(() => {
    const gstore = useStore(GlobalStore)
    const store = useStore(CommunityPostsAndCommentsStore)

    const uploadInputRef = useRef<HTMLInputElement | null>(null)

    const previewImageUrls = useRef<string[]>([])

    const isSubmitting = gstore.loading.is(
        CommunityPostsAndCommentsStore.LoadingKeys["submit-comment"],
    )

    useEffect(() => {
        const urls = previewImageUrls.current
        return () => {
            for (const url of urls) {
                URL.revokeObjectURL(url)
            }
        }
    }, [])

    const handleFileChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            const files = event.target.files
            if (files != null && files.length > 0) {
                const url = URL.createObjectURL(files[0])
                store.appendImage({
                    file: files[0],
                    url,
                })
                previewImageUrls.current.push(url)
            }
        },
        [store],
    )

    return (
        <Stack direction="row" spacing={1} justifyContent="space-between">
            <Stack direction="row" spacing={1} sx={{ width: "100%" }}>
                <UploadButton
                    title={t`view-community-post-modal.settings-section.attach-image-button`}
                    type="button"
                    color="inherit"
                    variant="outlined"
                    onClick={() => {
                        uploadInputRef.current?.click()
                    }}
                    disabled={
                        isSubmitting ||
                        !store.canAppendImage() ||
                        store.post.accessType === "READ"
                    }
                >
                    <Photo />
                </UploadButton>
                <input
                    ref={uploadInputRef}
                    type="file"
                    style={{ display: "none" }}
                    onChange={handleFileChange}
                    accept="image/jpg,image/jpeg,image/png"
                />
            </Stack>
            <SquareButton
                title={t`view-community-post-modal.settings-section.submit-comment-button`}
                type="submit"
                color="info"
                variant="contained"
                disabled={
                    isSubmitting ||
                    !store.canSubmitComment() ||
                    store.post.accessType === "READ"
                }
            >
                <Send />
            </SquareButton>
        </Stack>
    )
})
