import { Typography, styled } from "@mui/material"

export const ItemContainer = styled("div")(() => ({
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    maxWidth: "100%",
    alignItems: "center",
}))

export const AdminItemContainer = styled("div")(({ theme }) => ({
    display: "flex",
    [theme.breakpoints.up("sm")]: {
        flexDirection: "row",
        justifyContent: "space-between",
    },
    [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
        alignItems: "flex-start",
    },
    maxWidth: "100%",
}))

export const CardItemContainer = styled("div")(() => ({
    justifyContent: "space-between",
    maxWidth: "100%",
}))

export const ClickableCardItemContainer = styled(CardItemContainer)(
    ({ theme }) => ({
        cursor: "pointer",
        "& .tenant-id": {
            transition: theme.transitions.create(["color", "text-decoration"], {
                duration: theme.transitions.duration.shortest,
            }),
        },
        "&:hover": {
            "& .tenant-id": {
                textDecoration: "underline",
                color: theme.palette.primary.main,
            },
        },
    }),
)

export const CardContainer = styled("div")(
    ({ windowWidth }: { windowWidth: boolean }) => ({
        marginLeft: windowWidth ? 8 : 0,
        marginRight: windowWidth ? 8 : 0,
        maxWidth: "420px",
        minWidth: "275px",
        borderWidth: 1,
        padding: "16px",
        backgroundColor: "white",
    }),
)
export const IconContainer = styled("span")(({ theme }) => ({
    display: "flex",
    flexDirection: "row",
    [theme.breakpoints.up("sm")]: {
        flex: 0.12,
        justifyContent: "space-between",
    },
}))
export const IconBackground = styled("span")(() => ({
    width: "25px",
    height: "25px",
    backgroundColor: "rgba(0, 0, 0, 0.08)",
    borderRadius: "50%",
    padding: "5px",
}))
export const FeedbackContainer = styled("div")(
    ({ windowWidth }: { windowWidth: boolean }) => ({
        marginLeft: windowWidth ? 8 : 0,
        marginRight: windowWidth ? 8 : 0,
        maxWidth: "100%",
        borderWidth: 1,
        padding: "16px",
        backgroundColor: "white",
        alignItems: "center",
    }),
)

export const TextContainer = styled("div")(() => ({
    display: "flex",
    flexDirection: "column",
}))

export const FeedbackTextContainer = styled("div")(({ theme }) => ({
    display: "flex",
    [theme.breakpoints.up("sm")]: {
        flex: 0.4,
        alignSelf: "center",
    },
}))

export const SelectContainer = styled("div")(() => ({
    flex: 0.4,
}))

export const Separator = styled("div")(() => ({
    marginTop: "20px",
}))
export const Typography400 = styled(Typography)(() => ({
    fontFamily: "Roboto",
    fontSize: "14px",
    fontWeight: "400",
}))
export const Typography500 = styled(Typography)(() => ({
    fontFamily: "Roboto",
    fontSize: "14px",
    fontWeight: "500",
    color: "#333333",
}))
