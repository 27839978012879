import { t } from "@lingui/macro"

import { CommunityAdminService } from "src/api"
import { Channel } from "src/channel"
import { reportSuccess, reportUnhandledApiError } from "src/lib/report"

const sendChannelUpdate = () =>
    Channel.send({
        name: "repository/updated",
        payload: {
            repository: "community-posts-and-comments",
            action: "update",
        },
    })

export const handledPost = async (
    id: number | undefined,
    isHandled: boolean,
) => {
    if (id === undefined) return

    try {
        isHandled
            ? await CommunityAdminService.postV1AdminCommunityPostUnhandle({
                  postId: id,
              })
            : await CommunityAdminService.postV1AdminCommunityPostHandle({
                  postId: id,
              })

        sendChannelUpdate()
        reportSuccess(t`global.success`)
    } catch (e) {
        reportUnhandledApiError(e)
    }
}

export const handledComment = async (
    id: number | undefined,
    isHandled: boolean,
) => {
    if (id === undefined) return

    try {
        isHandled
            ? await CommunityAdminService.postV1AdminCommunityCommentUnhandle({
                  commentId: id,
              })
            : await CommunityAdminService.postV1AdminCommunityCommentHandle({
                  commentId: id,
              })

        sendChannelUpdate()
        reportSuccess(t`global.success`)
    } catch (e) {
        reportUnhandledApiError(e)
    }
}

export const handledReportPost = async (id: number | undefined) => {
    if (id === undefined) return

    try {
        await CommunityAdminService.postV1AdminCommunityPostReport({
            postId: id,
        })

        sendChannelUpdate()
        reportSuccess(t`global.success`)
    } catch (e) {
        reportUnhandledApiError(e)
    }
}

export const handledReportComment = async (id: number | undefined) => {
    if (id === undefined) return

    try {
        await CommunityAdminService.postV1AdminCommunityCommentReport({
            commentId: id,
        })

        sendChannelUpdate()
        reportSuccess(t`global.success`)
    } catch (e) {
        reportUnhandledApiError(e)
    }
}
