/* eslint-disable require-await */
import { t } from "@lingui/macro"
import React from "react"

import { ContactFormV2AdminService } from "src/api"

import { Channel } from "src/channel"
import { loads } from "src/channel/utils"
import { DEFAULT_ACCESS_GROUP } from "src/config"

import { createLoadingKeys } from "src/lib/loading"
import { Pagination } from "src/lib/pagination"
import { IFormItem } from "src/views/contact-form-formsList/type"

export class FormsViewStore implements IDisposable {
    static Context = React.createContext<FormsViewStore | null>(null)
    static LoadingKeys = createLoadingKeys("init", "loading")
    private _accessGroupId: number = DEFAULT_ACCESS_GROUP.id
    private repositoryUpdatesListenerDisposer?: () => void

    formList = new Pagination(
        async (query) => {
            const response =
                await ContactFormV2AdminService.getV2AdminContactFormType({
                    pageNumber: query.page,
                    pageSize: query.pageSize,
                    accessGroupId: this.getAccessGroupId(),
                })

            const items: IFormItem[] = (response.result ?? []).map((item) => ({
                id: item.form_type_id ?? -1,
                form_id: String(item.form_type_id) ?? "",
                name: item.name ?? "",
                type: item.output ?? "-",
                published_to: `${String(
                    item.published_in?.length,
                )} ${t`contact-form-forms-view.published.value`}`,
            }))

            return {
                items,
                count: response.total_count ?? 0,
            }
        },
        {
            loadingKey: FormsViewStore.LoadingKeys.loading,
        },
    )

    dispose() {
        this.repositoryUpdatesListenerDisposer?.()
    }
    private setAccessGroupId(accessGroupId: number) {
        this._accessGroupId = accessGroupId
    }
    private getAccessGroupId() {
        return this._accessGroupId !== DEFAULT_ACCESS_GROUP.id
            ? this._accessGroupId
            : undefined
    }

    @loads(() => FormsViewStore.LoadingKeys.init)
    async init(accessGroupId: number) {
        this.setAccessGroupId(accessGroupId)
        this.listenToFormListRepositoryUpdates()
        await this.formList.loadInitialPage()
    }

    private listenToFormListRepositoryUpdates() {
        this.repositoryUpdatesListenerDisposer = Channel.addListener(
            async (event) => {
                if (
                    event.name === "repository/updated" &&
                    event.payload.repository === "contact-forms-v2"
                ) {
                    await this.formList.reload()
                }
            },
        )
    }
}
