import { t } from "@lingui/macro"
import { observer } from "mobx-react"
import { useCallback, useEffect, useMemo } from "react"

import { ChatbotDispatchesStore } from "./store"

import { ListPage } from "src/components/ListPage"
import { SegmentPickerButton } from "src/components/SegmentPickerButton"
import { DataGridProTable } from "src/components/Table/DataGridPro"
import { DEFAULT_ACCESS_GROUP } from "src/config"
import {
    getFilterModelForRepository,
    getSortModelForRepository,
} from "src/lib/data-grid-pro"
import { ChatbotDispatchesModal } from "src/modals/chatbot-dispatches"
import { GlobalStore } from "src/store"
import { StoreProvider } from "src/store/lib/StoreProvider"
import { useStore } from "src/store/lib/useStore"
import { Repository } from "src/types/channel"
import { FilterModel, IColumn, SortModel } from "src/types/data-grid-pro"
import { IChatbotDispatch } from "src/types/chatbot-dispatches/chatbotDispatch"
import { ConfirmModal } from "src/modals/confirm"
import { AccessTypeHeader } from "src/components/AccessTypeHeader"

const repository: Repository = "chatbot-dispatches"

const View = observer(() => {
    const store = useStore(ChatbotDispatchesStore)
    const globalStore = useStore(GlobalStore)

    useEffect(() => {
        ;(async () => {
            if (globalStore.session.accessGroup.id !== undefined) {
                await store.init(
                    globalStore.session.accessGroup.id,
                    advanceQuery,
                )
            } else {
                await store.init(DEFAULT_ACCESS_GROUP.id, advanceQuery)
            }
        })()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [store, globalStore.session.accessGroup])

    const handleNewDispatchClick = useCallback(() => {
        globalStore.modals.open(() => <ChatbotDispatchesModal />)
    }, [globalStore.modals])

    const header = useMemo(
        () => ({
            header: t`chatbot-dispatch-view.header`,
            breadcrumbs: [
                t`navigation.chatbot`,
                t`chatbot-dispatch-view.header`,
            ],
            createOptions: {
                item: t`chatbot-dispatch-view.create-new`,
                onClick: handleNewDispatchClick,
            },
        }),
        [handleNewDispatchClick],
    )

    const handleSegmentChange = useCallback(
        (segments: number[]) => store.loadSegments(segments),
        [store],
    )

    const filter = useMemo(
        () => ({
            actions: (
                <>
                    <SegmentPickerButton
                        value={store.segments}
                        onChange={handleSegmentChange}
                    />
                </>
            ),
        }),
        [handleSegmentChange, store.segments],
    )

    const advanceQuery = useMemo(() => {
        const filter = getFilterModelForRepository(
            repository,
            globalStore.session.dataGridFilterModel,
        )
        const sort = getSortModelForRepository(
            repository,
            globalStore.session.dataGridSortModel,
        )
        return { sort, filter }
    }, [
        globalStore.session.dataGridSortModel,
        globalStore.session.dataGridFilterModel,
    ])

    const handleFilterChange = useCallback(
        async (model: FilterModel) => {
            const sort = getSortModelForRepository(
                repository,
                globalStore.session.dataGridSortModel,
            )

            await store.query({ sort, filter: model })
        },
        [store, globalStore.session.dataGridSortModel],
    )

    const handleSortChange = useCallback(
        async (model: SortModel) => {
            const filter = getFilterModelForRepository(
                repository,
                globalStore.session.dataGridFilterModel,
            )

            await store.query({ filter, sort: model })
        },
        [store, globalStore.session.dataGridFilterModel],
    )

    const columns: IColumn<IChatbotDispatch>[] = [
        {
            field: "dispatch_id",
            headerName: t`chatbot-dispatches-list-view.dispatch-id`,
            renderCell: (params) => params.value,
            type: "string",
            filterable: false,
            sortable: false,
        },
        {
            field: "name",
            headerName: t`chatbot-dispatches-list-view.internal-name`,
            renderCell: (params) => params.value,
            type: "string",
        },
        {
            field: "segment_names",
            headerName: t`chatbot-dispatches-list-view.visible-for`,
            filterable: false,
            sortable: false,
            renderCell: (params) => {
                const segmentNames =
                    params.value != null &&
                    params.value?.length > 0 &&
                    params.value?.length === 1
                        ? params.value[0]
                        : `(${
                              params.value?.length
                          }) ${t`chatbot-dispatches-list-view.segments`.toLowerCase()}`

                return segmentNames ?? ""
            },
            minWidth: 120,
            flex: 1.5,
        },
        {
            field: "access_type",
            headerName: t`chatbot-dispatches-list-view.access-type`,
            filterable: false,
            sortable: false,
            renderCell: (params) => (
                <AccessTypeHeader accessType={params.value} />
            ),
        },
    ]

    const handleEditItem = useCallback(
        (item) => {
            if (item != null) {
                globalStore.modals.open(() => (
                    <ChatbotDispatchesModal item={item.row} />
                ))
            }
        },
        [globalStore.modals],
    )

    const handleOpenPostClick = useCallback(
        (item) => {
            if (item != null) {
                globalStore.modals.open(() => (
                    <ChatbotDispatchesModal item={item} mode={"Copy"} />
                ))
            }
        },
        [globalStore.modals],
    )

    const handleDeleteItem = useCallback(
        (item: IChatbotDispatch) => {
            globalStore.modals.open(
                () => (
                    <ConfirmModal
                        onConfirm={async (confirmed) => {
                            if (confirmed) {
                                await store.deleteItem(item.dispatch_id)
                            }
                        }}
                        title={t`chatbot-dispatches-list-view.are-you-sure`}
                        content={t({
                            id: "chatbot-dispatches-list-view.confirm-delete-item",
                            values: { dispatchName: item.name },
                        })}
                    />
                ),
                {
                    variant: "slide-up-w600",
                },
            )
        },
        [globalStore.modals, store],
    )

    const rowActionsRenderer = useCallback(
        (item: IChatbotDispatch) => {
            const actions = [
                {
                    text: t`chatbot-dispatches-list-view.copy-dispatch`,
                    onClick: () => handleOpenPostClick(item),
                },
                {
                    text: t`chatbot-dispatches-list-view.delete-item`,
                    onClick: () => handleDeleteItem(item),
                },
            ]

            return actions
        },
        [handleOpenPostClick, handleDeleteItem],
    )

    return (
        <ListPage
            header={header}
            filter={filter}
            loading={globalStore.loading.is(
                ChatbotDispatchesStore.LoadingKeys.init,
            )}
        >
            <DataGridProTable
                paginator={store.dispatches}
                data={store.dispatches.items}
                columns={columns}
                advancedOperations={{
                    pagination: "server",
                    filtering: "server",
                    sorting: "server",
                }}
                onRowClickEvent={handleEditItem}
                rowActionsRenderer={rowActionsRenderer}
                repository={repository}
                loading={!store.dispatches.meta.initialized}
                onFilterChange={handleFilterChange}
                onSortChange={handleSortChange}
            />
        </ListPage>
    )
})

export const ChatbotDispatchesView = () => (
    <StoreProvider Store={ChatbotDispatchesStore}>
        <View />
    </StoreProvider>
)
