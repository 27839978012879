import { t } from "@lingui/macro"
import { Alert, Button } from "@mui/material"
import { observer } from "mobx-react"
import { useCallback } from "react"

import { useLocation } from "react-router-dom"

import { IContentItemsModalViewProps } from "./types/contentItemDetailModalView"

import { CenteredSpinner } from "src/components/CenteredSpinner"
import { ModalHeader } from "src/components/ModalHeader"
import { useInitializer } from "src/lib/initializer"
import { ContentItemsModalStore } from "src/modals/content-items/store"
import { GlobalStore } from "src/store"
import { StoreProvider } from "src/store/lib/StoreProvider"
import { useStore } from "src/store/lib/useStore"

import { Form } from "src/components/Form"
import { FormPanel } from "src/components/FormPanel"
import { ConfigurableDynamicFormFieldsSection } from "src/components/ConfigurableDynamicFormFields"
import { NavigationIDSection } from "src/modals/content-items/sections/IDSection"
import { PublishingSettingsSection } from "src/modals/content-items/sections/PublishingSettingsSection"
import { SettingsSection } from "src/modals/content-items/sections/SettingsSection"
import { ConfigurableDynamicFormFieldsStore } from "src/components/ConfigurableDynamicFormFields/store"
import { useCloseConfirmationForForm } from "src/store/modals/use-close-confirmation-for-form"

interface IExtraData {
    accessType?: string
}

const View = observer(({ id, mode }: IContentItemsModalViewProps) => {
    const globalStore = useStore(GlobalStore)

    const contentItemsStore = useStore(ContentItemsModalStore)
    const configurableDynamicFormFieldsStore = useStore(
        ConfigurableDynamicFormFieldsStore,
    )

    const location = useLocation()
    const locationState = location.state as IExtraData

    useCloseConfirmationForForm(
        contentItemsStore.form,
        configurableDynamicFormFieldsStore.form,
    )

    const initialized = useInitializer(async () => {
        await contentItemsStore.init(
            id,
            globalStore.session.accessGroupId,
            locationState?.accessType,
            mode,
        )
    }, [globalStore.session.accessGroupId])

    const handleSubmit = useCallback(
        async (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault()

            const validationError = await contentItemsStore.handleSubmit({
                accessGroupName: globalStore.session.accessGroup?.name,
                accessGroupID: globalStore.session.accessGroup?.id,
                defaultLanguage:
                    globalStore.session.defaultConfigurableLanguage,
                validateConfigurableDynamicFields:
                    configurableDynamicFormFieldsStore.handleValidation,
                getConfigsApiPayload:
                    configurableDynamicFormFieldsStore.getConfigsApiPayload,
            })

            if (
                validationError &&
                !Boolean(contentItemsStore.form.error("generic"))
            ) {
                globalStore.modals.pop()
            }
        },
        [
            globalStore,
            contentItemsStore,
            configurableDynamicFormFieldsStore.handleValidation,
            configurableDynamicFormFieldsStore.getConfigsApiPayload,
        ],
    )

    if (!initialized) {
        return <CenteredSpinner height="100vh" />
    }

    const isSaveButtonDisabled =
        (contentItemsStore.form.getIsDirty() === false &&
            configurableDynamicFormFieldsStore.form.getIsDirty() === false) ||
        contentItemsStore.isLoading ||
        contentItemsStore.isReadOnly

    return (
        <Form onSubmit={handleSubmit}>
            <ModalHeader>
                <Button
                    type="submit"
                    variant="contained"
                    disabled={isSaveButtonDisabled}
                >
                    {t`global.save`}
                </Button>
            </ModalHeader>
            {Boolean(contentItemsStore.form.error("generic")) && (
                <Alert severity="error">
                    {contentItemsStore.form.error("generic")}
                </Alert>
            )}
            <FormPanel
                sections={[
                    {
                        header: t`navigation-items-view.form-panel.navigation-id`,
                        content: (
                            <NavigationIDSection id={contentItemsStore.ID} />
                        ),
                    },
                ]}
            />
            <FormPanel
                sections={[
                    {
                        header: t`navigation-items-view.form-panel.settings`,
                        content: <SettingsSection />,
                    },
                ]}
            />

            <FormPanel
                sections={[
                    {
                        header: t`navigation-items-view.form-panel.publishing-settings`,
                        content: <PublishingSettingsSection />,
                    },
                ]}
            />
            <ConfigurableDynamicFormFieldsSection
                configDefinition={contentItemsStore.configDefinition}
                configs={contentItemsStore.form.get("configs")}
                isReadOnly={contentItemsStore.isReadOnly}
                configType={contentItemsStore.form.get("config_type")}
            />
        </Form>
    )
})

export const ContentItemsModalView = (props: IContentItemsModalViewProps) => (
    <StoreProvider Store={ContentItemsModalStore}>
        <StoreProvider Store={ConfigurableDynamicFormFieldsStore}>
            <View {...props} />
        </StoreProvider>
    </StoreProvider>
)
